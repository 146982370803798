import { AsStoreObject, Reference } from "@apollo/client/utilities";
import { Grid, Skeleton, Stack, useTheme } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";
import { FacilityAlternateIdKnownKind } from "encamp-shared/src/facility/alternateId";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { gql } from "generated-graphql";
import {
  AlternateIdInput,
  DynamicField,
  DynamicFieldInput,
  Issue,
  ProgramArea,
  RegulatoryInfoFragment,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformStateField";
import { useValidatingForm } from "hooks/useValidatingForm";
import { omit } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { reportStepMetadata } from "util/constants";
import { CaliforniaRegulatory } from "./CaliforniaRegulatory";
import { ReportFloatingBar } from "./ReportFloatingBar";
import { StepperPageHeader } from "./StepperPageHeader";
import { useHmbpFeature } from "./useHmbp";
import { useNavigateReportSaveCancel } from "./useNavigateReportSaveCancel";
import {
  filterRegulatoryInfoIssues,
  regulatoryFields,
  useRegulatoryInfoIssues,
  useReport,
  useUpdateFacilityReport,
} from "./useReport";
import { useTouchReportMutation } from "./useTouchReportMutation";
import { useRegulatoryInputValidation } from "./validationHooks/useRegulatoryInputValidation";

gql(`
  fragment regulatoryInfo on Facility {
    isSubjectToChemicalAccidentPrevention
    isSubjectToEmergencyPlanning
    isSubjectToToxicsReleaseInventoryReporting
    facilityAlternateIds {
      id
      type
      value
      expiresAt
      hasNoId
    }
  }
`);

export type RegInfoForm = Omit<
  RegulatoryInfoFragment,
  "facilityAlternateIds" | "facilityStateFields"
> & {
  rmpId?: string;
  triId?: string;
  epaId?: string;
  hazardousMaterialsOnSite?: boolean;
  hasUST?: boolean;
  hasHazardousWaste?: boolean;
  hazardousWasteOnSite?: boolean;
  hazardousWasteFinancialAssuranceRequirements?: boolean;
  hazardousWasteConsolidate?: boolean;
  hazardousWasteTankClosureRemoval?: boolean;
  hazardousWasteRCRA?: boolean;
  hazardousWasteHHWCollectionSite?: boolean;
  hasExcludedExemptedMaterials?: boolean;
  hasAPS?: boolean;
  apsaConditionallyExempt?: boolean;
  hasRegulatedSubstancesGreaterThanThreshold?: boolean;
};

// Custom function for mapping issues into our form here
const mapIssues = (issues: Issue[]) => {
  return issues.map((issue) => {
    if (issue.key === "facilityAlternateIds") {
      if (issue.message.includes("RMP")) {
        return { ...issue, key: "rmpId" };
      }
      if (issue.message.includes("TRI")) {
        return { ...issue, key: "triId" };
      }
      if (issue.message.includes("EPA")) {
        return { ...issue, key: "epaId" };
      }
    }

    return issue;
  });
};

const issueIsStoreObject = (val: any): val is AsStoreObject<Issue> => {
  return !!val.__typename && !val.__ref;
};

export const Regulatory: React.FC = () => {
  const alerts = useAlerts();
  const { data, loading } = useReport();
  const isHmbp = useHmbpFeature(data?.tierIIReport?.facility.state ?? "");
  const navigateToReportOverview = useNavigateReportSaveCancel();
  const regulatoryInfo = useMemo<RegInfoForm | undefined>(
    () =>
      data
        ? {
            isSubjectToChemicalAccidentPrevention:
              data?.tierIIReport?.facility
                .isSubjectToChemicalAccidentPrevention,
            isSubjectToEmergencyPlanning:
              data?.tierIIReport?.facility.isSubjectToEmergencyPlanning,
            isSubjectToToxicsReleaseInventoryReporting:
              data?.tierIIReport?.facility
                .isSubjectToToxicsReleaseInventoryReporting,
            rmpId:
              findAlternateId(
                data?.tierIIReport?.facility.facilityAlternateIds ?? [],
                ["RMP"]
              )?.value ?? "",
            triId:
              findAlternateId(
                data?.tierIIReport?.facility.facilityAlternateIds ?? [],
                ["TRI"]
              )?.value ?? "",
            epaId:
              findAlternateId(
                data?.tierIIReport?.facility.facilityAlternateIds ?? [],
                ["EPA"]
              )?.value ?? "",
            hazardousMaterialsOnSite: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hazardousMaterialsOnSite"
            )?.value,
            hasUST: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hasUST"
            )?.value,
            hasHazardousWaste: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hasHazardousWaste"
            )?.value,
            hazardousWasteOnSite: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hazardousWasteOnSite"
            )?.value,
            hazardousWasteFinancialAssuranceRequirements: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hazardousWasteFinancialAssuranceRequirements"
            )?.value,
            hazardousWasteConsolidate: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hazardousWasteConsolidate"
            )?.value,
            hazardousWasteTankClosureRemoval: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hazardousWasteTankClosureRemoval"
            )?.value,
            hazardousWasteRCRA: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hazardousWasteRCRA"
            )?.value,
            hazardousWasteHHWCollectionSite: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hazardousWasteHHWCollectionSite"
            )?.value,
            hasExcludedExemptedMaterials: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hasExcludedExemptedMaterials"
            )?.value,
            hasAPS: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hasAPS"
            )?.value,
            apsaConditionallyExempt: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "apsaConditionallyExempt"
            )?.value,
            hasRegulatedSubstancesGreaterThanThreshold: getStateField(
              data?.tierIIReport?.facility.stateFields,
              "hasRegulatedSubstancesGreaterThanThreshold"
            )?.value,
          }
        : undefined,
    [data]
  );

  const baseValidator = useRegulatoryInputValidation({
    facilityId: data?.tierIIReport.facility.id ?? "",
    reportId: data?.tierIIReport.id,
    pick: regulatoryFields,
    omit: isHmbp ? undefined : ["stateFields"],
  });

  const validatorWithCustomMapping = useCallback(
    async (input: RegInfoForm) => {
      const {
        rmpId,
        triId,
        epaId,
        hazardousMaterialsOnSite,
        hasUST,
        hasHazardousWaste,
        hazardousWasteOnSite,
        hazardousWasteFinancialAssuranceRequirements,
        hazardousWasteConsolidate,
        hazardousWasteTankClosureRemoval,
        hazardousWasteRCRA,
        hazardousWasteHHWCollectionSite,
        hasExcludedExemptedMaterials,
        hasAPS,
        apsaConditionallyExempt,
        hasRegulatedSubstancesGreaterThanThreshold,
        ...rest
      } = input;
      const mappedReq = {
        ...rest,
        facilityAlternateIds: [
          rmpId
            ? { type: FacilityAlternateIdKnownKind.RMP, value: rmpId }
            : null,
          triId
            ? { type: FacilityAlternateIdKnownKind.TRI, value: triId }
            : null,
          epaId
            ? { type: FacilityAlternateIdKnownKind.EPA, value: epaId }
            : null,
        ].filter(hasValue) as AlternateIdInput[],
        stateFields: [
          {
            key: "hazardousMaterialsOnSite",
            value: hazardousMaterialsOnSite,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Hazardous Materials on Site",
          },
          {
            key: "hasUST",
            value: hasUST,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Has UST",
          },
          {
            key: "hasHazardousWaste",
            value: hasHazardousWaste,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Has Hazardous Waste",
          },
          {
            key: "hazardousWasteOnSite",
            value: hazardousWasteOnSite,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Hazardous Waste on Site",
          },
          {
            key: "hazardousWasteFinancialAssuranceRequirements",
            value: hazardousWasteFinancialAssuranceRequirements,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Hazardous Waste Financial Assurance Requirements",
          },
          {
            key: "hazardousWasteConsolidate",
            value: hazardousWasteConsolidate,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Hazardous Waste Consolidate",
          },
          {
            key: "hazardousWasteTankClosureRemoval",
            value: hazardousWasteTankClosureRemoval,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Hazardous Waste Tank Closure Removal",
          },
          {
            key: "hazardousWasteRCRA",
            value: hazardousWasteRCRA,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Hazardous Waste RCRA",
          },
          {
            key: "hazardousWasteHHWCollectionSite",
            value: hazardousWasteHHWCollectionSite,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Hazardous Waste HHW Collection Site",
          },
          {
            key: "hasExcludedExemptedMaterials",
            value: hasExcludedExemptedMaterials,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Has Excluded Exempted Materials",
          },
          {
            key: "hasAPS",
            value: hasAPS,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Has APS",
          },
          {
            key: "apsaConditionallyExempt",
            value: apsaConditionallyExempt,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "APS Conditionally Exempt",
          },
          {
            key: "hasRegulatedSubstancesGreaterThanThreshold",
            value: hasRegulatedSubstancesGreaterThanThreshold,
            type: ProgramArea.Epcra,
            jurisdiction: "CA",
            inputType: "boolean",
            label: "Has Regulated Substances Greater Than Threshold",
          },
        ].filter(hasValue) as (DynamicField & { type: ProgramArea })[],
      };
      const res = await baseValidator(mappedReq);
      return mapIssues(res).filter((i) =>
        filterRegulatoryInfoIssues(i, isHmbp)
      );
    },
    [baseValidator, isHmbp]
  );

  const initialIssues = useRegulatoryInfoIssues();
  const initialIssuesWithCustomMapping = useMemo(
    () => mapIssues(initialIssues),
    [initialIssues]
  );

  const { handleSave: handleTouch, loading: touchLoading } =
    useTouchReportMutation(
      data?.tierIIReport.id ?? "",
      data?.tierIIReport.touchedSteps ?? [],
      TierIiReportStep.Regulatory
    );

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    issues,
    formState: { isDirty, isSubmitSuccessful, isSubmitted },
  } = useValidatingForm<RegInfoForm>(
    regulatoryInfo,
    initialIssuesWithCustomMapping,
    validatorWithCustomMapping
  );

  useEffect(() => {
    if (regulatoryInfo?.epaId) {
      const currentValue = watch("hasHazardousWaste");
      if (currentValue !== false) {
        setValue("hasHazardousWaste", true);
      }
    }
  }, [regulatoryInfo?.epaId, setValue, watch]);

  const showRmpId = watch("isSubjectToChemicalAccidentPrevention");
  const showTriId = watch("isSubjectToToxicsReleaseInventoryReporting");

  const [updateFacility, { loading: submitting }] = useUpdateFacilityReport();

  const hasPendingChanges =
    isDirty && !isSubmitSuccessful && !isSubmitted && !submitting;

  const onSubmit: SubmitHandler<RegInfoForm> = useCallback(
    async (saveData) => {
      if (!data) return;
      const { rmpId, triId, epaId, ...formDataWithStateFields } = saveData;
      const formData = omit(formDataWithStateFields, [
        "hazardousMaterialsOnSite",
        "hasUST",
        "hasHazardousWaste",
        "hazardousWasteOnSite",
        "hazardousWasteFinancialAssuranceRequirements",
        "hazardousWasteConsolidate",
        "hazardousWasteTankClosureRemoval",
        "hazardousWasteRCRA",
        "hazardousWasteHHWCollectionSite",
        "hasExcludedExemptedMaterials",
        "hasAPS",
        "apsaConditionallyExempt",
        "hasRegulatedSubstancesGreaterThanThreshold",
      ]);
      const existingAltIds =
        data?.tierIIReport?.facility?.facilityAlternateIds ?? [];

      const altIdUpdates = [
        { type: FacilityAlternateIdKnownKind.RMP, value: rmpId },
        { type: FacilityAlternateIdKnownKind.TRI, value: triId },
        { type: FacilityAlternateIdKnownKind.EPA, value: epaId },
      ];

      const newAltIds: AlternateIdInput[] = altIdUpdates.reduce(
        (acc, { type, value }) => {
          const index = acc.findIndex((altId) => altId.type === type);
          if (value) {
            if (index !== -1) {
              acc[index] = { ...acc[index], type, value }; // Update existing entry
            } else {
              acc.push({ type, value }); // Add new entry
            }
          } else if (index !== -1) {
            acc.splice(index, 1); // Remove entry if value is empty
          }
          return acc;
        },
        [...(existingAltIds as AlternateIdInput[])]
      ); // Initialize with a copy of existing facilityAlternateIds

      const stateFields = getStateFieldUpdates(
        data?.tierIIReport?.facility?.stateFields,
        saveData
      );

      try {
        await updateFacility({
          variables: {
            id: data.tierIIReport.facility.id,
            facility: {
              ...formData,
              facilityAlternateIds: newAltIds,
              stateFields: isHmbp ? stateFields : undefined,
            },
            reportId: data.tierIIReport.id,
            programArea: ProgramArea.Epcra,
          },
          awaitRefetchQueries: true,
          update(cache) {
            const removedIssues = initialIssues.filter(
              (initialIssue) =>
                !issues.map((i) => i.message).includes(initialIssue.message)
            );

            const addedIssues = issues.filter(
              (issue) =>
                !initialIssues.map((i) => i.message).includes(issue.message)
            );

            cache.modify({
              id: cache.identify({
                __typename: "TierIIReport",
                id: data.tierIIReport.id,
              }),
              fields: {
                issues(issues: readonly (Reference | AsStoreObject<Issue>)[]) {
                  const updatedIssues = issues.filter((issue) => {
                    // typescript thing, keep refs. not sure any of these can be refs
                    if (!issueIsStoreObject(issue)) return true;

                    return !removedIssues
                      .map((r) => r.message)
                      .includes(issue.message);
                  });

                  return [...updatedIssues, ...addedIssues];
                },
              },
            });
          },
        });
        await handleTouch();
      } catch (err) {
        alerts.error("Error saving regulatory information.");
        console.info("Error saving regulatory information.", err);
        return;
      }

      alerts.success("Regulatory information saved successfully.");
      navigateToReportOverview();
    },
    [
      alerts,
      data,
      navigateToReportOverview,
      updateFacility,
      handleTouch,
      initialIssues,
      issues,
      isHmbp,
    ]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ flexGrow: 1 }}>
      <Stack
        sx={{
          flexDirection: "column",
          minHeight: "100%",
        }}
      >
        <StepperPageHeader
          header="Regulatory"
          description={
            isHmbp
              ? reportStepMetadata.REGULATORY.hmbpDescription
              : reportStepMetadata.REGULATORY.overviewDescription
          }
        />

        {loading || !regulatoryInfo ? (
          <RegulatorySkeleton />
        ) : (
          <Stack
            spacing={3}
            sx={{ height: "100%", flexGrow: 1, flexDirection: "column" }}
          >
            {!isHmbp && (
              <>
                <Stack>
                  <RadioGroupField
                    label="Is this facility subject to Risk Management Plan (RMP) regulations?"
                    subtitle="Section 112(r) of CAA (40 CFR part 68, Risk Management Program)"
                    radioOptions={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ]}
                    name="isSubjectToChemicalAccidentPrevention"
                    control={control}
                  />
                  {showRmpId && (
                    <Grid item xs={3}>
                      <FormTextField
                        name="rmpId"
                        control={control}
                        label="RMP ID number"
                      />
                    </Grid>
                  )}
                </Stack>

                <RadioGroupField
                  label="Is this facility subject to Emergency Planning?"
                  subtitle="Section 302 of EPCRA (40 CFR part 355)"
                  radioOptions={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                  name="isSubjectToEmergencyPlanning"
                  control={control}
                />

                <Stack>
                  <RadioGroupField
                    label="Is this facility subject to the Toxic Release Inventory (TRI) program?"
                    subtitle="Section 313 of EPCRA"
                    radioOptions={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ]}
                    name="isSubjectToToxicsReleaseInventoryReporting"
                    control={control}
                  />
                  {showTriId && (
                    <Grid item xs={3}>
                      <FormTextField
                        name="triId"
                        control={control}
                        label="TRI ID number"
                      />
                    </Grid>
                  )}
                </Stack>
              </>
            )}

            {isHmbp && <CaliforniaRegulatory control={control} watch={watch} />}
          </Stack>
        )}
        <ReportFloatingBar
          saving={submitting}
          loading={loading}
          onCancel={navigateToReportOverview}
          issues={issues}
        />
        <UnfinishedChangesPrompt when={hasPendingChanges} />
      </Stack>
    </form>
  );
};

function RegulatorySkeleton() {
  const theme = useTheme();
  return (
    <Stack gap={theme.spacing(2)}>
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>

      <Skeleton variant="rectangular" />
      <Skeleton variant="rectangular" />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Grid>
      </Grid>
    </Stack>
  );
}

function getStateField(stateFields: DynamicField[], field: string) {
  return stateFields.find((f) => f.key === field);
}

function getStateFieldUpdates(
  stateFields: DynamicField[],
  saveData: RegInfoForm
): DynamicFieldInput[] {
  const {
    hazardousMaterialsOnSite,
    hasUST,
    hasHazardousWaste,
    hazardousWasteOnSite,
    hazardousWasteFinancialAssuranceRequirements,
    hazardousWasteConsolidate,
    hazardousWasteTankClosureRemoval,
    hazardousWasteRCRA,
    hazardousWasteHHWCollectionSite,
    hasExcludedExemptedMaterials,
    hasAPS,
    apsaConditionallyExempt,
    hasRegulatedSubstancesGreaterThanThreshold,
  } = saveData;

  // Filter out null values and transform the rest to DynamicFieldInput
  const baseStateFields =
    stateFields
      .filter((s) => s.value !== null)
      .map((s) => transform({ ...s, type: ProgramArea.Epcra })) ?? [];

  const stateFieldUpdates = [
    { key: "hazardousMaterialsOnSite", value: hazardousMaterialsOnSite },
    { key: "hasUST", value: hasUST },
    { key: "hasHazardousWaste", value: hasHazardousWaste },
    { key: "hazardousWasteOnSite", value: hazardousWasteOnSite },
    {
      key: "hazardousWasteFinancialAssuranceRequirements",
      value: hazardousWasteFinancialAssuranceRequirements,
    },
    { key: "hazardousWasteConsolidate", value: hazardousWasteConsolidate },
    {
      key: "hazardousWasteTankClosureRemoval",
      value: hazardousWasteTankClosureRemoval,
    },
    { key: "hazardousWasteRCRA", value: hazardousWasteRCRA },
    {
      key: "hazardousWasteHHWCollectionSite",
      value: hazardousWasteHHWCollectionSite,
    },
    {
      key: "hasExcludedExemptedMaterials",
      value: hasExcludedExemptedMaterials,
    },
    { key: "hasAPS", value: hasAPS },
    { key: "apsaConditionallyExempt", value: apsaConditionallyExempt },
    {
      key: "hasRegulatedSubstancesGreaterThanThreshold",
      value: hasRegulatedSubstancesGreaterThanThreshold,
    },
  ];

  return stateFieldUpdates.reduce(
    (acc, { key, value }) => {
      const index = acc.findIndex((field) => field.key === key);
      if (value !== undefined && value !== null) {
        const fieldData = {
          key,
          value,
          type: ProgramArea.Epcra,
          jurisdiction: "CA",
        };

        if (index !== -1) {
          acc[index] = { ...acc[index], ...fieldData }; // Update existing entry
        } else {
          acc.push(fieldData); // Add new entry
        }
      } else if (index !== -1) {
        acc.splice(index, 1); // Remove entry if value is undefined
      }
      return acc;
    },
    [...baseStateFields]
  ) as DynamicFieldInput[];
}
