import { useQuery } from "@apollo/client";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { gql } from "generated-graphql";
import { PersonPickerFragment } from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import React, { useEffect, useMemo, useState } from "react";
import { FieldError } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ErrorDisplay } from "./Forms/ErrorDisplay";
import { getFullName } from "encamp-shared/src/utils/name";

gql(`
  fragment PersonPicker on Person {
    id
    first
    last
    email
  }
`);

const PERSON_QUERY = gql(`
  query People($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    people(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...PersonPicker
      }
      count
    }
  }
`);

const getPersonDisplay = (person: PersonPickerFragment) => {
  if (person.email && person.first) {
    return `${getFullName(person)} <${person.email ?? ""}>`.trim();
  } else if (person.email) {
    return `${person.email ?? ""}`.trim();
  } else {
    return getFullName(person);
  }
};

const filterPerson = (person: PersonPickerFragment) => {
  return person.first || person.last || person.email;
};

type OptionType = PersonPickerFragment & {
  inputValue?: string;
};

interface PersonPickerProps {
  label?: string;
  helperText?: (
    value: PersonPickerFragment | null | undefined
  ) => string | undefined;
  onSelectPerson: (person: PersonPickerFragment | null) => void;
  value?: PersonPickerFragment | null;
  error?: FieldError;
  disabled?: boolean;
}

export const PersonPicker: React.FC<PersonPickerProps> = ({
  label = "Contact",
  helperText = () => "",
  onSelectPerson,
  value,
  error,
  disabled,
}) => {
  const { tenantId = "" } = useParams<{ tenantId: string }>();
  const [search, setSearch] = useState(value ? getPersonDisplay(value) : "");
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [debouncedSearch] = useDebounce(`tenantId:${tenantId} ${search}`, 200);

  const { data, loading, previousData } = useQuery(PERSON_QUERY, {
    variables: {
      search: debouncedSearch,
      page: 0,
      pageSize: 10,
      sort: [
        { field: "last", sort: "asc" },
        { field: "first", sort: "asc" },
      ],
    },
    skip: !searchIsOpen,
    fetchPolicy: "cache-and-network",
  });

  const options: OptionType[] =
    (data ?? previousData)?.people.items.filter(filterPerson) ?? [];

  useEffect(() => {
    setSearch(value ? getPersonDisplay(value) : "");
  }, [value]);

  const helperTextResult = useMemo(
    () => helperText(value),
    [helperText, value]
  );

  return (
    <>
      <Autocomplete<OptionType, false, false, true>
        disabled={disabled}
        loading={loading}
        value={value}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        onBlur={(e) => {
          if (!value) {
            const words = (e.target as HTMLInputElement).value.split(" ");
            if (words.length && words[0].length)
              onSelectPerson({
                id: "",
                first: words[0],
                last: words.slice(1).join(" "),
              });
          }
        }}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            const words = newValue.split(" ");
            onSelectPerson({
              id: "",
              first: words[0],
              last: words.slice(1).join(" "),
            });
          } else {
            onSelectPerson(newValue);
          }
        }}
        inputValue={search}
        onInputChange={(_, newInputValue, reason) => {
          if (reason !== "reset") {
            setSearch(newInputValue);
          }
        }}
        options={options}
        filterOptions={(options, params) => {
          const { inputValue } = params;
          const filtered = options.filter(
            (o) =>
              getPersonDisplay(o)
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0
          );
          const isExisting = filtered.some(
            (option) => inputValue === getPersonDisplay(option)
          );
          if (inputValue !== "" && !isExisting) {
            const words = inputValue.split(" ");
            filtered.push({
              id: "",
              first: words[0] ?? inputValue,
              last: words.slice(1).join(" "),
              inputValue: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        onOpen={() => setSearchIsOpen(true)}
        onClose={() => setSearchIsOpen(false)}
        noOptionsText="Search for a person"
        getOptionLabel={(option: OptionType | string) => {
          if (typeof option === "string") {
            return option;
          }

          if (option.inputValue) {
            return option.inputValue;
          }

          return getPersonDisplay(option);
        }}
        renderOption={(props, option) => (
          <li {...props} key={`${option.id}`}>
            {option.inputValue ?? getPersonDisplay(option)}
          </li>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={!!error}
              label={label}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
      />
      <ErrorDisplay error={error} helperText={helperTextResult} />
    </>
  );
};
