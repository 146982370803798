import { useLazyQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
  EditMode,
  FormState,
} from "components/AddEditDocumentDialog";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DataGrid } from "components/DataGrid";
import { DocumentType } from "generated-graphql/graphql";
import { useDeleteDocumentMutation } from "hooks/documents";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { useState } from "react";
import { getActivityTitle } from "util/activity";
import { useReportDetails } from "../useReportDetails";
import { ConfirmDeleteDocuments } from "./ConfirmDeleteDocuments";

const documentTypeOptions: DocumentType[] = Object.values(DocumentType);

export function DocumentList() {
  const {
    data,
    refetch,
    loading: reportLoading,
  } = useReportDetails({
    onCompleted: (data) => {
      setAddEditDialogState((state) => ({
        ...state,
        reportId: data.tierIIReport.id,
        facilities: data.tierIIReport.facility.id
          ? [{ id: data.tierIIReport.facility.id }]
          : [],
      }));
    },
  });
  const report = data?.tierIIReport;

  const [deleteDocumentId, setDeleteDocumentId] = useState<string | null>(null);

  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>(defaultAddEditDocumentDialogState);

  const [deleteDocument, { loading: deleting }] = useDeleteDocumentMutation({
    update(cache, { data }) {
      if (!data?.deleteDocument) return;
      const id = cache.identify(data.deleteDocument);
      cache.evict({ id });
    },
  });
  const alerts = useAlerts();

  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          alerts.error("Error fetching download link");
          return;
        }
        window.open(data.getDocumentDownloadLink, "_blank");
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", flex: 1, editable: true },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      editable: true,
    },

    {
      field: "documentType",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "activity",
      headerName: "Activity",
      flex: 1,
      valueGetter: ({ row }) =>
        row.activities?.length > 0 ? getActivityTitle(row.activities[0]) : null,
    },
    {
      field: "actions",
      type: "actions",
      resizable: false,
      sortable: false,
      getActions: ({ row }) => [
        <Tooltip title="Download Document" key={2}>
          <GridActionsCellItem
            onClick={() => {
              getDocumentDownloadLink({
                variables: { id: row.id },
              });
            }}
            key={2}
            label="Download Document"
            icon={<Download />}
          />
        </Tooltip>,
        <Tooltip title="Edit Document" key={0}>
          <GridActionsCellItem
            onClick={() =>
              setAddEditDialogState((state) => ({
                ...state,
                open: true,
                mode: EditMode.EDIT,
                step: FormState.FORM,
                documentId: row.id,
                title: row.title,
                documentType: row.documentType,
                fileExtension: row.fileExtension,
                documentTags: row.documentTags ?? undefined,
                activityId: row.activities?.[0]?.id ?? null,
              }))
            }
            key={2}
            label="Edit Document"
            icon={<Edit />}
          />
        </Tooltip>,
        <Tooltip title="Delete Document" key={1}>
          <GridActionsCellItem
            onClick={() => setDeleteDocumentId(row.id)}
            key={1}
            label="Delete Document"
            icon={<DeleteIcon />}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={reportLoading}
          onClick={() =>
            setAddEditDialogState((state) => ({
              ...state,
              open: true,
              mode: EditMode.ADD,
            }))
          }
        >
          Add Document
        </Button>
      </Box>
      <Stack spacing={2}>
        <Box>
          <DataGrid
            sx={{
              width: "100%",
              "& .MuiDataGrid-virtualScroller": {
                minHeight: "50px",
              },
              "& .wrapHeader": {
                lineHeight: "1rem",
              },
              "& .wrapHeader .MuiDataGrid-columnHeaderTitleContainer": {
                whiteSpace: "normal",
              },
              "& .wrapHeader .MuiDataGrid-colCellTitle, .wrapHeader .MuiDataGrid-columnHeaderTitle":
                {
                  whiteSpace: "normal",
                },
            }}
            hideFooter
            disableRowSelectionOnClick
            initialState={{
              pinnedColumns: {
                right: ["actions"],
              },
            }}
            onRowClick={({ row }) => {
              setAddEditDialogState((state) => ({
                ...state,
                open: true,
                mode: EditMode.EDIT,
                step: FormState.FORM,
                documentId: row.id,
                title: row.title,
                documentType: row.documentType,
                fileExtension: row.fileExtension,
                documentTags: row.documentTags ?? undefined,
                activityId: row.activities?.[0]?.id ?? null,
              }));
            }}
            rows={report?.documents ?? []}
            columns={columns}
            // processRowUpdate={processRowUpdate}
          />
        </Box>
        {addEditDialogState.open && data && (
          <AddEditDocumentDialog
            dialogState={addEditDialogState}
            setDialogState={setAddEditDialogState}
            reportId={data.tierIIReport.id}
            tenantId={data.tierIIReport.tenantId}
            generateTitleOnCreate={true}
            activityPickerOptions={data?.tierIIReport.currentWorkflow?.activities.map(
              (x) => ({
                id: x.id,
                title: getActivityTitle(x),
              })
            )}
            documentTypeOptions={documentTypeOptions}
            onSubmit={() => {
              refetch();
            }}
            refetchQueries={[]}
          />
        )}
        {deleteDocumentId && (
          <ConfirmDeleteDocuments
            deleteDocument={async () => {
              await deleteDocument({ variables: { id: deleteDocumentId } });
            }}
            onClose={() => setDeleteDocumentId(null)}
            deleting={deleting}
          />
        )}
      </Stack>
    </>
  );
}
