import { useMutation } from "@apollo/client";
import { HmbpUndergroundStorageTankSection } from "encamp-shared/src/hmbp";
import { gql } from "generated-graphql";
import {
  TierIiReportCaStep,
  TierIiReportUstStep,
} from "generated-graphql/graphql";
import { useCallback } from "react";
import { useReport } from "./useReport";

const TOUCH_TIER_II_REPORT_CA_STEP = gql(`
  mutation TouchTierIIReportCAStep($reportId: ID!, $step: TierIIReportCAStep!) {
    touchTierIIReportCAStep(reportId: $reportId, step: $step)
  }
`);

const UNTOUCH_TIER_II_REPORT_CA_STEP = gql(`
  mutation UntouchTierIIReportCAStep($reportId: ID!, $step: TierIIReportCAStep!) {
    untouchTierIIReportCAStep(reportId: $reportId, step: $step)
  }
`);

export function useTouchReportCAStepMutation(
  reportId: string,
  touchedCASteps: TierIiReportCaStep[],
  step: TierIiReportCaStep
) {
  const [touchTierIIReportCAStep, { loading: touchLoading }] = useMutation(
    TOUCH_TIER_II_REPORT_CA_STEP,
    {
      update: (cache) => {
        cache.modify({
          id: cache.identify({
            __typename: "TierIIReport",
            id: reportId,
          }),
          fields: {
            touchedCASteps: () =>
              Array.from(new Set([...touchedCASteps, step])),
          },
        });
      },
    }
  );
  const [untouchTierIIReportCAStep, { loading: untouchLoading }] = useMutation(
    UNTOUCH_TIER_II_REPORT_CA_STEP,
    {
      update: (cache) => {
        cache.modify({
          id: cache.identify({
            __typename: "TierIIReport",
            id: reportId,
          }),
          fields: {
            touchedCASteps: () => touchedCASteps.filter((s) => s !== step),
          },
        });
      },
    }
  );
  const handleTouch = useCallback(async () => {
    if (!touchedCASteps.includes(step)) {
      await touchTierIIReportCAStep({
        variables: {
          reportId,
          step,
        },
      });
    }
  }, [touchedCASteps, step, touchTierIIReportCAStep, reportId]);
  const handleUntouch = useCallback(async () => {
    if (touchedCASteps.includes(step)) {
      await untouchTierIIReportCAStep({
        variables: {
          reportId,
          step,
        },
      });
    }
  }, [touchedCASteps, step, untouchTierIIReportCAStep, reportId]);

  return {
    handleTouch,
    handleUntouch,
    loading: touchLoading || untouchLoading,
  };
}

const TOUCH_TIER_II_REPORT_UST_STEP = gql(`
  mutation TouchTierIIReportUSTStep($reportId: ID!, $undergroundStorageTankId: ID!, $step: TierIIReportUstStep!) {
    touchTierIIReportUstStep(reportId: $reportId, undergroundStorageTankId: $undergroundStorageTankId, step: $step)
  }
`);

export function useTouchReportUSTStepMutation(
  tankId: string,
  section: HmbpUndergroundStorageTankSection
) {
  const { data } = useReport();
  const reportId = data?.tierIIReport.id;
  const [mutate, { loading }] = useMutation(TOUCH_TIER_II_REPORT_UST_STEP);

  const touch = useCallback(async () => {
    const touchedSteps =
      data?.tierIIReport.undergroundStorageTankStatuses?.find(
        (ust) => ust.undergroundStorageTankId === tankId
      )?.touchedSteps ?? [];
    if (!touchedSteps.includes(section) && reportId) {
      await mutate({
        variables: {
          reportId,
          step: section as unknown as TierIiReportUstStep,
          undergroundStorageTankId: tankId,
        },
      });
    }
  }, [
    data?.tierIIReport.undergroundStorageTankStatuses,
    section,
    tankId,
    mutate,
    reportId,
  ]);

  return { touch, loading };
}
