export type Chemical = {
  isAlwaysReported?: boolean | null;
  isOverThreshold?: boolean | null;
  chemical: { noHazardsNotReporting?: boolean | null };
};

export function isChemicalReporting<T extends Chemical>(chemical: T): boolean {
  // There is an intentional hierarchy here, the idea being
  // noHazardsNotReporting > the user has marked this explicitly not reported
  // isAlwaysReported > this product has been explicitly marked to always be reported
  // isOverThresholds > our system detects that this chemical is over threshold
  //    for the specific municipality (see recompute-values)
  // default true - this will catch any underspecified or malformed product,
  //    and send it through validation

  // This is new. This is being used in the underlying product catalog as a
  // way for the end-user to intentionally keep the product in Encamp, but
  // not report it as part of the Tier II reporting process
  if (chemical.chemical?.noHazardsNotReporting) {
    return false;
  }

  if (chemical.isAlwaysReported || chemical.isOverThreshold) {
    return true;
  }

  if (chemical.isOverThreshold === false) {
    return false;
  }

  // default to reporting if we have a malformed product, with the idea that
  // applying validation is a good thing
  return true;
}
