import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  hmbpSectionDisplay,
  HmbpSectionWithoutRegulatory,
  HmbpTitle,
} from "encamp-shared/src/hmbp";
import { Issue } from "generated-graphql/graphql";
import pluralize from "pluralize";
import React from "react";
import { ReportStepState } from "util/constants";
import { useShouldShowSection } from "../../useHmbp";

export type SectionProps = {
  section: HmbpSectionWithoutRegulatory;
};

interface BaseRow {
  title: string;
  section: HmbpSectionWithoutRegulatory;
  guidance?: "Recommended" | "Required" | "Optional";
  guidanceTooltip?: React.ReactNode;
  issues: Issue[];
  status: ReportStepState;
}

export function SectionContainer({
  title,
  caption,
  children,
}: {
  title: string;
  caption?: string;
  children?: React.ReactNode;
}) {
  const shouldShow = useShouldShowSection(title as HmbpTitle);

  if (!shouldShow) {
    return <></>;
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h6">{title}</Typography>
      {caption && <Typography variant="caption">{caption}</Typography>}
      <Box
        sx={{
          border: 1,
          borderColor: grey[200],
          borderRadius: 1,
          padding: 0,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}

export function BaseRow({
  guidance,
  guidanceTooltip,
  icon,
  onClick,
  section,
  issues,
  status,
}: BaseRow & { icon: React.ReactNode; onClick: () => void }) {
  const theme = useTheme();
  const title = hmbpSectionDisplay[section];
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        paddingX: "0.5rem",
        paddingY: "0.875rem",
        cursor: "pointer",
        borderBottom: 1,
        borderColor: grey[200],
        "&:last-of-type": {
          borderBottom: 0,
        },
      }}
      onClick={onClick}
    >
      <Box sx={{ display: "flex", flex: 1 }}>
        <Typography variant="body2">{title}</Typography>
      </Box>
      {guidance && (
        <Tooltip title={guidanceTooltip}>
          <Typography variant="caption" sx={{ paddingX: theme.spacing(1) }}>
            {guidance}
          </Typography>
        </Tooltip>
      )}
      <Button sx={{ padding: 0, minWidth: "1.875rem" }}>{icon}</Button>
      <StatusIndicator status={status} issues={issues} />
    </Stack>
  );
}

export function StatusIndicator({
  status,
  issues,
}: {
  status: ReportStepState;
  issues: Issue[];
}) {
  const theme = useTheme();
  const issueCount = issues.length;
  const statusToButtonText: { [key in ReportStepState]: string } = {
    [ReportStepState.NotStarted]: "Not Started",
    [ReportStepState.Done]: "Complete",
    [ReportStepState.Invalid]: `Fix ${issueCount} ${pluralize(
      "Issue",
      issueCount
    )}`,
  };

  const statusToButtonIcon: {
    [key in ReportStepState]: React.ReactNode;
  } = {
    [ReportStepState.NotStarted]: <CircleOutlined />,
    [ReportStepState.Done]: <CheckCircleOutlined color="success" />,
    [ReportStepState.Invalid]: <ErrorOutline color="error" />,
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ paddingX: theme.spacing(1), width: "9rem" }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "1.875rem", height: "1.875rem", marginRight: "0.5rem" }}
      >
        {statusToButtonIcon[status]}
      </Stack>
      <Typography
        variant="body2"
        color={status === ReportStepState.Invalid ? "error" : undefined}
      >
        {statusToButtonText[status]}
      </Typography>
    </Stack>
  );
}
