import { Frequency } from "generated-graphql/graphql";
import { gql } from "generated-graphql";
import { useMutation } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useDeleteDocumentMutation } from "hooks/documents";
import { useTenant } from "hooks/useTenant";
import invariant from "invariant";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { TaskDialog, TaskFormType } from "./TaskDialog";
import { useTaskSchema } from "./createTaskSchema";
import { yupResolver } from "@hookform/resolvers/yup";

const CREATE_TASK_MUTATION = gql(/* GraphQL */ `
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input)
  }
`);

const defaultValues: TaskFormType = {
  title: "",
  description: "",
  assignee: null,
  deadline: null,
  frequency: Frequency.Once,
  interval: 1,
  reminderDays: [],
  subtasks: [],
  facilityIds: [],
  tags: [],
  documents: [],
  links: [],
  watchers: [],
  isCompleted: false,
  completedOn: null,
};

type AddTaskDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const AddTaskDialog: React.FC<AddTaskDialogProps> = ({
  open,
  onClose,
}) => {
  const alerts = useAlerts();
  const { tenantId, loading: isTenantLoading } = useTenant();
  const yupSchema = useTaskSchema();

  const [createTask, { loading: isCreateTaskLoading }] = useMutation(
    CREATE_TASK_MUTATION,
    {
      refetchQueries: ["Tasks"],
    }
  );

  const [deleteDocument] = useDeleteDocumentMutation({
    onError: (error) => {
      alerts.error("Failed to delete document");
    },
  });

  const handleClose = useCallback(
    (formValues: TaskFormType) => {
      // delete any documents that we may have created, as they were not meant to be persisted
      if (formValues.documents) {
        formValues.documents.forEach((document) => {
          if (document.id) {
            deleteDocument({ variables: { id: document.id } });
          }
        });
      }

      onClose();
    },
    [deleteDocument, onClose]
  );

  const handleSave = useCallback(
    (data: TaskFormType) => {
      invariant(tenantId, "tenantId is required");
      invariant(data.assignee, "assignee is required");

      createTask({
        variables: {
          input: {
            tenantId,
            title: data.title,
            description: data.description,
            assigneeId: data.assignee!.id,
            deadline: data.deadline
              ? new Date(data.deadline).toISOString().slice(0, 10)
              : null,
            reminderDays: data.reminderDays.map((reminder) => reminder.days),
            frequency: data.frequency,
            interval: data.interval,
            watchers: data.watchers,
            facilityIds: data.facilityIds,
            documentIds: data.documents.map((document) => document.id),
            links: data.links.map((link) => link.url),
            subtasks: data.subtasks
              .filter((subTask) => subTask.title?.trim() !== "")
              .map((subTask) => ({
                title: subTask.title,
                completedAt: subTask.completed ? DateTime.now() : null,
              })),
            tags: data.tags.filter((tag) => tag.name.trim() !== ""),
          },
        },
        onCompleted: () => {
          alerts.success("Task created");
          onClose();
        },
        onError: (error) => {
          alerts.error("Failed to create task");
          onClose();
        },
      });
    },
    [tenantId, createTask, alerts, onClose]
  );

  return (
    <TaskDialog
      isLoading={isTenantLoading}
      initialValues={defaultValues}
      validationResolver={yupResolver(yupSchema) as any}
      onSubmit={handleSave}
      onClose={handleClose}
      isSubmitting={isCreateTaskLoading}
      open={open}
      title="Add Task"
    />
  );
};
