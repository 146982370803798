import Add from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CheckboxField } from "components/Forms/CheckboxField";
import { DateField } from "components/Forms/DateField";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { getUtcDisplayDate } from "encamp-shared/src/utils/date";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  AssigneePickerFragment,
  Document,
  Tag,
  Frequency,
} from "generated-graphql/graphql";
import { useDeleteDocumentMutation } from "hooks/documents";
import { useTenant } from "hooks/useTenant";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { AssigneePicker } from "./AssigneePicker";
import { AssociateFacilitiesDialog } from "./AssociateFacilitiesDialog";
import { AssociateWatchersDialog } from "./AssociateWatchersDialog";
import { DocumentsControl } from "./DocumentsControl";
import { AssociatedFacilityGrid } from "./FacilityGrid";
import LinksFormControl from "./LinksFormControl";
import SubtasksControl from "./SubtasksControl";
import { TaskDialogSkeleton } from "./TaskDialogSkeleton";
import { WatcherGrid } from "./WatcherGrid";
import { TagsControl } from "./TagsControl";
import ReminderDaysFormControl from "./ReminderDaysFormControl";

export type TaskFormType = {
  title: string;
  description: string;
  assignee: AssigneePickerFragment | null;
  deadline: Date | null;
  frequency: Frequency;
  interval: number;
  reminderDays: { id: string; days: number }[];
  subtasks: { title: string; completed: boolean; id: string }[];
  facilityIds: string[];
  documents: Pick<
    Document,
    "id" | "fileExtension" | "title" | "documentType"
  >[];
  tags: Pick<Tag, "id" | "name">[];
  links: { url: string; id: string }[];
  watchers: string[];
  isCompleted: boolean;
  completedOn: Date | null;
};

export const TASK_FREQUENCY_ORDER = [
  Frequency.Once,
  Frequency.Weekly,
  Frequency.Monthly,
  Frequency.Quarterly,
  Frequency.Yearly,
];

export interface TaskDialogProps {
  onSubmit: (
    data: TaskFormType,
    isCompletionOnly: boolean,
    isFrequencyChanged: boolean
  ) => void;
  onClose: (data: TaskFormType) => void;
  initialValues: TaskFormType;
  isSubmitting: boolean;
  isLoading: boolean;
  title: string;
  open: boolean;
  validationResolver?: Resolver<TaskFormType>;
}

export const TaskDialog: React.FC<TaskDialogProps> = ({
  onSubmit,
  onClose,
  title,
  open,
  isSubmitting,
  initialValues,
  isLoading,
  validationResolver,
}) => {
  const theme = useTheme();
  const alerts = useAlerts();
  const form = useForm<TaskFormType>({
    defaultValues: initialValues,
    resolver: validationResolver,
    mode: "onChange",
  });

  useEffect(() => {
    if (initialValues) {
      form.reset(initialValues);
    }
  }, [initialValues, form]);
  const { control, handleSubmit, watch } = form;

  const completedOn = watch("completedOn");
  const frequency = watch("frequency");
  const interval = watch("interval");

  // When the frequency is removed, reset the interval to 1 if it is < 1
  useEffect(() => {
    if (frequency === Frequency.Once && (!interval || interval < 1)) {
      form.setValue("interval", 1);
    }
  }, [frequency, interval, form]);

  const getIntervalLabel = useCallback(() => {
    switch (frequency) {
      case Frequency.Weekly:
        return interval === 1 ? "week" : "weeks";
      case Frequency.Monthly:
        return interval === 1 ? "month" : "months";
      case Frequency.Quarterly:
        return interval === 1 ? "quarter" : "quarters";
      case Frequency.Yearly:
        return interval === 1 ? "year" : "years";
      case Frequency.Once:
        return "";
      default:
        ((_: never) => {
          throw new Error(`Unexpected value: ${_}`);
        })(frequency);
    }
  }, [frequency, interval]);
  const handleClose = useCallback(() => {
    onClose(form.getValues());
  }, [form, onClose]);

  const { tenantId, loading: isTenantLoading } = useTenant();
  const [facilitiesDialogOpen, setFacilitiesDialogOpen] = useState(false);
  const [watchersDialogOpen, setWatchersDialogOpen] = useState(false);

  const [deleteDocument] = useDeleteDocumentMutation({
    onError: (error) => {
      alerts.error("Failed to delete document");
    },
  });

  const isCompletionOnly = useCallback(
    (newValues: TaskFormType) => {
      const dirtyFields = form.formState.dirtyFields;
      if (dirtyFields.deadline) {
        // Check if deadline date has changed
        if (!initialValues.deadline || !newValues.deadline) return false;
        const originalDeadline =
          typeof initialValues.deadline === "string"
            ? DateTime.fromISO(initialValues.deadline)
            : DateTime.fromJSDate(initialValues.deadline);
        const updatedDeadline = DateTime.fromJSDate(newValues.deadline);
        const deadlinesAreEqual = originalDeadline.hasSame(
          updatedDeadline,
          "day"
        );
        if (!deadlinesAreEqual) return false;
      }
      if (
        // Check for dirty fields other than isCompleted and subtasks
        Object.keys(dirtyFields).some(
          (k) =>
            k !== "isCompleted" &&
            k !== "subtasks" &&
            k !== "deadline" &&
            dirtyFields[k as keyof typeof dirtyFields]
        )
      ) {
        return false;
      }
      // Ensure all subtask changes are isCompleted only
      return (
        !dirtyFields.subtasks ||
        dirtyFields.subtasks.every((s) => !s.title && !s.id)
      );
    },
    [form.formState.dirtyFields, initialValues.deadline]
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          {isLoading ? (
            <TaskDialogSkeleton />
          ) : (
            <Grid
              container
              spacing={theme.spacing(1)}
              columnSpacing={theme.spacing(4)}
              marginTop={theme.spacing(1)}
            >
              {initialValues.deadline && (
                // Do not display task completed checkbox when creating new
                // tasks
                <Grid item xs={12} display="flex" flexDirection="row">
                  <CheckboxField
                    name="isCompleted"
                    label="Task completed"
                    control={control}
                    sx={{ flex: 1 }}
                  />
                  {completedOn && (
                    <Typography
                      variant="body1"
                      noWrap
                      sx={{ mt: 1, pr: 1, fontStyle: "italic" }}
                    >
                      Completed on {getUtcDisplayDate(completedOn, "med")}
                    </Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <FormTextField
                  name="title"
                  label="Title"
                  control={control}
                  textFieldProps={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="description"
                  label="Description"
                  control={control}
                  InputProps={{
                    multiline: true,
                    rows: 4,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="assignee"
                  control={control}
                  render={({ field, fieldState }) =>
                    isTenantLoading ? (
                      <Skeleton />
                    ) : tenantId ? (
                      <AssigneePicker
                        {...fieldState}
                        {...field}
                        tenantId={tenantId}
                        value={field.value}
                        onChange={(item) => {
                          field.onChange(item);
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <DateField
                  name="deadline"
                  label="Deadline"
                  control={control}
                  required={true}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <FormSelect
                  name="frequency"
                  label="Recurring Task?"
                  control={control}
                  selectItems={Object.values(Frequency)
                    .sort(
                      (a, b) =>
                        TASK_FREQUENCY_ORDER.indexOf(a) -
                        TASK_FREQUENCY_ORDER.indexOf(b)
                    )
                    .map((value) => ({
                      value,
                      display:
                        value === Frequency.Once
                          ? "No"
                          : prettyPrintEnumValue(value),
                    }))}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="interval"
                  label="Interval"
                  control={control}
                  intOnly={true}
                  helperText={
                    frequency !== Frequency.Once && interval && interval > 0
                      ? `Task will repeat every ${interval} ${getIntervalLabel()}`
                      : ""
                  }
                  disabled={frequency === Frequency.Once}
                  textFieldProps={{
                    type: "number",
                    required: frequency !== Frequency.Once,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ReminderDaysFormControl control={control} />
              </Grid>
              <Grid item xs={12} paddingBottom={5}>
                <SubtasksControl control={control} />
              </Grid>
              <Grid item xs={12} paddingBottom={5}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">Facilities</Typography>
                  <Button
                    startIcon={<Add />}
                    onClick={() => setFacilitiesDialogOpen(true)}
                  >
                    Add Facilities
                  </Button>
                </Stack>
                <Controller
                  name="facilityIds"
                  control={control}
                  render={({
                    field: { value: facilityIds, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <AssociatedFacilityGrid
                        facilityIds={facilityIds ?? []}
                        onDelete={(id) => {
                          onChange(facilityIds?.filter((f) => f !== id));
                        }}
                      />
                      {error && (
                        <Typography color="error" marginTop={theme.spacing(1)}>
                          {error.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} paddingBottom={5}>
                <LinksFormControl control={control} />
              </Grid>
              <Grid item xs={12} paddingBottom={5}>
                <DocumentsControl
                  control={control}
                  onDelete={(id: string) =>
                    deleteDocument({ variables: { id } })
                  }
                />
              </Grid>
              <Grid item xs={12} paddingBottom={5}>
                <TagsControl control={control} />
              </Grid>
              <Grid item xs={12} paddingBottom={5}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">Watchers</Typography>
                  <Button
                    startIcon={<Add />}
                    onClick={() => setWatchersDialogOpen(true)}
                  >
                    Add Watchers
                  </Button>
                </Stack>
                <Controller
                  name="watchers"
                  control={control}
                  render={({ field: { value: watcherIds, onChange } }) => (
                    <WatcherGrid
                      watcherIds={watcherIds ?? []}
                      onDelete={(id) => {
                        onChange(watcherIds?.filter((f) => f !== id));
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <SaveButton
          onClick={handleSubmit((data) =>
            onSubmit(
              data,
              isCompletionOnly(data),
              !!form.formState.dirtyFields.frequency
            )
          )}
          disabled={!form.formState.isDirty}
          saveText="Save"
          loading={isSubmitting}
        />
      </DialogActions>
      <AssociateFacilitiesDialog
        open={facilitiesDialogOpen}
        onClose={() => setFacilitiesDialogOpen(false)}
        onSave={(facilityIds) => {
          form.setValue("facilityIds", facilityIds, { shouldDirty: true });
        }}
        existingFacilityIds={form.getValues("facilityIds")}
      />
      <AssociateWatchersDialog
        open={watchersDialogOpen}
        onClose={() => setWatchersDialogOpen(false)}
        onSave={(watcherIds: string[]) => {
          form.setValue("watchers", watcherIds, { shouldDirty: true });
        }}
        existingWatcherIds={form.getValues("watchers")}
      />
    </Dialog>
  );
};
