import { Link, Stack, Typography } from "@mui/material";
import { HmbpSection } from "encamp-shared/src/hmbp";
import { ReportFloatingBar } from "../ReportFloatingBar";
import { useGuidance } from "../useHmbp";
import { useStateInfoIssues } from "../useReport";
import {
  DocumentSection,
  FormSection,
  SectionContainer,
  UndergroundStorageTanks,
} from "./hmbpComponents";

export function HmbpForm() {
  const showUndergroundStorageTanks = [
    useGuidance(HmbpSection.USTFacilityOperatingPermitApplication) !== "None",
    useGuidance(HmbpSection.USTMonitoringSitePlan) !== "None",
    useGuidance(HmbpSection.USTCertificationOfFinancialResponsibility) !==
      "None",
    useGuidance(HmbpSection.USTResponsePlan) !== "None",
    useGuidance(HmbpSection.USTOwnerOperatorWrittenAgreement) !== "None",
    useGuidance(HmbpSection.USTLetterFromChiefFinancialOfficer) !== "None",
    useGuidance(HmbpSection.OwnerStatementOfDesignatedUSTOperatorCompliance) !==
      "None",
  ].some(Boolean);

  return (
    <>
      <Stack direction="column" flex={1}>
        <Stack spacing={3}>
          <SectionContainer title="Facility Information">
            <FormSection section={HmbpSection.FacilityInformation} />
            <DocumentSection section={HmbpSection.SitePlan} />
          </SectionContainer>

          <SectionContainer title="Emergency Response and Training Plans">
            <DocumentSection
              section={HmbpSection.EmergencyResponseContingencyPlan}
            />
            <DocumentSection section={HmbpSection.EmployeeTrainingPlan} />
          </SectionContainer>

          {showUndergroundStorageTanks && (
            <Stack spacing={3}>
              <SectionContainer
                title="Underground Storage Tanks (UST)"
                caption="Because the facility has USTs, the following forms/uploads are
              required. See below to add the UST documentation required for each
              tank at the facility."
              >
                <FormSection
                  section={HmbpSection.USTFacilityOperatingPermitApplication}
                />
                <DocumentSection section={HmbpSection.USTMonitoringSitePlan} />
                <DocumentSection
                  section={
                    HmbpSection.USTCertificationOfFinancialResponsibility
                  }
                />
                <DocumentSection section={HmbpSection.USTResponsePlan} />
                <DocumentSection
                  section={HmbpSection.USTOwnerOperatorWrittenAgreement}
                />
                <DocumentSection
                  section={HmbpSection.USTLetterFromChiefFinancialOfficer}
                />
                <DocumentSection
                  section={
                    HmbpSection.OwnerStatementOfDesignatedUSTOperatorCompliance
                  }
                />
              </SectionContainer>

              <UndergroundStorageTanks />
            </Stack>
          )}

          <SectionContainer title="Recyclable Materials Report">
            <DocumentSection
              section={HmbpSection.RecyclableMaterialsReportDocumentation}
            />
          </SectionContainer>
          <SectionContainer title="Hazardous Waste Tank Closure Certification">
            <DocumentSection
              section={HmbpSection.HazardousWasteTankClosureCertificate}
            />
          </SectionContainer>
          <SectionContainer title="Aboveground Petroleum Storage Act">
            <FormSection section={HmbpSection.APSAFacilityInformation}>
              <Typography variant="caption">
                You must indicate if your tank facility is conditionally exempt
                from having to prepare and implement a Spill Prevention,
                Control, and Countermeasure (SPCC) Plan under APSA. Refer to{" "}
                <Link
                  href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=HSC&sectionNum=25270.4.5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HSC §25270.4.5(b)
                </Link>{" "}
                for details. If you indicate that your facility is conditionally
                exempt in the Regulatory step, then no additional information is
                requested on the APSA Facility Information. If you indicate that
                your facility is not conditionally exempt, then complete the
                remaining fields in the APSA Facility Information. For
                assistance with calculating the total aboveground storage
                capacity of petroleum at your facility, read the CERS Help
                Materials for APSA:{" "}
                <Link
                  href="https://osfm.fire.ca.gov/what-we-do/pipeline-safety-and-cupa/certified-unified-program-agency/aboveground-petroleum-storage-act/is-my-facility-regulated-under-the-aboveground-petroleum-storage-act"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Is My Facility Regulated Under APSA?
                </Link>{" "}
                For information on “tanks in underground areas,” please contact
                OSFM at cupa@fire.ca.gov or contact your local regulator for
                assistance.
              </Typography>
            </FormSection>
            <DocumentSection
              section={HmbpSection.AbovegroundPetroleumStorageActDocumentation}
            />
          </SectionContainer>

          <Stack>
            <Typography variant="caption" sx={{ fontStyle: "italic" }}>
              Looking for additional HMBP forms? We only show the required forms
              based on your responses in the Regulatory step. Check your
              responses there and reach out to Encamp Support if you have
              questions.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <ReportFloatingBar issues={useStateInfoIssues()} />
    </>
  );
}
