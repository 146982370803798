import { DataGrid } from "components/DataGrid";
import { useQuery } from "@apollo/client";
import { GridColDef } from "@mui/x-data-grid";
import { Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useTenant } from "hooks/useTenant";
import { useMemo, useState } from "react";
import Delete from "@mui/icons-material/Delete";
import { GET_USERS_QUERY } from "./userQueries";
import { getFullName } from "encamp-shared/src/utils/name";

type Props = {
  watcherIds: string[];
  onDelete: (id: string) => void;
};

export const WatcherGrid = ({ watcherIds, onDelete }: Props) => {
  const { tenantId, loading: isTenantLoading } = useTenant();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const { data, previousData, loading } = useQuery(GET_USERS_QUERY, {
    variables: {
      search: `tenantId:${tenantId} id:${watcherIds.join("|")}`,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: [{ field: "name", sort: "asc" }],
    },
    skip: isTenantLoading,
  });

  const items = useMemo(
    () => data?.users?.items ?? previousData?.users?.items ?? [],
    [data?.users?.items, previousData?.users?.items]
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: (params) => getFullName(params.row.person),
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value}>
            <Typography
              variant="body2"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      valueGetter: (params) => params.row.email,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value}>
            <Typography
              variant="body2"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.1,
      getActions: (params) => {
        return [
          <Tooltip title="Delete watcher" key={1}>
            <GridActionsCellItem
              icon={<Delete />}
              label="Delete"
              onClick={() => {
                onDelete(params.row.id);
              }}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <div>
      <DataGrid
        noRowsMessage="No watchers associated with this task"
        rows={items}
        columns={columns}
        paginationMode="server"
        pagination
        getRowId={(row) => row.id}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => {
          setPaginationModel(model);
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        rowCount={data?.users.count ?? 0}
        loading={loading}
        sx={{
          maxHeight: "300px",
          "& .MuiDataGrid-virtualScroller": {
            minHeight: items.length === 0 ? "100px" : "0",
          },
        }}
      />
    </div>
  );
};
