import { identity } from "lodash";

export const initials = (first?: string | null, last?: string | null): string =>
  first
    ? last
      ? `${first.substring(0, 1)}${last.substring(0, 1)}`.toUpperCase()
      : first.substring(0, 1).toUpperCase()
    : "";

export const fullName = (first?: string | null, last?: string | null): string =>
  [first, last].filter(identity).join(" ");

export const getFullName = (
  person?: {
    first?: string | null;
    last?: string | null;
  } | null
): string =>
  [person?.first ?? "", person?.last ?? ""].filter((x) => x).join(" ");

export const getFullNames = (
  people?:
    | {
        first?: string | null;
        last?: string | null;
      }[]
    | null
): string => people?.map(getFullName).join(", ") || "";
