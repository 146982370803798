import { Typography, Box } from "@mui/material";

export function ResetReportExplainer() {
  return (
    <>
      <Typography gutterBottom>
        Changing Encamp Status determines whether the workflow will be canceled
        and if snapshots will be created, updated, or deleted.
      </Typography>
      <Typography gutterBottom>
        Changing Encamp Status to <strong>AWAITING_VERIFICATION</strong> cancels
        the current workflow and deletes the snapshot.
      </Typography>
      <Typography gutterBottom>
        Check <em>Force Cancel Workflow</em> to cancel a workflow without
        setting it to <strong>AWAITING_VERIFICATION</strong> (this is the
        default).
      </Typography>
      <Typography gutterBottom>
        Check <em>Force Replace Snapshot</em> to update a snapshot without
        setting the report to <strong>AWAITING_VERIFICATION</strong>.
      </Typography>
      See{" "}
      <a
        href="https://docs.google.com/spreadsheets/d/1WLqmhrMIs2yeNQCpBoMeWgN8tGVK1R4KAA9Cesu_AVI/edit?usp=sharing"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>{" "}
      for a full list of Encamp Status transition behaviors.
    </>
  );
}
