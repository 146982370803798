import { useMutation } from "@apollo/client";
import {
  DocumentInput2,
  DocumentType,
} from "encamp-shared/src/generated-graphql/oak-resolver-types";
import { gql } from "generated-graphql";

import { useCallback, useMemo, useState } from "react";
import { useUploadFiles } from "./useDocumentUpload";

const REPLACE_REPORT_DOCUMENTS_BY_TYPE_MUTATION = gql(`
  mutation ReplaceReportDocumentsByType($reportId: ID!, $documentType: DocumentType!, $documents: [DocumentInput2!]!) {
    replaceReportDocumentsByType(reportId: $reportId, documentType: $documentType, documents: $documents) {
      id
    }
  }
`);

export function useReplaceReportDocumentsByType() {
  const [loading, setLoading] = useState(false);
  const [replaceReportDocumentsByTypeMutation] = useMutation(
    REPLACE_REPORT_DOCUMENTS_BY_TYPE_MUTATION
  );

  const uploadFiles = useUploadFiles();

  const replaceReportDocumentsByType = useCallback(
    async ({
      reportId,
      documentType,
      documentsWithFile,
    }: {
      reportId: string;
      documentType: DocumentType;
      documentsWithFile: { file?: File; documentData: DocumentInput2 }[];
    }) => {
      setLoading(true);
      try {
        const documentKeys = await uploadFiles(documentsWithFile);
        await replaceReportDocumentsByTypeMutation({
          variables: {
            reportId,
            documentType,
            documents: documentsWithFile.map((doc, index) => ({
              ...doc.documentData,
              storageLink: documentKeys[index],
            })),
          },
        });
      } catch (error) {
        console.error("Error replacing report documents by type", error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [replaceReportDocumentsByTypeMutation, uploadFiles]
  );

  return useMemo(
    () => ({ replaceReportDocumentsByType, loading }),
    [replaceReportDocumentsByType, loading]
  );
}
