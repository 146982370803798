import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Theme,
  useTheme,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { isNil } from "lodash";
import { useMemo, useCallback } from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
} from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";

export type SelectItem = {
  value: any;
  display: string;
  disabled?: boolean;
};

export const FormSelect = <T extends FieldValues>({
  name,
  label,
  tooltip,
  disabled,
  control,
  selectProps,
  selectItems,
  helperText,
  rules,
  variant,
  sx,
  selectSx,
  hideErrors,
}: {
  label: string;
  tooltip?: string;
  disabled?: boolean;
  selectProps?: SelectProps;
  selectItems: SelectItem[];
  helperText?: string;
  rules?: RegisterOptions;
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps<Theme>;
  selectSx?: SxProps<Theme>;
  hideErrors?: boolean;
} & UseControllerProps<T>) => {
  const inputLabelId = useMemo(() => `${name}label`, [name]);
  const theme = useTheme();
  const labelWithTooltip = useMemo(
    () =>
      tooltip ? <TextWithTooltip text={label} tooltip={tooltip} /> : label,
    [label, tooltip]
  );

  const defaultValue = selectProps?.multiple ? [] : "";

  const handleOnMouseDownForClearButton = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  const handleClear = useCallback((event: any, field: any) => {
    event.stopPropagation();
    field.onChange("");
  }, []);

  /**
   * This layered handleClear is necessary to prevent creating new functions on
   * every render.
   *
   * Having an anonymous function as a callback for the onClick event of the
   * ClearIcon button will create a new function on every render, which will
   * lower performance. This makes it so the anonymous function is only created
   * on click.
   */
  const handleClearWithField = useCallback(
    (field: any) => (event: any) => {
      handleClear(event, field);
    },
    [handleClear]
  );

  return (
    <FormControl fullWidth sx={sx} required={!!rules?.required}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          let value: string | string[] = field.value;
          if (selectProps?.multiple) {
            value = Array.isArray(value) ? value : [];
          }
          if (isNil(value)) {
            value = defaultValue;
          }
          return (
            <>
              <InputLabel
                error={!!error}
                sx={{
                  color: disabled
                    ? theme.palette.action.disabled
                    : theme.palette.text.secondary,
                }}
                id={inputLabelId}
              >
                {labelWithTooltip}
              </InputLabel>
              <Select
                {...selectProps}
                {...field}
                value={value}
                name={name}
                label={labelWithTooltip}
                error={!!error}
                labelId={inputLabelId}
                aria-labelledby={inputLabelId}
                disabled={disabled}
                variant={variant}
                sx={selectSx}
                renderValue={(selected: any) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {selected}
                      <IconButton
                        size="small"
                        // This is necessary to prevent the clear button from
                        // opening the select menu
                        onMouseDown={handleOnMouseDownForClearButton}
                        onClick={handleClearWithField(field)}
                        sx={{ ml: 1 }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  );
                }}
              >
                {selectItems?.map((item: SelectItem) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    disabled={item.disabled}
                  >
                    {item.display}
                  </MenuItem>
                ))}
              </Select>
              <ErrorDisplay
                error={error}
                helperText={helperText}
                hideErrors={hideErrors}
              />
            </>
          );
        }}
      />
    </FormControl>
  );
};
