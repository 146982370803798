import { Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { Box } from "@mui/material";

interface DocumentPreviewProps {
  fileUrl: string;
  noPreviewAvailable?: boolean;
}

export const DocumentPreview = ({
  fileUrl,
  noPreviewAvailable,
}: DocumentPreviewProps) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return (
    <div
      className="rpv-core__viewer"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#eeeeee",
          borderTop: "1px solid rgba(0, 0, 0, 0.3)",
          borderLeft: "1px solid rgba(0, 0, 0, 0.3)",
          borderRight: "1px solid rgba(0, 0, 0, 0.3)",
          display: "flex",
          padding: "4px",
        }}
      >
        <Toolbar>
          {(props) => {
            const {
              CurrentPageInput,
              GoToNextPage,
              GoToPreviousPage,
              NumberOfPages,
              ShowSearchPopover,
              Zoom,
              ZoomIn,
              ZoomOut,
            } = props;
            return (
              <>
                <div style={{ padding: "0px 2px" }}>
                  <ShowSearchPopover />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomOut />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <Zoom />
                </div>
                <div style={{ padding: "0px 2px" }}>
                  <ZoomIn />
                </div>
                <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                  <GoToPreviousPage />
                </div>
                <div className="rpv-toolbar__item">
                  <CurrentPageInput />
                </div>
                <span className="rpv-toolbar__label">
                  / <NumberOfPages />
                </span>
                <div style={{ padding: "0px 2px" }}>
                  <GoToNextPage />
                </div>
              </>
            );
          }}
        </Toolbar>
      </div>
      <div
        style={{
          height: "85%",
          border: "1px solid rgba(0, 0, 0, 0.3)",
        }}
      >
        <Viewer
          fileUrl={fileUrl}
          plugins={[toolbarPluginInstance]}
          renderError={() => (
            <Box sx={{ p: 2 }}>Document preview only displays for PDFs.</Box>
          )}
        />
      </div>
    </div>
  );
};
