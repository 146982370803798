import Info from "@mui/icons-material/Info";
import WarningOutlined from "@mui/icons-material/WarningOutlined";
import {
  Box,
  Link,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  ActivityLog,
  ActivityStatus,
  ActivityType,
} from "generated-graphql/graphql";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { convertEnumToHumanReadable, getActivityTitle } from "util/activity";
import { useReportDetails } from "../useReportDetails";
import { ActivityBottomToolbar } from "./ActivityBottomToolbar";
import { ActivityErrorDisplay } from "./ActivityErrorDisplay";
import { ActivityInputs } from "./ActivityInputs";
import { ReportDetailsExtended } from "./ReportDetailsExtended";
import { StaffInputs } from "./StaffInputs";

export function ActivityDetails() {
  const theme = useTheme();
  const { data, loading } = useReportDetails();
  const { activityId } = useParams<{ activityId: string }>();
  const activity = useMemo(
    () =>
      data?.tierIIReport.currentWorkflow?.activities.find(
        (act) => act.id === activityId
      ),
    [activityId, data?.tierIIReport.currentWorkflow?.activities]
  );
  const recentErrorMessage =
    activity?.logs
      ?.filter((log: ActivityLog) => log.status === ActivityStatus.Error)
      .sort(
        (a: ActivityLog, b: ActivityLog) =>
          new Date(b.time).getTime() - new Date(a.time).getTime()
      )[0]?.message ?? "";

  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      sx={{ flex: 1 }}
      padding={theme.spacing(1)}
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" sx={{ display: "flex", mb: 0 }}>
          {activity && getActivityTitle(activity)}
          {activity && activity && activity.type === ActivityType.Staff && (
            <Tooltip title="Staff Submission Workflow">
              <Link
                sx={{ display: "flex", ml: "auto" }}
                component="a"
                href="https://academy.encamp.com/chemicals/6NmwbK2GNFS9Az6DhF7Xez"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Info />
              </Link>
            </Tooltip>
          )}
        </Typography>
        <Typography>
          {activity && convertEnumToHumanReadable(activity.status)}
        </Typography>
        {activity?.type === ActivityType.Geppetto && (
          <Typography variant="subtitle1" sx={{ color: "gray" }}>
            Job Status:{" "}
            {prettyPrintEnumValue(activity?.job?.status) ?? "Unknown"}
          </Typography>
        )}
      </Box>

      {activity && activity.status === ActivityStatus.Error && (
        <Box sx={{ flex: 1 }} gap={1} mb={2}>
          <Stack direction="row" gap={1}>
            <WarningOutlined sx={{ color: "red" }} />
            <Typography variant="subtitle1" sx={{ mb: 2, color: "red" }}>
              {recentErrorMessage ? recentErrorMessage : "Unknown Error"}
            </Typography>
          </Stack>
          <ActivityErrorDisplay activity={activity} />
        </Box>
      )}

      <Box sx={{ flex: 1 }} gap={1}>
        {loading ? (
          <>
            <Skeleton variant="rounded" sx={{ height: "3rem", mb: 2 }} />
            <Skeleton variant="rounded" sx={{ height: "3rem", mb: 2 }} />
            <Skeleton variant="rounded" sx={{ height: "3rem", mb: 2 }} />
            <Skeleton variant="rounded" sx={{ height: "3rem", mb: 2 }} />
          </>
        ) : activity ? (
          <>
            {activity.status === ActivityStatus.StaffCompleted && (
              <Typography variant="subtitle1" fontStyle="italic" sx={{ mb: 1 }}>
                Waiting for the backend workflow manager to clean up/complete
                this activity
              </Typography>
            )}
            <StaffInputs />
            <ActivityInputs activity={activity} />
            <ReportDetailsExtended
              reportDetailsExtended={activity.reportDetailsExtended}
            />
          </>
        ) : (
          <> {"Uh Oh can't find this activity"}</>
        )}
      </Box>
      {activity && <ActivityBottomToolbar activity={activity} />}
    </Stack>
  );
}
