import { useCallback, useState } from "react";

type UseConfirmDialogReturn<T> = {
  isOpen: boolean;
  onOpen: (item: T) => void;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  item?: T;
};

export const useConfirmDialog = <T>(
  onRemove: (item: T) => void | Promise<void>
): UseConfirmDialogReturn<T> => {
  const [itemToRemove, setItemToRemove] = useState<T | undefined>(undefined);

  const openRemoveConfirm = useCallback((item: T) => {
    setItemToRemove(item);
  }, []);

  const cancelRemoveConfirm = useCallback(() => {
    setItemToRemove(undefined);
  }, []);

  const removeConfirm = useCallback(async () => {
    if (itemToRemove !== undefined) {
      await onRemove(itemToRemove);
      cancelRemoveConfirm();
    }
  }, [cancelRemoveConfirm, onRemove, itemToRemove]);

  return {
    isOpen: itemToRemove !== undefined,
    onOpen: openRemoveConfirm,
    onCancel: cancelRemoveConfirm,
    onConfirm: removeConfirm,
    item: itemToRemove,
  };
};
