import { Grid, Typography } from "@mui/material";
import { DynamicField } from "generated-graphql/graphql";
import { groupBy } from "lodash";
import { useEffect } from "react";
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { DynamicField as DynamicFieldComponent } from "./DynamicField";

export const DynamicFieldLayout = <TFieldValues extends FieldValues>(props: {
  fields: (DynamicField & { key: Path<TFieldValues> })[];
  control: Control<TFieldValues>;
  disabled?: boolean;
  getValues: UseFormGetValues<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  grouping?: boolean;
}) => {
  const { grouping, fields, setValue, ...fieldProps } = props;
  const groupedFields = grouping
    ? groupBy(fields, (field) => field.group || "default")
    : undefined;

  // Reset fields when their `visibleWhen` condition is not met
  useEffect(() => {
    fields.forEach((field) => {
      const shouldShow = shouldShowField(
        field.visibleWhen,
        fieldProps.getValues
      );
      if (!shouldShow) {
        // Clear the value
        setValue(field.key, null as PathValue<TFieldValues, typeof field.key>);
      }
    });
  }, [fieldProps.getValues, fields, setValue]);

  return (
    <Grid container spacing={3} marginTop={1}>
      {groupedFields ? (
        Object.entries(groupedFields).map(([group, groupFields]) => {
          return (
            <Grid container item spacing={3} key={group}>
              {group !== "default" && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{group}</Typography>
                </Grid>
              )}
              <Fields fields={groupFields} {...fieldProps} />
            </Grid>
          );
        })
      ) : (
        <Fields fields={fields} {...fieldProps} />
      )}
    </Grid>
  );
};

const Fields = <TFieldValues extends FieldValues>({
  fields,
  control,
  disabled,
  getValues,
}: {
  fields: (DynamicField & { key: Path<TFieldValues> })[];
  control: Control<TFieldValues>;
  disabled?: boolean;
  getValues: UseFormGetValues<TFieldValues>;
}) => {
  return fields.map((field) => {
    const shouldShow = shouldShowField(field.visibleWhen, getValues);

    return (
      shouldShow && (
        <Grid
          item
          xs={field.layout?.xs || 12}
          sm={field.layout?.sm}
          md={field.layout?.md}
          lg={field.layout?.lg}
          xl={field.layout?.xl}
          key={field.key}
        >
          <DynamicFieldComponent
            name={field.key}
            control={control}
            dynamicField={field}
            disabled={disabled}
          />
        </Grid>
      )
    );
  });
};

export function shouldShowField<TFieldValues extends FieldValues>(
  visibleWhen: JSON[] | undefined,
  getValues: UseFormGetValues<TFieldValues>
) {
  if (!visibleWhen) {
    return true;
  }

  return visibleWhen.some((obj) => {
    return Object.keys(obj).every((key) => {
      const value = obj[key as keyof typeof obj];
      const fieldValue = getValues(key as Path<TFieldValues>);

      if (Array.isArray(value)) {
        // Handle case where value is array but fieldValue is string. This implies we migrated an arrayOf field to a oneOf field at some point, but we had a saved array value already.
        if (typeof fieldValue === "string") {
          if (value.length > 1) {
            console.error(
              `Field "${key}" expects multiple values but received single string. Using first value for comparison.`
            );
          }
          return value[0] === fieldValue;
        }

        // Handle null/undefined fieldValue when checking arrays
        return Array.isArray(fieldValue)
          ? value.every((v) => fieldValue.includes(v))
          : false;
      } else {
        return fieldValue === value;
      }
    });
  });
}
