import Add from "@mui/icons-material/Add";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Edit from "@mui/icons-material/Edit";
import { Box, Link, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CellWithSecondaryText } from "components/DataGrid";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { gql } from "generated-graphql";
import {
  AssignmentType,
  FacilityStatus,
  FacilityTierIiReportsQuery,
  Permission,
  TierIiReportKind,
  TierIiReportOrgStatus,
} from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useCreateAnnual } from "hooks/useCreateReport";
import { useFacilityReportEditable } from "hooks/useFacilityReportEditable";
import {
  FilterKey,
  OmnisearchGridColDef,
} from "hooks/useOmnisearchDatagridSettings";
import invariant from "invariant";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getChemicalsBreadcrumb } from "util/breadcrumb";
import { reportKindToLabel, statusToLabel } from "util/constants";
import { prettyPrintDateMed } from "util/dates";
import { getNotReportingReason } from "util/notReporting";
import { useBreadcrumb } from "../../../../hooks/useBreadcrumbs";
import { AssigneesEditorDialog, AssignmentAction } from "./AssigneesEditor";
import ReportActionsMenu from "./ReportActionsMenu";
import { ReportBulkActionsMenu } from "./ReportBulkActionsMenu";
import { getFullName } from "encamp-shared/src/utils/name";

export type ReportRow =
  FacilityTierIiReportsQuery["facilityTierIIReports"]["items"][0];

const additionalFilterColumns: FilterKey[] = [
  {
    header: "State",
    key: "state",
  },
  {
    key: "facilityStatus",
    header: "Facility Status",
    filterKeyType: "enum",
    enumValues: Object.values(FacilityStatus).sort(),
  },
  {
    key: "isColocated",
    header: "Is Facility Co-located?",
    filterKeyType: "boolean",
  },
  {
    key: "facilityTags",
    header: "Facility Tags",
    filterKeyType: "facilityTags",
  },
];

export const REPORTS_QUERY = gql(`
    query FacilityTierIIReports($tenantId: String!, $search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
        facilityTierIIReports(tenantId: $tenantId, search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
            count
            items {
              viewId
              facilityId
              customerFacilityId
              facilityName
              facilityDateClosed
              facilityDateInactive
              tierIIReportId
              reportingYear
              reportKind
              organizationStatus
              encampStatus
              streetAddress1
              streetAddress2
              city
              county
              state
              zip
              tenantId
              assignees {
                id
                person {
                  id
                  first
                  last
                }
                assignmentType
                isComplete
              }
              reviewers {
                id
                person {
                  id
                  first
                  last
                }
                assignmentType
                isComplete
              }
              organizationStatusAnnual
              encampStatusAnnual
              hasOpenRevision
              hasVerifiedRevision
              hasOpenInitial
              hasVerifiedInitial
              reportUpdatedAt
              isInactive
              isClosed
              colocationReportingFacilityId
              isNotReporting
              isNotReportingThroughEncamp
              reportUpdatedByUser {
                id
                email
                person {
                  first
                  last
                }
              }
              issueCount
            }
        }
    }
`);

export function ReportsTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const alerts = useAlerts();
  const { hasPermissionForFacility } = useAuthorization();
  const { tenantId } = useParams<{ tenantId: string }>();
  const [selectedRows, setSelectedRows] = useState<ReportRow[]>([]);
  const [assigneesReportAndType, setAssigneesReportAndType] = useState<
    [ReportRow, AssignmentType] | undefined
  >(undefined);

  invariant(tenantId, "tenantId is required");

  useBreadcrumb([
    {
      label: "Chemicals",
      to: tenantId ? getChemicalsBreadcrumb(tenantId) : undefined,
    },
    {
      label: "Reports",
    },
  ]);

  const [openAnnualReport] = useCreateAnnual();

  const createNewReport = useCallback(
    async ({ facilityId, reportingYear }: ReportRow) => {
      alerts.info("Creating Annual Report");
      await openAnnualReport({
        variables: {
          facilityId,
          reportingYear,
        },
        onCompleted: (data) => {
          navigate(`${data.createTierIIReport.id}`);
        },
        onError: (err) => {
          alerts.error("Error creating Annual Report", err);
        },
      });
    },
    [alerts, navigate, openAnnualReport]
  );

  const goToReport = useCallback(
    (id: string, event?: React.MouseEvent) => {
      if (event?.metaKey || event?.ctrlKey) {
        window.open(`${window.location.pathname}/${id}`, "_blank");
      } else {
        navigate(id);
      }
    },
    [navigate]
  );

  const { canAddAssigneesOrReviewers } = useFacilityReportEditable();

  const columns: OmnisearchGridColDef<ReportRow>[] = [
    {
      field: "facilityName",
      headerName: "Facility",
      flex: 1.1,
      filterKeyType: "facility",
      renderCell: (params) => (
        <CellWithSecondaryText
          primaryValue={
            params.row.customerFacilityId
              ? `${params.row.facilityName} (${params.row.customerFacilityId})`
              : params.row.facilityName
          }
          secondaryValue={`${
            params.row.streetAddress1 ? params.row.streetAddress1 : ""
          } ${params.row.streetAddress2 ? params.row.streetAddress2 : ""}, ${
            params.row.city ? `${params.row.city}, ` : ""
          }${params.row.state ? `${params.row.state} ` : ""}${
            params.row.zip ? `${params.row.zip}` : ""
          }${params.row.county ? ` ${params.row.county}` : ""}`}
        />
      ),
    },
    {
      field: "reportingYear",
      headerName: "Reporting Year",
      flex: 0.45,
    },
    {
      field: "reportKind",
      headerName: "Type",
      flex: 0.3,
      valueFormatter: (params) =>
        params.value
          ? reportKindToLabel(params.value)
          : "Annual report not yet open",
      colSpan: (params) => (params.value ? undefined : 5),
      filterKeyType: "enum",
      enumValues: Object.values(TierIiReportKind),
    },
    {
      field: "organizationStatus",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <CellWithSecondaryText
          primaryValue={
            params.row.organizationStatus &&
            params.row.encampStatus &&
            statusToLabel(
              params.row.organizationStatus,
              params.row.encampStatus
            )
          }
          secondaryValue={
            params.row.organizationStatus === TierIiReportOrgStatus.NotReporting
              ? getNotReportingReason(params.row)
              : undefined
          }
        />
      ),
      filterKeyType: "enum",
      enumValues: Object.values(TierIiReportOrgStatus),
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      flex: 0.6,
      sortable: false,
      renderCell: (params) => {
        const assignees = params.row.assignees ?? [];
        if (assignees.length) {
          return (
            <Stack direction="column" width="100%">
              {assignees.map((assignee) => (
                <Typography
                  variant="body2"
                  key={assignee.id}
                  width="100%"
                  noWrap
                >
                  {getFullName(assignee.person)}
                </Typography>
              ))}
            </Stack>
          );
        } else if (canAddAssigneesOrReviewers(params.row)) {
          return (
            <Link
              underline="none"
              onClick={(e) => {
                setAssigneesReportAndType([
                  params.row,
                  AssignmentType.Assignee,
                ]);
                e.stopPropagation();
              }}
              noWrap
            >
              Add Assignees
            </Link>
          );
        }
      },
    },
    {
      field: "reviewer",
      headerName: "Reviewers",
      renderHeader: (params) => {
        return (
          <Typography
            fontWeight="medium"
            fontSize="14px"
            sx={{ pl: "18px" }}
            noWrap
          >
            {params.colDef.headerName}
          </Typography>
        );
      },
      flex: 0.6,
      sortable: false,
      renderCell: (params) => {
        const reviewers = params.row.reviewers ?? [];
        if (reviewers.length) {
          return (
            <Stack direction="column" width="100%">
              {reviewers.map((reviewer) => (
                <Stack
                  width="100%"
                  direction="row"
                  key={reviewer.id}
                  alignItems="center"
                  gap={theme.spacing(0.5)}
                >
                  <CheckCircle
                    color="success"
                    sx={{
                      fontSize: "0.9rem",
                      visibility: reviewer.isComplete ? "visible" : "hidden",
                    }}
                  />
                  <Typography variant="body2" noWrap>
                    {getFullName(reviewer.person)}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          );
        } else if (canAddAssigneesOrReviewers(params.row)) {
          return (
            <Link
              underline="none"
              sx={{ pl: "18px" }}
              onClick={(e) => {
                setAssigneesReportAndType([
                  params.row,
                  AssignmentType.Reviewer,
                ]);
                e.stopPropagation();
              }}
              noWrap
            >
              Add Reviewers
            </Link>
          );
        }
      },
    },
    {
      field: "reportUpdatedAt",
      headerName: "Last Updated",
      flex: 0.5,
      renderCell: (params) => (
        <CellWithSecondaryText
          primaryValue={
            params.row.reportUpdatedAt &&
            prettyPrintDateMed(params.row.reportUpdatedAt)
          }
          secondaryValue={
            params.row.reportUpdatedByUser?.person
              ? getFullName(params.row.reportUpdatedByUser?.person)
              : params.row.tierIIReportId
              ? "Encamp"
              : ""
          }
        />
      ),
    },
    {
      field: "issueCount",
      headerName: "Issues",
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return <IssueCount issueCount={row.issueCount} />;
      },
      flex: 0.5,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => {
        return getRowActions(params.row);
      },
    },
  ];

  const getItems = useCallback(
    (data: FacilityTierIiReportsQuery) =>
      data.facilityTierIIReports.items.map((item: ReportRow) => ({
        ...item,
        id: item.viewId,
      })),
    []
  );

  const getRowActions = useCallback(
    (row: ReportRow) => {
      const actions = [];

      if (!row.tierIIReportId) {
        actions.push(
          <Tooltip title="Create Report" key="createReport">
            <GridActionsCellItem
              onClick={() => createNewReport(row)}
              label="Open Annual Report"
              icon={<Add />}
            />
          </Tooltip>
        );
      } else {
        const tooltip =
          row.organizationStatus === TierIiReportOrgStatus.Verified
            ? "View Report"
            : "Edit Report";
        actions.push(
          <Tooltip title={tooltip} key="editReport">
            <GridActionsCellItem
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                if (row.tierIIReportId) {
                  goToReport(row.tierIIReportId, event);
                }
              }}
              label={tooltip}
              icon={<Edit />}
            />
          </Tooltip>
        );
      }

      const canModifyReport = hasPermissionForFacility(row.facilityId, [
        Permission.VerifyTierIiReport,
      ]);

      if (canModifyReport) {
        actions.push(
          <ReportActionsMenu
            key="moreActions"
            onEditAssignees={(report, assignmentType) => {
              if (report) {
                setAssigneesReportAndType([report, assignmentType]);
              }
            }}
            facilityTierIiReport={row}
            createNewReport={createNewReport}
          />
        );
      }

      return actions;
    },
    [createNewReport, goToReport, hasPermissionForFacility]
  );

  return (
    <Box sx={{ py: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        rowHeight="auto"
        dataQuery={REPORTS_QUERY}
        dataQueryVariables={{
          tenantId: tenantId ?? "",
        }}
        defaultSearch={`chemicalFeatureFlagOverride:!DISABLED reportingYear:${currentTierIIReportingYear}`}
        getItems={getItems}
        getCount={(data) => data.facilityTierIIReports.count}
        noDataMessage="No facilities have been added to this organization yet."
        onRowClick={({ row }, event) => {
          if (row.tierIIReportId) {
            goToReport(row.tierIIReportId, event);
          }
        }}
        initialSortModel={[{ field: "facilityName", sort: "asc" }]}
        onSelectedRowsChanged={setSelectedRows}
        isRowSelectable={({ row }) =>
          hasPermissionForFacility(row.facilityId, [
            Permission.VerifyTierIiReport,
          ])
        }
        commandButtons={[
          <ReportBulkActionsMenu key="bulk-actions" reports={selectedRows} />,
        ]}
        additionalFilterColumns={additionalFilterColumns}
        showFavorites={true}
      />
      {assigneesReportAndType && (
        <AssigneesEditorDialog
          assignmentAction={AssignmentAction.Assign}
          onClose={() => setAssigneesReportAndType(undefined)}
          assignmentType={assigneesReportAndType[1]}
          tierIIReports={[assigneesReportAndType[0]]}
          tenantId={tenantId}
        />
      )}
    </Box>
  );
}
