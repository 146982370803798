import { useTenant } from "./useTenant";

export function useFeatureFlags(): {
  featureFlags: FeatureFlags | undefined;
  loading: boolean;
} {
  const { tenant, loading } = useTenant();
  return { featureFlags: tenant?.featureFlags, loading };
}

export type FeatureFlagKey =
  | "appcues-widget"
  | "module-waste"
  | "amazon-notion-training"
  | "eager-beaver"
  | "countable-items-ui"
  | "module-tierii"
  | "insights-ui"
  | "facility-self-assignment"
  | "chemicals-overview"
  | "payments-update";

export type FeatureFlags = Record<FeatureFlagKey, boolean>;
