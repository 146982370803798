import { DocumentType } from "generated-graphql/graphql";
import { documentTypeToLabel } from "./constants";

/**
 * Client side standardize file name functions
 * These relate and should be consistent with the DocumentNamer class in
 * encamp-documents/src/documentNamer.ts
 */

/**
 * Standardizes the title of a document for a staff activity report
 * @param documentType - The type of document
 * @param facilityName - The name of the facility
 * @param fileExtension - The extension of the file
 * @returns The standardized title of the document
 */
export function standardizeStaffActivityDocumentTitle(
  documentType?: DocumentType,
  facilityName?: string,
  fileExtension?: string | null,
  date?: Date
): string | undefined {
  // Don't rename "Other" documents
  if (!documentType || documentType === DocumentType.Other) {
    return undefined;
  }

  const facilityNameOrEmpty = facilityName || "";

  const dateToUse = date ?? new Date();
  const formattedDate = dateToUse.toISOString().split("T")[0];

  // Create abbreviated/mapped names for document types
  const typeAbbreviations: Record<string, string> = {
    [DocumentType.SafetyDataSheet]: "SDS",
  };

  // Get abbreviated document type or use original if no mapping exists
  const formattedDocType =
    typeAbbreviations[documentType] || documentTypeToLabel(documentType);

  // Truncate facility name if too long (e.g., keep first 20 chars)
  const truncatedFacility =
    facilityNameOrEmpty.length > 20
      ? facilityNameOrEmpty.substring(0, 20).trim() + "..."
      : facilityNameOrEmpty;

  // strip off the . if it exists
  const fileExt = fileExtension?.startsWith(".")
    ? fileExtension.substring(1)
    : fileExtension;

  const combined = [formattedDocType, truncatedFacility, formattedDate]
    .filter((x) => !!x && x.length > 0)
    .join("_");

  return `${cleanStringForFilesystem(combined)}.${fileExt}`;
}

/**
 * Taken from the DocumentNamer class in encamp-documents/src/documentNamer.ts
 *
 * Replaces all characters that aren't letters, numbers, or dashes with underscores.
 * This ensures the string is safe to use as a filename.
 * @example "Hello, World!" -> "Hello_World_"
 */
function cleanStringForFilesystem(str: string): string {
  // [^a-zA-Z0-9-] means "match any character that is NOT a-z, A-Z, 0-9, or -"
  return str.replace(/[^a-zA-Z0-9-]/g, "_");
}
