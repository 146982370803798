import { grey } from "@mui/material/colors";
import { hmbpSectionDisplay } from "encamp-shared/src/hmbp";
import { useState } from "react";
import { useCAStepStatus, useGuidance, useHmbpIssues } from "../../useHmbp";
import { BaseRow, SectionProps } from "./common";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { TierIiReportCaStep } from "encamp-shared/src/generated-graphql/oak-resolver-types";
import { HmbpDocumentForm } from "./HmbpDocumentForm";

export function DocumentSection({ section }: SectionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const title = hmbpSectionDisplay[section];
  const issues = useHmbpIssues(section);
  const hmbpSection = section as unknown as TierIiReportCaStep;
  const status = useCAStepStatus(hmbpSection);
  const guidance = useGuidance(section);

  if (guidance === "None") {
    return <></>;
  }

  return (
    <>
      <BaseRow
        title={title}
        guidance={guidance}
        icon={<UploadFileIcon sx={{ color: grey[400] }} />}
        onClick={() => {
          setIsOpen(true);
        }}
        section={section}
        issues={issues}
        status={status}
      />
      {isOpen && (
        <HmbpDocumentForm
          section={section}
          handleClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
