import { FormControl, SxProps, Stack } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { InputTooltip } from "./InputTooltip";

type Props<T extends FieldValues> = {
  label: string;
  tooltip?: string;
  datePickerProps?: DatePickerProps<DateTime>;
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
  hideErrors?: boolean;
  helperText?: string;
} & UseControllerProps<T>;

export function DateField<T extends FieldValues>(props: Props<T>) {
  // 72px accounts for tooltip (24px) + calendar icon (24px) + padding/spacing
  // 48px accounts for just calendar icon (24px) + padding/spacing
  const slotWidth = props.tooltip ? 72 : 48;
  return (
    <FormControl fullWidth>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <DatePicker
                sx={props.sx}
                {...props.datePickerProps}
                label={props.label}
                value={value ? DateTime.fromISO(value) : null}
                onChange={(newValue) => onChange(newValue?.toISODate())}
                disabled={props.disabled}
                slots={{
                  inputAdornment: (adornmentProps) => (
                    <Stack direction="row" alignItems="center">
                      {props.tooltip && (
                        <InputTooltip title={props.tooltip} placement="right" />
                      )}
                      {adornmentProps.children}
                    </Stack>
                  ),
                }}
                slotProps={{
                  textField: {
                    error: !!error,
                    required: props.required,
                    sx: {
                      "& .MuiInputLabel-shrink": {
                        width: "auto", // Full width when shrunk/floating
                      },
                      "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
                        width: `calc(100% - ${slotWidth}px)`, // Limit width to avoid overlap with tooltip/date picker icons
                      },
                    },
                  },
                }}
              />
              <ErrorDisplay
                error={error}
                helperText={props.helperText}
                hideErrors={props.hideErrors}
              />
            </>
          );
        }}
      />
    </FormControl>
  );
}
