import { range } from "lodash";
import { DateTime } from "luxon";
import { StateAbbreviation } from "./states";

// This constant affects several things and is intentionally manually flipped between reporting years.
export const currentTierIIReportingYear = 2024;

// Either 365, or 366, for leap year
export const currentTierIIReportingYearDays = DateTime.local(
  currentTierIIReportingYear,
  12,
  31
).ordinal;

// This constant is used to populate any reporting year filter dropdowns
// Customers can begin working on Initial reports any time that calendar year
const currentCalendarYear = new Date().getFullYear();
export const reportingYearFilterRange = range(currentCalendarYear, 2018);

// This is DateTime.local for UI consumption, but can be switched to UTC if that's more sane.
export const currentTierIISeasonStart = DateTime.local(
  currentTierIIReportingYear + 1,
  1,
  1
);

// This is DateTime.local for UI consumption, but can be switched to UTC if that's more sane.
export const currentTierIIDeadline = DateTime.local(
  currentTierIIReportingYear + 1,
  3,
  1
);

export const nextTierIIReportingYear = currentTierIIReportingYear + 1;

// This is DateTime.local for UI consumption, but can be switched to UTC if that's more sane.
export const nextTierIISeasonStart = DateTime.local(
  nextTierIIReportingYear + 1,
  1,
  1
);

// This is DateTime.local for UI consumption, but can be switched to UTC if that's more sane.
export const nextTierIIDeadline = DateTime.local(
  nextTierIIReportingYear + 1,
  3,
  1
);

export type DropdownOption = {
  id: string;
  label: string;
};

const mapIntoDropdownOptions: (value: string) => DropdownOption = (
  value: string
) => ({
  id: value,
  label: value,
});

export enum StorageType {
  AboveGroundTank = "Above ground tank",
  Bag = "Bag",
  Battery = "Battery",
  BelowGroundTank = "Below ground tank",
  Box = "Box",
  Can = "Can",
  Carboy = "Carboy",
  Cylinder = "Cylinder",
  Dewar = "Dewar",
  ElectricalEquipment = "Electrical Equipment",
  FiberDrum = "Fiber drum",
  GlassBottlesOrJugs = "Glass bottles or jugs",
  HvacEquipment = "HVAC Equipment",
  LeadAcidBattery = "Lead Acid Battery",
  Other = "Other",
  OutdoorStoragePile = "Outdoor storage pile",
  PlasticBottlesOrJugs = "Plastic bottles or jugs",
  PlasticOrNonMetallicDrum = "Plastic or non-metallic drum",
  RailCar = "Rail car",
  Silo = "Silo",
  SteelDrum = "Steel drum",
  SulfuricAcid = "Sulfuric Acid",
  TankCarRailCar = "Tank Car, Rail Car",
  TankInsideBuilding = "Tank inside building",
  TankTruckTankWagon = "Tank Truck, Tank Wagon",
  TankWagon = "Tank wagon",
  ToteBin = "Tote bin",
  UndergroundTank = "Underground Tank",
}

export const STORAGE_TYPE_DESCRIPTIONS = <const>[
  StorageType.AboveGroundTank as string,
  StorageType.Bag as string,
  StorageType.Battery as string,
  StorageType.BelowGroundTank as string,
  StorageType.Box as string,
  StorageType.Can as string,
  StorageType.Carboy as string,
  StorageType.Cylinder as string,
  StorageType.Dewar as string,
  StorageType.ElectricalEquipment as string,
  StorageType.FiberDrum as string,
  StorageType.GlassBottlesOrJugs as string,
  StorageType.HvacEquipment as string,
  StorageType.LeadAcidBattery as string,
  StorageType.Other as string,
  StorageType.OutdoorStoragePile as string,
  StorageType.PlasticBottlesOrJugs as string,
  StorageType.PlasticOrNonMetallicDrum as string,
  StorageType.RailCar as string,
  StorageType.Silo as string,
  StorageType.SteelDrum as string,
  StorageType.SulfuricAcid as string,
  StorageType.TankCarRailCar as string,
  StorageType.TankInsideBuilding as string,
  StorageType.TankTruckTankWagon as string,
  StorageType.TankWagon as string,
  StorageType.ToteBin as string,
  StorageType.UndergroundTank as string,
];

export const AZ_OR_SD_WA_STORAGE_TYPE_DESCRIPTIONS = <const>[
  StorageType.AboveGroundTank as string,
  StorageType.Bag as string,
  StorageType.Battery as string,
  StorageType.BelowGroundTank as string,
  StorageType.Box as string,
  StorageType.Can as string,
  StorageType.Carboy as string,
  StorageType.Cylinder as string,
  StorageType.Dewar as string,
  StorageType.FiberDrum as string,
  StorageType.GlassBottlesOrJugs as string,
  StorageType.Other as string,
  StorageType.OutdoorStoragePile as string,
  StorageType.PlasticBottlesOrJugs as string,
  StorageType.PlasticOrNonMetallicDrum as string,
  StorageType.RailCar as string,
  StorageType.Silo as string,
  StorageType.SteelDrum as string,
  StorageType.TankInsideBuilding as string,
  StorageType.TankWagon as string,
  StorageType.ToteBin as string,
];

export const AZ_STORAGE_TYPE_DESCRIPTIONS =
  AZ_OR_SD_WA_STORAGE_TYPE_DESCRIPTIONS;

export const CO_STORAGE_TYPE_DESCRIPTIONS = <const>[
  StorageType.AboveGroundTank as string,
  StorageType.Bag as string,
  StorageType.Battery as string,
  StorageType.BelowGroundTank as string,
  StorageType.Box as string,
  StorageType.Can as string,
  StorageType.Carboy as string,
  StorageType.Cylinder as string,
  StorageType.FiberDrum as string,
  StorageType.GlassBottlesOrJugs as string,
  StorageType.Other as string,
  StorageType.OutdoorStoragePile as string,
  StorageType.PlasticBottlesOrJugs as string,
  StorageType.PlasticOrNonMetallicDrum as string,
  StorageType.RailCar as string,
  StorageType.Silo as string,
  StorageType.SteelDrum as string,
  StorageType.TankInsideBuilding as string,
  StorageType.TankWagon as string,
  StorageType.ToteBin as string,
];

export const CT_DE_KY_MN_STORAGE_TYPE_DESCRIPTIONS = <const>[
  StorageType.AboveGroundTank as string,
  StorageType.Bag as string,
  StorageType.BelowGroundTank as string,
  StorageType.Box as string,
  StorageType.Can as string,
  StorageType.Carboy as string,
  StorageType.Cylinder as string,
  StorageType.FiberDrum as string,
  StorageType.GlassBottlesOrJugs as string,
  StorageType.LeadAcidBattery as string,
  StorageType.Other as string,
  StorageType.PlasticBottlesOrJugs as string,
  StorageType.PlasticOrNonMetallicDrum as string,
  StorageType.RailCar as string,
  StorageType.Silo as string,
  StorageType.SteelDrum as string,
  StorageType.TankInsideBuilding as string,
  StorageType.TankWagon as string,
  StorageType.ToteBin as string,
];

export const CT_STORAGE_TYPE_DESCRIPTIONS =
  CT_DE_KY_MN_STORAGE_TYPE_DESCRIPTIONS;

export const DE_STORAGE_TYPE_DESCRIPTIONS =
  CT_DE_KY_MN_STORAGE_TYPE_DESCRIPTIONS;

export const KY_STORAGE_TYPE_DESCRIPTIONS =
  CT_DE_KY_MN_STORAGE_TYPE_DESCRIPTIONS;

export const LA_STORAGE_TYPE_DESCRIPTIONS = <const>[
  StorageType.AboveGroundTank as string,
  StorageType.Bag as string,
  StorageType.BelowGroundTank as string,
  StorageType.Box as string,
  StorageType.Can as string,
  StorageType.Carboy as string,
  StorageType.Cylinder as string,
  StorageType.FiberDrum as string,
  StorageType.GlassBottlesOrJugs as string,
  StorageType.Other as string,
  StorageType.PlasticBottlesOrJugs as string,
  StorageType.PlasticOrNonMetallicDrum as string,
  StorageType.RailCar as string,
  StorageType.Silo as string,
  StorageType.SteelDrum as string,
  StorageType.TankInsideBuilding as string,
  StorageType.TankWagon as string,
  StorageType.ToteBin as string,
];

export const MN_STORAGE_TYPE_DESCRIPTIONS =
  CT_DE_KY_MN_STORAGE_TYPE_DESCRIPTIONS;

export const OR_STORAGE_TYPE_DESCRIPTIONS =
  AZ_OR_SD_WA_STORAGE_TYPE_DESCRIPTIONS;

export const SD_STORAGE_TYPE_DESCRIPTIONS =
  AZ_OR_SD_WA_STORAGE_TYPE_DESCRIPTIONS;

export const TX_STORAGE_TYPE_DESCRIPTIONS = <const>[
  StorageType.AboveGroundTank as string,
  StorageType.Bag as string,
  StorageType.Battery as string,
  StorageType.BelowGroundTank as string,
  StorageType.Box as string,
  StorageType.Can as string,
  StorageType.Carboy as string,
  StorageType.Cylinder as string,
  StorageType.FiberDrum as string,
  StorageType.GlassBottlesOrJugs as string,
  StorageType.Other as string,
  StorageType.PlasticBottlesOrJugs as string,
  StorageType.PlasticOrNonMetallicDrum as string,
  StorageType.RailCar as string,
  StorageType.Silo as string,
  StorageType.SteelDrum as string,
  StorageType.TankInsideBuilding as string,
  StorageType.TankWagon as string,
  StorageType.ToteBin as string,
];

export const WA_STORAGE_TYPE_DESCRIPTIONS =
  AZ_OR_SD_WA_STORAGE_TYPE_DESCRIPTIONS;

const DropdownOptionsByState: Partial<
  Record<StateAbbreviation, DropdownOption[]>
> = {
  AZ: AZ_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  CO: CO_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  CT: CT_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  DE: DE_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  KY: KY_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  LA: LA_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  MN: MN_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  OR: OR_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  SD: SD_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  TX: TX_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
  WA: WA_STORAGE_TYPE_DESCRIPTIONS.map(mapIntoDropdownOptions),
};

export const disallowCustomStorageTypeStates = [
  "AZ",
  "KS",
  "MO",
  "NJ",
  "SD",
  "TX",
  "WA",
];

export const getStorageTypeDropdownOptionsForState = (
  state: StateAbbreviation
) => {
  return DropdownOptionsByState[state] || STORAGE_TYPE_DROPDOWN_OPTIONS;
};

export const STORAGE_TYPE_DROPDOWN_OPTIONS = STORAGE_TYPE_DESCRIPTIONS.map(
  mapIntoDropdownOptions
);

export type StorageTypeDescriptions = typeof STORAGE_TYPE_DESCRIPTIONS[number];

export const PRESSURE_DESCRIPTIONS = <const>[
  "Ambient pressure",
  "Greater than ambient pressure",
  "Less than ambient pressure",
];

export type PressureDescriptions = typeof PRESSURE_DESCRIPTIONS[number];

export const PRESSURE_DROPDOWN_OPTIONS = PRESSURE_DESCRIPTIONS.map(
  mapIntoDropdownOptions
);

export const TEMPERATURE_DESCRIPTIONS = <const>[
  "Ambient temperature",
  "Cryogenic conditions",
  "Greater than ambient temperature",
  "Less than ambient temperature but not cryogenic",
];

export type TemperatureDescriptions = typeof TEMPERATURE_DESCRIPTIONS[number];

export const TEMPERATURE_DROPDOWN_OPTIONS = TEMPERATURE_DESCRIPTIONS.map(
  mapIntoDropdownOptions
);

/*
 * OR specific location enums
 */
export const OR_INSIDE_OUTSIDE_DESCRIPTIONS = <const>["Inside", "Outside"];

export type ORInsideOutsideDescriptions =
  typeof OR_INSIDE_OUTSIDE_DESCRIPTIONS[number];

export const OR_INSIDE_OUTSIDE_OPTIONS = OR_INSIDE_OUTSIDE_DESCRIPTIONS.map(
  mapIntoDropdownOptions
);

export const OR_STORAGE_QUADRANT_DESCRIPTIONS = <const>[
  "N",
  "S",
  "E",
  "W",
  "NE",
  "SE",
  "NW",
  "SW",
  "C (Center)",
  "V (Various)",
];

export type ORStorageQuadrantDescriptions =
  typeof OR_STORAGE_QUADRANT_DESCRIPTIONS[number];

// Utility type to extract the id part from a string (the part before a space character)
type ExtractID<T extends string> = T extends `${infer ID} ${string}` ? ID : T;

export const OR_STORAGE_QUADRANT_OPTIONS = OR_STORAGE_QUADRANT_DESCRIPTIONS.map(
  (item) => ({
    id: item.split(" ")[0] as ExtractID<typeof item>,
    label: item,
  })
);

// The various id values that map to OR_STORAGE_QUADRANT_DESCRIPTIONS
export type ORStorageQuadrantOptionID =
  typeof OR_STORAGE_QUADRANT_OPTIONS[number]["id"];

/*
 * LA Specific location options
 */

export const LA_PRESSURE_DESCRIPTIONS = <const>[
  ...PRESSURE_DESCRIPTIONS,
  "Other",
];

const pressureFreeTextIndex = PRESSURE_DESCRIPTIONS.length;
export const LA_PRESSURE_FREE_TEXT_SELECTION =
  LA_PRESSURE_DESCRIPTIONS[pressureFreeTextIndex];

export type LAPressureDescriptions = typeof LA_PRESSURE_DESCRIPTIONS[number];

export const LA_PRESSURE_DROPDOWN_OPTIONS = LA_PRESSURE_DESCRIPTIONS.map(
  mapIntoDropdownOptions
);

export const LA_TEMPERATURE_DESCRIPTIONS = <const>[
  ...TEMPERATURE_DESCRIPTIONS,
  "Other",
];

const temperatureFreeTextIndex = TEMPERATURE_DESCRIPTIONS.length;
export const LA_TEMPERATURE_FREE_TEXT_SELECTION =
  LA_TEMPERATURE_DESCRIPTIONS[temperatureFreeTextIndex];

export type LATEMPERATUREDescriptions =
  typeof LA_TEMPERATURE_DESCRIPTIONS[number];

export const LA_TEMPERATURE_DROPDOWN_OPTIONS = LA_TEMPERATURE_DESCRIPTIONS.map(
  mapIntoDropdownOptions
);
