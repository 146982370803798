/**
 * Formats an address string in the format of "123 Main St, Springfield, IL 62704"
 * Returns an empty string if all inputs are null/undefined
 */
export function formatAddress(
  streetAddress: string | null | undefined,
  city: string | null | undefined,
  state: string | null | undefined,
  zip: string | null | undefined
): string {
  // Trim each part if it exists
  const trimmedStreet = streetAddress?.trim();
  const trimmedCity = city?.trim();
  const trimmedState = state?.trim();
  const trimmedZip = zip?.trim();

  const stateZip =
    trimmedState && trimmedZip
      ? `${trimmedState} ${trimmedZip}`
      : trimmedState || trimmedZip;

  return [trimmedStreet, trimmedCity, stateZip]
    .filter((part): part is string => Boolean(part))
    .join(", ");
}
