import {
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Popover,
  SelectProps,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  FieldError,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
} from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";
import React from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

export type SelectItem = {
  value: any;
  display: string;
  disabled?: boolean;
};

export const FormSelectWithOther = <T extends FieldValues>({
  name,
  label,
  tooltip,
  disabled,
  control,
  selectItems,
  helperText,
  rules,
  sx,
  hideErrors,
}: {
  label: string;
  tooltip?: string;
  disabled?: boolean;
  selectProps?: SelectProps;
  selectItems: SelectItem[];
  helperText?: string;
  rules?: RegisterOptions;
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps<Theme>;
  selectSx?: SxProps<Theme>;
  hideErrors?: boolean;
} & UseControllerProps<T>) => {
  const labelWithTooltip = useMemo(
    () =>
      tooltip ? <TextWithTooltip text={label} tooltip={tooltip} /> : label,
    [label, tooltip]
  );

  return (
    <FormControl fullWidth sx={sx} required={!!rules?.required}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <SelectWithOtherControl
            value={field.value}
            onChange={field.onChange}
            selectItems={selectItems}
            error={error}
            helperText={helperText}
            hideErrors={hideErrors}
            labelWithTooltip={labelWithTooltip}
            disabled={disabled}
          />
        )}
      />
    </FormControl>
  );
};

type SelectWithOtherControlProps = {
  value: string;
  onChange: (value: string) => void;
  selectItems: SelectItem[];
  error: FieldError | undefined;
  helperText: string | undefined;
  hideErrors: boolean | undefined;
  labelWithTooltip: React.ReactNode;
  disabled: boolean | undefined;
};

const SelectWithOtherControl = (props: SelectWithOtherControlProps) => {
  const {
    value = "",
    onChange,
    selectItems,
    error,
    helperText,
    hideErrors,
    labelWithTooltip,
    disabled,
  } = props;
  const internalValue = useMemo(() => value ?? "", [value]);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const otherInputRef = useRef<HTMLInputElement>(null);
  const [selectedElement, setSelectedElement] = useState<HTMLLIElement | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [otherValue, setOtherValue] = useState("");
  const [shouldFocusOther, setShouldFocusOther] = useState(false);

  const open = Boolean(anchorEl);

  // Determine if current value is a predefined item or "other"
  const isOtherValue =
    internalValue !== "" &&
    !selectItems.some((item) => item.value === internalValue);

  useEffect(() => {
    if (otherValue == "") {
      const foundOtherValue = isOtherValue ? internalValue : "";
      setOtherValue(foundOtherValue);
    }
  }, [otherValue, internalValue, selectItems, isOtherValue]);

  // Focus management for other input
  useEffect(() => {
    if (open && isOtherValue) {
      setShouldFocusOther(true);
    } else if (!open) {
      setShouldFocusOther(false);
    }
  }, [open, isOtherValue]);

  useEffect(() => {
    if (shouldFocusOther && otherInputRef.current) {
      // Small delay to ensure the popover is fully rendered
      const timeoutId = setTimeout(() => {
        otherInputRef.current?.focus();
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }, [shouldFocusOther]);

  // Scroll selected item into view when popover opens
  useEffect(() => {
    if (open && selectedElement) {
      setTimeout(() => {
        selectedElement?.scrollIntoView({ block: "nearest" });
      }, 0);
    }
  }, [open, selectedElement]);

  const handleItemClick = (itemValue: string) => {
    onChange(itemValue);
    setOtherValue("");
    handleClose();
  };

  const handleOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherValue(e.target.value);
    onChange(e.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShouldFocusOther(false);
  };

  const handleOtherKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleClose();
      textFieldRef.current?.focus();
    } else if (e.key === "Tab") {
      e.preventDefault();
      handleClose();
      textFieldRef.current?.focus();
    } else if (e.key === "Escape") {
      handleClose();
      textFieldRef.current?.focus();
    }
  };

  const handleMainInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!open) {
        setAnchorEl(e.currentTarget);
      }
    }
  };

  return (
    <>
      <TextField
        fullWidth
        error={!!error}
        ref={textFieldRef}
        disabled={disabled}
        label={labelWithTooltip}
        onClick={handleClick}
        onKeyDown={handleMainInputKeyDown}
        value={internalValue}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                cursor: "pointer",
                "& input": { cursor: "pointer" },
              }}
            >
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        sx={{
          cursor: "pointer",
          "& input": { cursor: "pointer" },
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              paddingTop: 1,
              paddingBottom: 1,
              width: textFieldRef.current
                ? textFieldRef.current.offsetWidth
                : "auto",
              maxHeight: "300px",
            },
          },
        }}
      >
        {selectItems?.map((item: SelectItem) => {
          const isSelected = item.value === internalValue;
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={item.disabled}
              onClick={() => handleItemClick(item.value)}
              selected={isSelected}
              ref={isSelected ? setSelectedElement : null}
            >
              <ListItemText primary={item.display} />
            </MenuItem>
          );
        })}
        <MenuItem
          key="other"
          selected={isOtherValue}
          ref={isOtherValue ? setSelectedElement : null}
          onClick={() => {
            setShouldFocusOther(true);
            otherInputRef.current?.focus();
          }}
        >
          <TextField
            value={otherValue}
            onChange={handleOther}
            onKeyDown={handleOtherKeyDown}
            inputRef={otherInputRef}
            fullWidth
            size="small"
            placeholder="Other"
            onClick={(e) => e.stopPropagation()}
          />
        </MenuItem>
      </Popover>
      <ErrorDisplay
        error={error}
        helperText={helperText}
        hideErrors={hideErrors}
      />
    </>
  );
};
