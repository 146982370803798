import { useMutation, useQuery } from "@apollo/client";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import { gql } from "generated-graphql";
import { FacilityProductsQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { ReactNode, useCallback, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { formatChemicalName } from "util/chemicalName";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { FacilityProductForm } from "./Inventory/Facility/FacilityProductForm";
import { ProductsTableProps } from "./ProductsTable";
import {
  DELETE_FACILITY_PRODUCT,
  FACILITY_PRODUCTS_QUERY,
  FacilityProductRow,
} from "./schema";
import { useIgnoredIssues } from "./Report/useReport";

const COPY_PRODUCT_INVENTORY = gql(`
  mutation CopyProductInventory($facilityId: ID!, $toYear: Int!) {
    copyFacilityProducts(facilityId: $facilityId, toYear: $toYear) {
      success
      reason
      fromYear
    }
  }
`);

const FACILITY_INVENTORY_STATUS = gql(`
  query GetFacilityProductInventoryStatus($facilityId: ID!, $toYear: Int!) {
    facilityInventoryStatus(facilityId: $facilityId, toYear: $toYear, inventory: PRODUCT) {
      exists
      mostRecentYear
    }
  }
`);

export function FacilityProductsTable({
  facilityId,
  reportId,
}: ProductsTableProps) {
  const ignoredIssues = useIgnoredIssues();
  const alerts = useAlerts();
  const reportingYear = useSelectedReportingYear();

  const [editFacilityProductId, setEditFacilityProductId] = useState<
    string | undefined
  >(undefined);

  const [deletingFacilityProduct, setDeletingFacilityProduct] = useState<
    FacilityProductRow | undefined
  >(undefined);

  const [deleteFacilityProduct, { loading: deleting }] = useMutation(
    DELETE_FACILITY_PRODUCT,
    {
      refetchQueries: [
        "InventoryFacilityProducts",
        "InventoryOverview",
        "ReportingFacilityChemicals",
        "FacilityProducts",
        "GetReportDetails",
      ],
    }
  );

  const [copyProductInventory, { loading: isLoadingInventory }] = useMutation(
    COPY_PRODUCT_INVENTORY,
    {
      refetchQueries: ["FacilityProducts"],
      variables: { facilityId, toYear: reportingYear },
      onCompleted({ copyFacilityProducts }) {
        if (copyFacilityProducts.success === false) {
          alerts.info("No product inventory was copied");
          return;
        }

        alerts.success(
          `Successfully copied product inventory from ${copyFacilityProducts.fromYear}`
        );
      },
      onError(err) {
        alerts.error(
          "There was an error copying this facility's product inventory"
        );
        console.error(err);
      },
    }
  );

  const { data: facilityInventoryStatus } = useQuery(
    FACILITY_INVENTORY_STATUS,
    {
      variables: { facilityId, toYear: reportingYear },
      fetchPolicy: "cache-and-network",
    }
  );

  const handleDelete = useCallback(async () => {
    try {
      invariant(deletingFacilityProduct?.id);
      await deleteFacilityProduct({
        variables: { id: deletingFacilityProduct.id },
      });
      alerts.success("Successfully removed product from facility");
      setDeletingFacilityProduct(undefined);
    } catch (err) {
      alerts.error(
        "An error occurred while removing product from facility",
        err
      );
    }
  }, [
    alerts,
    deleteFacilityProduct,
    deletingFacilityProduct,
    setDeletingFacilityProduct,
  ]);

  const columns: OmnisearchGridColDef<
    FacilityProductsQuery["facilityProducts"]["items"][0]
  >[] = [
    {
      field: "productName",
      headerName: "Product",
      flex: 2,
      topAligned: true,
      valueGetter(params) {
        return params.row.product?.name;
      },
    },
    {
      field: "maxQuantity",
      headerName: "Max Qty",
      flex: 0.8,
      topAligned: true,
      valueGetter(params) {
        return params.row.maxQuantity;
      },
    },
    {
      field: "averageQuantity",
      headerName: "Avg Qty",
      flex: 0.8,
      topAligned: true,
      valueGetter(params) {
        return params.row.averageQuantity;
      },
    },
    {
      field: "chemicals",
      headerName: "Chemicals in Product",
      flex: 2,
      sortable: false,
      topAligned: true,
      renderCell(params) {
        return (
          <Stack spacing={0}>
            {(params.row.chemicalAmounts ?? []).map((ca) => (
              <Typography
                key={ca.chemicalId}
                variant="body2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                  whiteSpace: "nowrap",
                }}
              >
                <Tooltip title={formatChemicalName(ca.chemical)}>
                  <span>{ca.chemical.name}</span>
                </Tooltip>
              </Typography>
            ))}
          </Stack>
        );
      },
    },
    {
      field: "maxAmount",
      headerName: "Max Amount",
      flex: 1,
      sortable: false,
      topAligned: true,
      renderCell(params) {
        return (
          <Stack spacing={0}>
            {(params.row.chemicalAmounts ?? []).map((ca) => (
              <Typography
                key={ca.chemicalId}
                variant="body2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                  whiteSpace: "nowrap",
                }}
              >
                {prettyPrintQuantity(ca.maxAmount, ca.unit, true)}
              </Typography>
            ))}
          </Stack>
        );
      },
    },
    {
      field: "averageAmount",
      headerName: "Avg Amount",
      flex: 1,
      sortable: false,
      topAligned: true,
      renderCell(params) {
        return (
          <Stack spacing={0}>
            {(params.row.chemicalAmounts ?? []).map((ca) => (
              <Typography
                key={ca.chemicalId}
                variant="body2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "inherit",
                  whiteSpace: "nowrap",
                }}
              >
                {prettyPrintQuantity(ca.averageAmount, ca.unit, true)}
              </Typography>
            ))}
          </Stack>
        );
      },
    },
    {
      field: "issues",
      headerName: "Issues",
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell({ row }) {
        const shouldIgnore = ignoredIssues.some((issue) =>
          row.issues?.some((i) => i.modelId === issue.modelId)
        );
        return (
          <IssueCount
            issueCount={row.issues?.length}
            ignoreForReport={shouldIgnore}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key="edit"
            label="Edit"
            icon={<Edit />}
            onClick={() => setEditFacilityProductId(params.row.id)}
          >
            <Edit />
          </GridActionsCellItem>,
          <GridActionsCellItem
            key="delete"
            label="Delete"
            icon={<Delete />}
            onClick={() => setDeletingFacilityProduct(params.row)}
          >
            <Delete />
          </GridActionsCellItem>,
        ];
      },
    },
  ];

  const defaultSearch = useMemo(
    () => `facilityId:${facilityId} reportingYear:${reportingYear}`,
    [facilityId, reportingYear]
  );

  const commandButtons = useMemo(() => {
    const buttons: ReactNode[] = [];
    if (!reportId) {
      buttons.push(
        <TierIIYearPicker
          key="yearPicker"
          selectProps={{
            size: "small",
          }}
        />
      );
    }
    buttons.push(
      <Button
        key="add"
        variant="contained"
        onClick={() => setEditFacilityProductId("new")}
        size="small"
        sx={{ width: { xs: "100%", sm: "auto" } }}
      >
        Add Product
      </Button>
    );
    return buttons;
  }, [reportId]);

  return (
    <>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={FACILITY_PRODUCTS_QUERY}
        getItems={(data) => data.facilityProducts.items}
        getCount={(data) => data.facilityProducts.count}
        noDataMessage={`No product inventory has been added to this facility for ${reportingYear}${
          !facilityInventoryStatus?.facilityInventoryStatus.exists
            ? " or any previous year"
            : ""
        }.`}
        noDataButtonText={
          facilityInventoryStatus?.facilityInventoryStatus.exists
            ? `Copy Inventory from ${facilityInventoryStatus?.facilityInventoryStatus.mostRecentYear}`
            : undefined
        }
        noDataOnButtonClick={copyProductInventory}
        otherLoading={isLoadingInventory}
        isRowSelectable={() => false}
        defaultSearch={defaultSearch}
        onRowClick={(params) => setEditFacilityProductId(params.row.id)}
        excludeFilterColumns={[
          "maxQuantity",
          "averageQuantity",
          "maxAmount",
          "averageAmount",
          "issues",
        ]}
        initialSortModel={[
          {
            field: "productName",
            sort: "asc",
          },
        ]}
        withPadding={false}
        commandButtons={commandButtons}
        rowHeight={"auto"}
      />
      {deletingFacilityProduct && (
        <ConfirmDialog
          open={!!deletingFacilityProduct}
          onClose={() => setDeletingFacilityProduct(undefined)}
          onConfirm={handleDelete}
          title="Remove Product?"
          msg={DeleteProductMessage({
            productName: deletingFacilityProduct.product?.name,
            reportingYear,
            forMeasurements: false,
          })}
          confirmText="delete"
          loading={deleting}
        />
      )}
      {editFacilityProductId && (
        <FacilityProductForm
          facilityProductId={editFacilityProductId}
          facilityId={facilityId}
          reportingYear={reportingYear}
          onClose={() => setEditFacilityProductId(undefined)}
        />
      )}
    </>
  );
}

export function DeleteProductMessage({
  productName,
  reportingYear,
  forMeasurements,
}: {
  productName?: string;
  reportingYear: number;
  forMeasurements: boolean;
}) {
  return (
    <>
      <Typography>
        {productName} will be deleted from your product inventory for reporting
        year {reportingYear}.
      </Typography>
      <Typography component="span">
        This action will:
        <ul>
          <li>Remove {productName} from your product inventory.</li>
          {forMeasurements && (
            <li>Remove all daily measurements for this product.</li>
          )}
          <li>
            Reduce your total reported chemical quantities by the amount this
            product contributed.
          </li>
        </ul>
      </Typography>
      <Typography component="span">
        This action will <b>not:</b>
        <ul>
          <li>Change the entry for {productName} in your product catalog.</li>
          <li>Affect chemical inventory reported as Other Chemicals.</li>
          <li>Affect previous reporting years.</li>
        </ul>
      </Typography>
      <Typography>
        Are you sure you want to remove {productName} from your product
        inventory?
      </Typography>
    </>
  );
}
