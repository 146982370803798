import { DocumentType } from "../generated-graphql/oak-resolver-types";
import { prettyPrintEnumValue } from "./prettyPrintEnumValue";

export function prettyPrintDocumentType(
  documentType?: DocumentType
): string | undefined {
  if (!documentType) return undefined;
  const documentTypeMap: Record<DocumentType, string> = {
    ASTDocumentation: "AST Documentation",
    Certification: "Certification",
    Check: "Check",
    CoverLetter: "Cover Letter",
    DikeDescription: "Dike Description",
    EmergencyResponsePlan: "Emergency Response Plan",
    EPCRA302: "EPCRA 302",
    EPCRA304: "EPCRA 304",
    EPCRA311: "EPCRA 311",
    EPCRA312: "EPCRA 312",
    EPCRA313: "EPCRA 313",
    FeeCalculation: "Fee Calculation",
    Fees: "Fees",
    Form: "Form",
    HazardousMaterialsContingencyPlanOrQuickGuide:
      "Hazardous Materials Contingency Plan/Quick Guide",
    HazardousMaterialsInventoryStatement:
      "Hazardous Materials Inventory Statement",
    HazardousMaterialsManagementPlan: "Hazardous Materials Management Plan",
    HazardousWasteAnnualReport: "Hazardous Waste Annual Report",
    HazardousWasteBiennialReport: "Hazardous Waste Biennial Report",
    HazardousWasteContingencyPlan: "Hazardous Waste Contingency Plan",
    HazardousWasteFeesReceipts: "Hazardous Waste Fees Receipts",
    HazardousWasteNotification: "Hazardous Waste Notification",
    HazardousWasteQuarterlyReport: "Hazardous Waste Quarterly Report",
    HazardousWasteRecertification: "Hazardous Waste Recertification",
    Invoice: "Invoice",
    Other: "Other",
    OtherRCRA: "Other RCRA",
    P2Plan: "P2 Plan",
    P2Update: "P2 Update",
    Permit: "Permit",
    Proof: "Proof",
    RCRAAnnual: "RCRA Annual",
    RCRAFeePaymentOrInvoice: "RCRA Fee Payment/Invoice",
    RCRAManifest: "RCRA Manifest",
    RCRAUpdateNotification: "RCRA Update Notification",
    Receipt: "Receipt",
    ReferenceImage: "Reference Image",
    Report: "Report",
    RevisionOrUpdate: "Revision/Update",
    SafeguardMeasures: "Safeguard Measures",
    SafetyDataSheet: "Safety Data Sheet",
    Screenshot: "Screenshot",
    SiteCoordinateAbbreviation: "Site Coordinate Abbreviation",
    SitePlan: "Site Plan",
    Submission: "Submission",
    TrainingPlan: "Training Plan",
    USTDocumentation: "UST Documentation",
    USTMonitoringSitePlan: "UST Monitoring Site Plan",
    USTCertificationOfFinancialResponsibility:
      "UST Certification of Financial Responsibility",
    USTResponsePlan: "UST Response Plan",
    USTOwnerOperatorWrittenAgreement: "UST Owner/Operator Written Agreement",
    USTLetterFromChiefFinancialOfficer:
      "UST Letter from Chief Financial Officer",
    OwnerStatementOfDesignatedUSTOperatorCompliance:
      "Owner Statement of Designated UST Operator Compliance",
    RecyclableMaterialsReportDocumentation:
      "Recyclable Materials Report Documentation",
    HazardousWasteTankClosureCertificate:
      "Hazardous Waste Tank Closure Certificate",
    AboveGroundPetroleumStorageActDocumentation:
      "Above Ground Petroleum Storage Act Documentation",
  };

  // Return the mapped value if it exists, otherwise use prettyPrintEnumValue
  return documentTypeMap[documentType] || prettyPrintEnumValue(documentType);
}
