import { useMutation } from "@apollo/client";
import CloudUploadOutlined from "@mui/icons-material/CloudUploadOutlined";
import EmojiEvents from "@mui/icons-material/EmojiEvents";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";
import { ActivityType } from "generated-graphql/graphql";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { getActivityTitle } from "util/activity";
import { ResetReportButton } from "../ResetReportButton";
import { ResetReportModal } from "../ResetReportModal";
import { useReportDetails } from "../useReportDetails";
import { ActivityStepper } from "./ActivityStepper";

export function Activities() {
  const theme = useTheme();
  const { data } = useReportDetails();
  const [resetReportModalOpen, setResetReportModalOpen] = useState(false);

  const currentWorkflow = data?.tierIIReport.currentWorkflow;
  const currentActivityId =
    data?.tierIIReport.currentWorkflow?.currentActivityId;

  const shouldAutoNavigateToCurrentActivity = useMatch(
    "/staff/fulfillment/:reportId/activities"
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldAutoNavigateToCurrentActivity && currentActivityId) {
      navigate(currentActivityId, { replace: true });
    }
  }, [currentActivityId, navigate, shouldAutoNavigateToCurrentActivity]);

  const alerts = useAlerts();
  const ConductReportMutation = gql(`
    mutation ConductReport($reportId: ID!) {
      conductReport(reportId: $reportId)
  }`);
  const [conductMutation, { loading: conductMutationLoading }] = useMutation(
    ConductReportMutation,
    {
      variables: { reportId: data?.tierIIReport.id ?? "" },
      refetchQueries: ["GetTierIIReport"],
      onCompleted: () => {
        alerts.success("Report is being conducted");
      },
      onError: (error) => {
        alerts.error(`Error conducting report: ${error.message}`);
      },
    }
  );

  const toggleResetModal = useCallback(
    () => setResetReportModalOpen(!resetReportModalOpen),
    [resetReportModalOpen]
  );
  const Fallback = (
    <>
      <Grid
        item
        xs={4}
        sx={{
          display: "flex",
          borderRight: `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Box
          display="flex"
          gap={1}
          sx={{ padding: theme.spacing(2), mt: "auto", width: "100%" }}
        >
          <ResetReportButton onClick={toggleResetModal} />
          <ResetReportModal
            open={resetReportModalOpen}
            toggleResetReportModal={toggleResetModal}
          />
          <LoadingButton
            variant="outlined"
            size="small"
            color="info"
            loadingPosition="start"
            startIcon={<CloudUploadOutlined />}
            onClick={() => conductMutation()}
            disabled={!data?.tierIIReport.id}
            loading={conductMutationLoading}
          >
            Conduct report
          </LoadingButton>
        </Box>
      </Grid>
      <Grid item xs={8} padding={theme.spacing(2)}>
        <Typography variant="h5">No active workflow</Typography>
        <Typography variant="body1">
          {`The report has not started processing yet. This may be due to the report's verification status or a reset in progress for a previous workflow. The workflow initiation is expected to take around 5 minutes.`}
        </Typography>
      </Grid>
    </>
  );

  const championChips = data?.tierIIReport.currentWorkflow?.activities
    .filter((a) =>
      [
        ActivityType.WaitForAllStateReports,
        ActivityType.WaitForAllCnReports,
        ActivityType.WaitForAllAltIdReports,
        ActivityType.WaitForAllCredentialReports,
      ].includes(a.type)
    )
    .map((activity, index) => {
      const isChampion = activity.championId === data?.tierIIReport.id;
      return (
        <Tooltip key={index} title={getActivityTitle(activity)}>
          <Chip
            icon={isChampion ? <EmojiEvents /> : undefined}
            label={isChampion ? "Champion" : "Non-champion"}
            color="primary"
            sx={{ mt: 1 }}
            onClick={() =>
              navigate(
                `/staff/fulfillment?omnisearch=cohortChampion:${activity.championId}`
              )
            }
          />
        </Tooltip>
      );
    });

  return (
    <Grid
      container
      component={Paper}
      minHeight={"32rem"}
      sx={{ border: `solid 1px ${theme.palette.divider}` }}
    >
      {!currentWorkflow ? (
        Fallback
      ) : (
        <>
          <Grid
            item
            xs={4}
            sx={{
              borderRight: `solid 1px ${theme.palette.divider}`,
              overflowX: "hidden",
            }}
            display="flex"
            flexDirection="column"
          >
            <Box flex={1} sx={{ overflowY: "auto" }}>
              <Box sx={{ padding: theme.spacing(2) }}>
                <Typography variant="h5" sx={{ color: "gray" }}>
                  Tier II Report
                </Typography>
                {championChips}
              </Box>
              <ActivityStepper />
            </Box>
            <Box display="flex" gap={1} sx={{ padding: theme.spacing(2) }}>
              <ResetReportButton onClick={toggleResetModal} />
              <ResetReportModal
                open={resetReportModalOpen}
                toggleResetReportModal={toggleResetModal}
              />
              <LoadingButton
                variant="outlined"
                size="small"
                color="info"
                loadingPosition="start"
                startIcon={<CloudUploadOutlined />}
                onClick={() => conductMutation()}
                disabled={!data?.tierIIReport.id}
                loading={conductMutationLoading}
              >
                Conduct report
              </LoadingButton>
            </Box>
          </Grid>
          <Grid
            display="flex"
            item
            xs={8}
            flexDirection="column"
            padding={theme.spacing(1)}
          >
            <Outlet />
          </Grid>
        </>
      )}
    </Grid>
  );
}
