import { StateAbbreviation } from "encamp-shared/dist/constants/states";

export type FacilityStateFields =
  | AL_FACILITY_STATE_FIELDS
  | AR_FACILITY_STATE_FIELDS
  | AZ_FACILITY_STATE_FIELDS
  | CA_FACILITY_STATE_FIELDS
  | CO_FACILITY_STATE_FIELDS
  | DE_FACILITY_STATE_FIELDS
  | FL_FACILITY_STATE_FIELDS
  | HI_FACILITY_STATE_FIELDS
  | ID_FACILITY_STATE_FIELDS
  | IL_FACILITY_STATE_FIELDS
  | IN_FACILITY_STATE_FIELDS
  | KS_FACILITY_STATE_FIELDS
  | KY_FACILITY_STATE_FIELDS
  | LA_FACILITY_STATE_FIELDS
  | MA_FACILITY_STATE_FIELDS
  | MD_FACILITY_STATE_FIELDS
  | ME_FACILITY_STATE_FIELDS
  | MI_FACILITY_STATE_FIELDS
  | MN_FACILITY_STATE_FIELDS
  | MO_FACILITY_STATE_FIELDS
  | ND_FACILITY_STATE_FIELDS
  | NE_FACILITY_STATE_FIELDS
  | NJ_FACILITY_STATE_FIELDS
  | NM_FACILITY_STATE_FIELDS
  | NV_FACILITY_STATE_FIELDS
  | NY_FACILITY_STATE_FIELDS
  | OH_FACILITY_STATE_FIELDS
  | OK_FACILITY_STATE_FIELDS
  | OR_FACILITY_STATE_FIELDS
  | PA_FACILITY_STATE_FIELDS
  | RI_FACILITY_STATE_FIELDS
  | SD_FACILITY_STATE_FIELDS
  | TX_FACILITY_STATE_FIELDS
  | UT_FACILITY_STATE_FIELDS
  | WA_FACILITY_STATE_FIELDS
  | WI_FACILITY_STATE_FIELDS
  | WV_FACILITY_STATE_FIELDS;

export type AL_FACILITY_STATE_FIELDS = {
  // label: Master ID Number
  masterIdNumber: number;
};

export type AR_FACILITY_STATE_FIELDS = {
  // label: Are you regulated under the Department of Homeland Security CFATS program?
  isRegulatedByCfats: boolean | undefined;
  // label: Are you regulated under the RCRA as a generator of hazardous waste?
  rcraGenerator: AR_RcraGenerator | undefined;
  // label: Are you regulated under the OSHA Process Safety Management program?
  isRegulatedByOshaPsm: boolean | undefined;
  // label: Have you reported any spill or released Hazardous Material to the National Reponse Center (NRC) in the past 5 years?
  hasReportedSpill: boolean | undefined;
  // label: Report Date(s)
  /**
   * when:
   * {"value":"Yes","key":"hasReportedSpill"}
   **/
  spillReportDates: string | undefined;
  // label: National Response Center (NRC) Case Number(s)
  /**
   * when:
   * {"value":"Yes","key":"hasReportedSpill"}
   **/
  nrcCaseNumbers: string | undefined;
};

export type AZ_FACILITY_STATE_FIELDS = {
  // label: Cross Streets, Landmarks, or Directions to the facility (if applicable)
  directionsToFacility: string | undefined;
  // label: SIC Code
  sicCode: number;
  // label: Tribal Land
  tribalLand: AZ_TribalLand;
  // label: Facility fax number
  faxNumber: string | undefined;
  // label: Facility email
  email: string;
  // label: Does Facility Reside on State Leased Land?
  isOnStateLeasedLand: boolean | undefined;
  // label: Pursuant to applicable regulatory requirements (40 CFR 370.40 / Section 311(e) of Title III) this facility is not required to submit a Tier Two Emergency and Hazardous Chemical Inventory Form for the current reporting year?
  isVoluntary: boolean | undefined;
  // label: Mailing Address Name
  mailingAddressName: string;
  onlyLeadAcidBatteries: boolean | undefined;
};

export type CA_FACILITY_STATE_FIELDS = {
  // label: Assessor Parcel Number (APN)
  /**
   * when:
   * {"params":["function:isCaApnNumberRequired",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  apnNumber: string | undefined;
  // label: Number of Employees
  /**
   * when:
   * {"params":["function:isCaNumberOfEmployeesRequired",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  numberOfEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Facility owns or operates underground storage tanks
  hazardousMaterialsOnSite: boolean | undefined;
  hasUST: boolean | undefined;
  // label: Facility generates hazardous waste
  hasHazardousWaste: boolean;
  // label: Facility treats hazardous waste on_site
  /**
   * when:
   * {"value":true,"key":"hasHazardousWaste"}
   **/
  hazardousWasteOnSite: boolean | undefined;
  // label: Facility's treatment is subject to financial assurance requirements for closure of an onsite treatment unit
  /**
   * when:
   * {"value":true,"key":"hasHazardousWaste"}
   **/
  hazardousWasteFinancialAssuranceRequirements: boolean | undefined;
  // label: Facility consolidates hazardous waste generated at a remote site
  /**
   * when:
   * {"value":true,"key":"hasHazardousWaste"}
   **/
  hazardousWasteConsolidate: boolean | undefined;
  // label: Facility needs to report the closure/removal of a tank that was classified as hazardous waste and cleaned on_site
  /**
   * when:
   * {"value":true,"key":"hasHazardousWaste"}
   **/
  hazardousWasteTankClosureRemoval: boolean | undefined;
  // label: Facility is a RCRA Large Quantity Generator
  /**
   * when:
   * {"value":true,"key":"hasHazardousWaste"}
   **/
  hazardousWasteRCRA: boolean | undefined;
  // label: Facility is a Household Hazardous Waste (HHW) Collection Site
  /**
   * when:
   * {"value":true,"key":"hasHazardousWaste"}
   **/
  hazardousWasteHHWCollectionSite: boolean | undefined;
  // label: Facility recycles more than 100 kg/month of excluded or exempted recyclable materials.
  hasExcludedExemptedMaterials: boolean;
  // label: Facility owns or operates aboveground petroleum storage tanks or containers; and
  hasAPS: boolean;
  // label: Facility is conditionally exempt from having to prepare and implement a Spill Prevention, Control, and Countermeasure (SPCC) Plan
  /**
   * when:
   * {"value":true,"key":"hasAPS"}
   **/
  apsaConditionallyExempt: boolean | undefined | null;
  // label: Date of SPCC Plan Certification or Date of 5_Year Review (YYYY_MM_DD)
  /**
   * when:
   * {"value":true,"key":"hasAPS"}
   **/
  apsaDateOfCertificationOrReview: string | undefined;
  // label: Number of Tanks in Underground Area(s)
  /**
   * when:
   * {"value":true,"key":"hasAPS"}
   **/
  /**
   * when:
   * {"params":["function:isNonExemptAps",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  apsaTanksInUndergroundAreas: number | undefined;
  // label: Total Aboveground Storage Capacity of Petroleum in gallons
  /**
   * when:
   * {"value":true,"key":"hasAPS"}
   **/
  /**
   * when:
   * {"params":["function:isNonExemptAps",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  apsaTotalStorageCapacity: number | undefined;
  // label: Facility stores Regulated Substances onsite in quantities greater than the threshold quantities established by the CalARP Program
  hasRegulatedSubstancesGreaterThanThreshold: boolean | undefined;
  // label: Provide any additional information as necessary and/or required by your local regulator(s).
  additionalInformation: string | undefined;

  // label: Facility Fax Number
  facilityFaxNumber: string | undefined;

  // HMBP document fields
  sitePlanDocumentOption: CA_DocumentOption | undefined;
  sitePlanSuppliedIn: string | undefined;
  sitePlanSuppliedInExplanation: string | undefined;
  sitePlanExemptExplanation: string | undefined;

  emergencyResponseDocumentOption: CA_DocumentOption | undefined;
  emergencyResponseSuppliedIn: string | undefined;
  emergencyResponseSuppliedInExplanation: string | undefined;
  emergencyResponseExemptExplanation: string | undefined;

  apsaDocumentationDocumentOption: CA_DocumentOption | undefined;
  apsaDocumentationSuppliedIn: string | undefined;
  apsaDocumentationSuppliedInExplanation: string | undefined;
  apsaDocumentationExemptExplanation: string | undefined;

  hazardousWasteTankClosureCertificateDocumentOption:
    | CA_DocumentOption
    | undefined;
  hazardousWasteTankClosureCertificateSuppliedIn: string | undefined;
  hazardousWasteTankClosureCertificateSuppliedInExplanation: string | undefined;
  hazardousWasteTankClosureCertificateExemptExplanation: string | undefined;

  recyclableMaterialsReportDocumentOption: CA_DocumentOption | undefined;
  recyclableMaterialsReportSuppliedIn: string | undefined;
  recyclableMaterialsReportSuppliedInExplanation: string | undefined;
  recyclableMaterialsReportExemptExplanation: string | undefined;

  // Emergency Response Training Plan
  trainingPlanDocumentOption: CA_DocumentOption | undefined;
  trainingPlanSuppliedIn: string | undefined;
  trainingPlanSuppliedInExplanation: string | undefined;
  trainingPlanExemptExplanation: string | undefined;

  ownerStatementOfDesignatedUSTOperatorComplianceDocumentOption:
    | CA_DocumentOption
    | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceSuppliedIn: string | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceSuppliedInExplanation:
    | string
    | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceExemptExplanation:
    | string
    | undefined;

  ustCertificationOfFinancialResponsibilityDocumentOption:
    | CA_DocumentOption
    | undefined;
  ustCertificationOfFinancialResponsibilitySuppliedIn: string | undefined;
  ustCertificationOfFinancialResponsibilitySuppliedInExplanation:
    | string
    | undefined;
  ustCertificationOfFinancialResponsibilityExemptExplanation:
    | string
    | undefined;

  ustLetterFromChiefFinancialOfficerDocumentOption:
    | CA_DocumentOption
    | undefined;
  ustLetterFromChiefFinancialOfficerSuppliedIn: string | undefined;
  ustLetterFromChiefFinancialOfficerSuppliedInExplanation: string | undefined;
  ustLetterFromChiefFinancialOfficerExemptExplanation: string | undefined;

  ustMonitoringSitePlanDocumentOption: CA_DocumentOption | undefined;
  ustMonitoringSitePlanSuppliedIn: string | undefined;
  ustMonitoringSitePlanSuppliedInExplanation: string | undefined;
  ustMonitoringSitePlanExemptExplanation: string | undefined;

  ustOwnerOperatorWrittenAgreementDocumentOption: CA_DocumentOption | undefined;
  ustOwnerOperatorWrittenAgreementSuppliedIn: string | undefined;
  ustOwnerOperatorWrittenAgreementSuppliedInExplanation: string | undefined;
  ustOwnerOperatorWrittenAgreementExemptExplanation: string | undefined;

  ustResponsePlanDocumentOption: CA_DocumentOption | undefined;
  ustResponsePlanSuppliedIn: string | undefined;
  ustResponsePlanSuppliedInExplanation: string | undefined;
  ustResponsePlanExemptExplanation: string | undefined;

  ustPermitTypeOfAction: CA_UstPermitTypeOfAction | undefined;
  ustPermitFacilityType: CA_UstFacilityType | undefined;
  ustPermitBoeNumber: string | undefined;
  ustPermitIndianOrTrustLand: boolean | undefined;
  ustPermitHolderInfo: CA_UstPermitHolderInfo | undefined;
  ustPermitSdsOffice: string | undefined;
  ustPermitFinancialResponsibilityMechanisms: string[] | undefined;
  ustPermitFinancialResponsibilityMechanismOther: string | undefined;
};

export type CO_FACILITY_STATE_FIELDS = {
  // label: Facility Type
  facilityType: CO_FacilityType;
  // label: Well Head?
  /**
   * when:
   * {"value":"Oil and Gas","key":"facilityType"}
   **/
  oilAndGasType: CO_OilAndGasType[] | undefined;
  // label: Farm or Ranch?
  /**
   * when:
   * {"value":"Agriculture","key":"facilityType"}
   **/
  agricultureType: CO_AgricultureType[] | undefined;
};

export type DE_FACILITY_STATE_FIELDS = {
  // label: Development/Industrial Park
  developmentIndustrialPark: string | undefined;
  // label: Nature of Business
  natureOfBusiness: string;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Is this a government facility?
  isGovernmentFacility: boolean | undefined;
};

export type FL_FACILITY_STATE_FIELDS = {
  // label: Does your facility have a written emergency response plan?
  hasWrittenEmergencyResponsePlan: boolean | undefined;
  // label: Does your facility have a hazardous materials response team?
  hasHazardousMaterialsResponseTeam: boolean | undefined;
  // label: Does your local fire department have an up_to_date pre_plan for your facility?
  hasLocalFireDepartmentReceivedPrePlan: boolean | undefined;

  // These three state fields are asked during the invoicing process in E-Plan after submitting the t2s file
  // https://encamp.slack.com/archives/C02MJFRHG4W/p1731430532030149
  // label: Number of Employees Statewide
  numberOfEmployees: number;
  // label: Is your facility regulated under Chapter 368 (Gas Transmission and Distribution Facilities), Chapter 527 (sale of liquified petroleum gas) or Section 376.303 (Aboveground and underground storage tanks) and do not have any EHSs over the TPQ?
  isGasFacilityBelowTpq: boolean | undefined;
  // label: Is your facility an Agricultural Facility using the agricultural use exemption under EPCRA, Section 311(e)?
  isAgriculturalFacilityExemption: boolean | undefined;
};

export type GA_FACILITY_STATE_FIELDS = {
  hasTrainedFirePersonnel: boolean;
  comments: string;
};

export type HI_FACILITY_STATE_FIELDS = {
  // label: Facility ID Number
  facilityId: number;
  // label: Island
  island: HI_Island;
  // label: Is this a military facility?
  isMilitaryFacility: boolean | undefined;
};

export type ID_FACILITY_STATE_FIELDS = {
  // label: Facility Email
  email: string;
};

export type IL_FACILITY_STATE_FIELDS = {
  // label: Other fire departments responding to this site
  otherFireDistricts: string | undefined;
  // label: Nature of Business
  natureOfBusiness: string | undefined;
  // label: Number of Full Time Employees
  fullTimeEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: EIN
  ownerOperatorEin: string | undefined;
};

export type IN_FACILITY_STATE_FIELDS = {
  // label: Type(s) of common transportation routes for EHS chemicals to and from facility
  commonTransportationRoutes: string | undefined;
  // label: Process for Shelter in Place and/or Evacuation of Onsite and Off_Site Populations
  evacuationProcess: string | undefined;
  // label: Process for Alerting/Warning the Public and Special Facilities
  alertProcess: string | undefined;
  // label: How many individuals are trained in emergency response and what are their respective training level/capabilities?
  trainingLevel: string | undefined;
  // label: List the equipment or resources available for hazardous materials response at the facility
  hazardousMaterialEquipmentList: string | undefined;
  // label: Facility Employee Training Schedule for Hazmat
  hazmatTrainingSchedule: string | undefined;
  // label: Provide the name, title, and contact information for the individual(s) who has the authority to commit the facilities resources in times of emergency
  emergencyAllocationContact: string | undefined;
  // label: Facility Employee Exercise Schedule for Hazmat
  hazmatExerciseSchedule: string | undefined;
  // label: Other fire departments responding to this site
  otherFireDistricts: string | undefined;
  // label: Nature of Business
  natureOfBusiness: string | undefined;
  // label: Number of Full Time Employees
  fullTimeEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Is this facility a federal, state or local government facility?
  isGovernmentFacility: boolean | undefined;
  // label: Is this facility a farm?
  isFarm: boolean | undefined;
};

export type KS_FACILITY_STATE_FIELDS = {
  // label: New Facility (check if new facility)
  isNewFacility: boolean | undefined;
  // label: Nearest Street Corner
  nearestStreetCorner: string | undefined;
  // label: Section
  section: string | undefined;
  // label: Township
  township: string | undefined;
  // label: Range
  range: string | undefined;
  // label: SIC Code
  sicCode: number | undefined;
};

export type KY_FACILITY_STATE_FIELDS = {
  // label: Nature of Business
  natureOfBusiness: string | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Chemical Modes of Transport
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  transportationModes: string | undefined;
  // label: Chemical Transportation Routes (Route from County line to Facility)
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  transportationRoutes: string | undefined;
  // label: Chemical Transportation Handling (From transport vehicle into facility)
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  transportationHandling: string | undefined;
  // label: Frequency of Shipping (Ex. Daily, Monthly)
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  shippingFrequency: string | undefined;
  // label: Primary EHS Supplier
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  primaryEhsSupplierName: string | undefined;
  // label: Primary EHS Supplier Phone
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  primaryEhsSupplierPhone: string | undefined;
  // label: Primary EHS Supplier Address
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  primaryEhsSupplierAddress: string | undefined;
  // label: Facility Emergency Equipment on Hand
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  emergencyEquipment: string | undefined;
  // label: Facility Employee Training Schedule for Hazmat
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  employeeHazmatTrainingSchedule: string | undefined;
  // label: Facility Employee Exercise Schedule for Hazmat
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  employeeHazmatExerciseSchedule: string | undefined;
  // label: Procedures for Containment of Released Substance
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  containmentProcedures: string | undefined;
  // label: Procedures for Clean Up of Released Substance
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  cleanupProcedures: string | undefined;
  // label: Procedures for Substance Disposal
  /**
   * when:
   * {"params":["function:isSubjectToEmergencyPlanning",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  disposalProcedures: string | undefined;
};

export type LA_FACILITY_STATE_FIELDS = {
  // label: Facility Status
  facilityStatus: LA_FacilityStatus;
  // label: Sold, Closed, or Plugged & Abandoned Date (if applicable)
  /**
   * when:
   * {"key":"facilityStatus","values":["Sold","Closed","Plugged and Abandoned"]}
   **/
  soldClosedPluggedAbandonedDate: string | undefined;
  // label: New owner's name (company or person)
  /**
   * when:
   * {"value":"Sold","key":"facilityStatus"}
   **/
  newOwnerName: string | undefined;
  // label: New owner's full address
  /**
   * when:
   * {"value":"Sold","key":"facilityStatus"}
   **/
  newOwnerAddress: string | undefined;
  // label: New owner's phone and/or fax number:
  /**
   * when:
   * {"value":"Sold","key":"facilityStatus"}
   **/
  newOwnerPhone: string | undefined;
  // label: New owner's email address
  /**
   * when:
   * {"value":"Sold","key":"facilityStatus"}
   **/
  newOwnerEmail: string | undefined;
  // label: Facility Type
  facilityType: LA_FacilityType;
  // label: Adjoining parish within one mile of facility's location (if applicable) (primary)
  adjoiningParishPrimary: string | undefined;
  // label: Adjoining parish within one mile of facility's location (if applicable) (secondary)
  adjoiningParishSecondary: string | undefined;
  // label: Adjoining parish within one mile of facility's location (if applicable) (tertiary)
  adjoiningParishTertiary: string | undefined;
  // label: SIC Code
  sicCode: number;
  // label: Annual Gross Receipts under two million?
  isGrossReceiptsUnderTwoMillion: boolean | undefined;
  // label: Liquefied Petroleum Gas is the only chemical present in reportable quanities, qualifying this facility for the LPG Distributor exemption?
  isLpgDistributor: boolean | undefined;
};

export type MA_FACILITY_STATE_FIELDS = {
  // label: Nature of Business
  natureOfBusiness: string | undefined;
  // label: Number of Full Time Employees
  fullTimeEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
};

export type MD_FACILITY_STATE_FIELDS = {
  // label: Other fire departments responding to this site
  otherFireDistricts: string | undefined;
  // label: Nature of Business
  natureOfBusiness: string | undefined;
  // label: Number of Full Time Employees
  fullTimeEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Is this a federal facility owned by a government agency?
  isFederalFacility: boolean;
  // label: Is this facility a state or local government entity owned by a government agency?
  isStateOrLocalFacility: boolean;
  // label: Is this facility a retail gas station which stores gasoline and/or diesel fuel entirely underground, in compliance with Underground Storage Tank (UST) requirements and you store less than 75,000 gallons of gasoline (all grades) and less than 100,000 gallons of diesel fuel?
  isRetailGasStation: boolean;
  // label: Is this facility a farm and are you a farmer whose principle residence is located on their farm?
  isFarm: boolean;
  // label: Are you a charitable organization as defined under Business Regulation Article,§ 6_101(d), Annotated Code of Maryland?
  isCharitableOrganization: boolean;
  // label: Is this facility a marina?
  isMarina: boolean;
};

export type ME_FACILITY_STATE_FIELDS = {
  // label: Specify the most recent date (within the last year) when the facility emergency response plan was reviewed
  /**
   * when:
   * {"value":true,"key":"function:isEhsFacility"}
   **/
  /**
   * when:
   * {"params":["function:isEhsFacility",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  erpReviewedAt: string | undefined;
  // label: Specify the most recent date (within the last year) when the facility emergency response plan was exercised
  /**
   * when:
   * {"value":true,"key":"function:isEhsFacility"}
   **/
  /**
   * when:
   * {"params":["function:isEhsFacility",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  erpExercisedAt: string | undefined;
  // label: Specify the type of exercise that was performed
  /**
   * when:
   * {"value":true,"key":"function:isEhsFacility"}
   **/
  /**
   * when:
   * {"params":["function:isEhsFacility",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  erpExerciseType: ME_ErpExerciseType | undefined;
  // label: List the departments and agencies that were contacted to ensure proper coordination
  /**
   * when:
   * {"value":true,"key":"function:isEhsFacility"}
   **/
  /**
   * when:
   * {"params":["function:isEhsFacility",{"is":true,"then":[{"type":"required"}]}],"type":"when"}
   **/
  erpExerciseContacted: string | undefined;
};

export type MI_FACILITY_STATE_FIELDS = {
  // label: Other fire departments responding to this site
  otherFireDistricts: string | undefined;
  // label: Nature of Business
  natureOfBusiness: string;
  // label: Number of Full Time Employees
  fullTimeEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
};

export type MN_FACILITY_STATE_FIELDS = {
  // label: Other fire departments responding to this site
  otherFireDistricts: string | undefined;
  // label: Nature of Business
  natureOfBusiness: string;
  // label: Number of Full Time Employees
  fullTimeEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Is this a federal facility, which means owned and/or operated by the federal government?
  isFederalFacility: boolean;
  // label: Is this facility a retail gas station? (The exemption below does not apply to airports or boat marinas).
  isRetailGasStation: boolean;
};

export type MO_FACILITY_STATE_FIELDS = {
  // label: Are Any Explosives Listed?
  hasExplosives: boolean | undefined;
  // label: Facility Type
  facilityType: MO_FacilityType;
};

export type NC_FACILITY_STATE_FIELDS = {
  facilityType: NC_FacilityType;
  companyName: string | undefined;
  isRegulatedUnderCFATS: boolean;
  isRegulatedUnderOSHA: boolean;
  isRegulatedUnderRCRA: NC_RcraGenerator;
  rcraSiteIdNumber: string | undefined;
  nearestHazardousMaterialsResponseTeam: string | undefined;
  responseTeam24hrPhone: string | undefined;
  responseTeamCity: string | undefined;
  facilityFloorPlanAttached: boolean;
  rcraContingencyPlanAttached: boolean;
};

export type ND_FACILITY_STATE_FIELDS = {
  // label: Nature of Business
  natureOfBusiness: string;
  // label: SIC Code
  sicCode: number;
};

export type NE_FACILITY_STATE_FIELDS = {
  // label: Notes, in particular if you specified (or will specify) a chemical hazard as 'Hazard not otherwise classified' please enter the information below.
  notes: string | undefined;
};

export type NH_FACILITY_STATE_FIELDS = {
  monitoredEmailAddress: string;
  safetyDataSheetsAttached: boolean;
};

export type NJ_FACILITY_STATE_FIELDS = {
  // label: Facility ID Number
  facilityId: string | undefined;
  // label: EIN
  ein: string;
  // label: Number of Facilities in NJ
  numberOfFacilities: number | undefined;
  // label: Does this facility produce, store, or use NJ CRTK Environmental Hazardous Substances in any quantity?
  hasEhs: boolean | undefined;
  // label: Does this facility produce, store, or use NJ CRTK Environmental Hazardous Substances above thresholds?
  hasEhsAboveThresholds: boolean | undefined;
  // label: Facility Status
  facilityStatus: NJ_FacilityStatus | undefined;
  // label: Briefly describe the current operations or business conducted at this facility
  descriptionOfOperations: string;
  // label: Union Name/Local #
  unionName: string | undefined;
  // label: Union Representative Name
  representativeName: string | undefined;
  // label: Union Representative Email
  representativeEmail: string | undefined;
  // label: Union Representative Phone
  representativePhone: string | undefined;
  // label: Company Contact Name
  companyContactName: string;
  // label: Company Contact Email Address
  companyContactEmailAddress: string;
  // label: Local Police Department for Report Distribution
  // this is only used in mailings, not in portal automation
  policeDepartment: number | undefined;
  // label: Number of employees at this facility
  numberOfEmployees: number | undefined;
};

export type NM_FACILITY_STATE_FIELDS = {
  // label: Facility is on Tribal Land
  isOnTribalLand: boolean | undefined;
  // label: If yes, Name of Nation
  /**
   * when:
   * {"value":"Yes","key":"isOnTribalLand"}
   **/
  tribalNation: string | undefined;
  // label: Number of facilities in New Mexico under same Owner/Operator
  numberOfFacilities: number | undefined;
};

export type NV_FACILITY_STATE_FIELDS = {
  // label: Other fire departments responding to this site
  otherFireDistricts: string | undefined;
  // label: Nature of Business
  natureOfBusiness: string;
  // label: SIC Code
  sicCode: number | undefined;
  // label: NV Secretary of State Business ID Number
  businessIdNumber: string;
  // label: Is this a federal government facility?
  isFederalFacility: boolean | undefined;
  // label: Is this a state government facility?
  isStateFacility: boolean | undefined;
  // label: Is this a mining facility and does not perform operations after the milling process as defined in the Interagency Agreement between MSHA and OSHA?
  isMiningFacility: boolean | undefined;
};

export type NY_FACILITY_STATE_FIELDS = {
  // label: If facility is located in New York City, which borough?
  borough: NY_Borough | undefined;
};

export enum OH_SQUARE_FOOTAGE {
  "0-100,000 sq. ft.",
  "100,001-400,000 sq. ft.",
  "400,001+ sq. ft.",
}

export type OH_FACILITY_STATE_FIELDS = {
  // label: Confidential location forms included?
  hasConfidentialLocationForms: boolean | undefined;
  // label: Facility Name has changed since last Tier II Filing?
  hasFacilityNameChanged: boolean | undefined;
  // label: Previous facility name (if known)
  /**
   * when:
   * {"value":true,"key":"hasFacilityNameChanged"}
   **/
  previousFacilityName: string | undefined;
  // label: Owner/Operator has changed since last Tier II Filing?
  hasOwnerOperatorChanged: boolean | undefined;
  // label: Previous parent company/owner name (if known)
  /**
   * when:
   * {"value":true,"key":"hasOwnerOperatorChanged"}
   **/
  previousOwnerOperator: string | undefined;
  // label: Type of Filing
  filingType: OH_FilingType | undefined;
};

export type OH_CLEVELAND_FACILITY_STATE_FIELDS = {
  // Cleveland FD F22321 specific fields
  numberOfEmployees: number;
  incorporationInformationName: string;
  incorporationInformationState: StateAbbreviation;
  incorporationInformationFederalIdNumber: number;
  squareFootage: OH_SQUARE_FOOTAGE;
};

export type OK_FACILITY_STATE_FIELDS = {
  // label: Are you regulated under the Department of Homeland Security CFATS program?
  isRegulatedByCfats: boolean | undefined;
  // label: Are you regulated under the RCRA as a generator of hazardous waste?
  rcraGenerator: OK_RcraGenerator | undefined;
  // label: Are you regulated under the OSHA Process Safety Management program?
  isRegulatedByOshaPsm: boolean | undefined;
  // label: Have you reported any spill or released Hazardous Material to the National Reponse Center (NRC) in the past 5 years?
  hasReportedSpill: boolean | undefined;
  // label: Report Date(s)
  /**
   * when:
   * {"value":"Yes","key":"hasReportedSpill"}
   **/
  spillReportDates: string | undefined;
  // label: National Response Center (NRC) Case Number(s)
  /**
   * when:
   * {"value":"Yes","key":"hasReportedSpill"}
   **/
  nrcCaseNumbers: string | undefined;
};

export type OR_FACILITY_STATE_FIELDS = {
  // label: Tax ID Number
  taxIdNumber: string;
  // label: Is there an automatic fire suppression system present at this facility?
  hasAutomaticFireSuppressionSystem: boolean;
  // label: Does a written emergency plan exist for this facility?
  hasEmergencyPlan: boolean;
  // label: Plan Location
  /**
   * when:
   * {"type":"when","params":["hasEmergencyPlan",{"is":"Yes","then":[{"type":"required"}]}]}
   **/
  planLocation: string | undefined;
  // label: Is storage placarded according to NFPA 704?
  isStorePlacarded: boolean | undefined;
  // label: Are other types of placards used?
  hasOtherPlacards: boolean;
  // label: Does your facility have multiple addresses as part of the same Facility Site Id?
  hasMultipleAddresses: boolean;
  // label: Are you a state/local government facility?
  isStateOrLocalFacility: boolean;
  // label: Are you an agricultural facility with grow_only operations?
  isAgriculturalFacility: boolean;
  // label: This facility is open for business but no longer storing chemicals in reportable quantities as of this date:
  facilityNoChemicalsExemptionStorageDate: string | undefined;
};

export type PA_FACILITY_STATE_FIELDS = {
  // label: Nature of Business
  natureOfBusiness: string | undefined;
  // label: SIC Code
  sicCode: number;
  // label: Is this facility a federal facility?
  isFederalFacility: boolean | undefined;
  // label: Is this facility a state or local government entity?
  isStateOrLocalFacility: boolean | undefined;
  // label: Is this facility a publicly owned and operated water, waste water or sewer treatment plant?
  isWater: boolean | undefined;
  // label: Is the facility a farm and are you a farmer whose principle residence is located on this farm?
  isFarm: boolean | undefined;
  // label: Is the facility a retail gas station?
  isRetailGasStation: boolean | undefined;
  // label: Filing a volunteer report with all hazardous substances BELOW reporting thresholds?
  isVolunteerReport: boolean | undefined;
  // label: Fax
  faxNumber: string | undefined;
};

export type RI_FACILITY_STATE_FIELDS = {
  isLocatedInCommunityWaterSystemArea: boolean | undefined;
  waterDrinkingSystemOwner: string | undefined;
};

export type SD_FACILITY_STATE_FIELDS = {
  // label: Facility ID
  facilityId: string | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Enter a date if the facility is closed and there is no on_site storage
  closedAt: string | undefined;
};

export type TX_FACILITY_STATE_FIELDS = {
  // label: During this reporting year, did you purchase this facility, start to carry reportable amounts of chemicals for the first time, or is this the first time filing a Tier II Report for this facility? If yes, you must enter the effective date that the facility was first required to report under your management.
  isNewFacilityThisYear: boolean | undefined;
  // label: Effective date
  /**
   * when:
   * {"value":"Yes","key":"isNewFacilityThisYear"}
   **/
  startReportingDate: string | undefined;
  // label: During this reporting year, was this facility sold to another company, or did it close operations or discontinue storing reportable quantities of hazardous substances? If yes, you must enter the expiration date. Answering 'No' means that as of the end of the reporting year you were still operating this facility and storing reportable quantities of hazardous substances.
  isNoLongerReportable: boolean | undefined;
  // label: Expiration date
  /**
   * when:
   * {"value":"Yes","key":"isNoLongerReportable"}
   **/
  closedAt: string | undefined;
};

export type UT_FACILITY_STATE_FIELDS = {
  // label: State ID
  stateId: string;
};

export type WA_FACILITY_STATE_FIELDS = {
  // label: UBI
  ubi: number | undefined;
};

export type WI_FACILITY_STATE_FIELDS = {
  // label: Is this a federal or federally recognized tribal facility?
  isFederalOrTribalFacility: boolean | undefined;
  // label: Nature of Business
  natureOfBusiness: string | undefined;
  // label: Number of Full_Time Equivalent Employees (FTE) within the State of WI
  fullTimeEmployees: number;
  // label: SIC Code
  sicCode: number | undefined;
  // label: EIN/Tax ID
  einTaxId: string;
  // label: This facility is not covered by the OSHA Hazard Communication Act and is not a private or public agency as defined by s. 323.60 (1)(h) and (i).
  hasOSHACommunicationExemption: boolean | undefined;
  // label: Per OSHA Hazard Communication Act regulations, hazardous chemicals present at this facility are not required to have Safety Data Sheets prepared for them or available to the facility because of reporting exemptions at CFR Chapter 29, Section 1910.1200(b).
  hasOSHAHazComActExemption: boolean | undefined;
  // label: Hazardous chemicals at this facility fall under one or more of the section 311(e) reporting exemptions.
  has311Exemption: boolean | undefined;
  // label: This is a Retail Gas Station and the higher reporting thresholds for gasoline and diesel fuel apply.
  hasRetailGasStationExemption: boolean | undefined;
  // label: This facility NO LONGER has Hazardous Chemicals or Extremely Hazardous Substances at or above a reportable amount under section 311(SDS/Chemical List) or section 312 (annual Tier II Reporting).
  hasNoChemicalsExemption: boolean | undefined;
};

export type WV_FACILITY_STATE_FIELDS = {
  // label: Nature of Business
  natureOfBusiness: string;
  // label: Number of Full Time Employees
  fullTimeEmployees: number | undefined;
  // label: SIC Code
  sicCode: number | undefined;
  // label: Are you required to register an Above_Ground Storage Tank (AST)?
  isAst: boolean | undefined;
  // label: Above_Ground Storage Tank Permit ID
  /**
   * when:
   * {"value":"Yes","key":"isAst"}
   **/
  /**
   * when:
   * {"params":["isAst",{"is":"Yes","then":[{"type":"required"}]}],"type":"when"}
   **/
  astPermitId: string | undefined;
  // label: Are you subject to WV Code Section 15_5C (the 15 min Oil and Gas Reporting Rule)?
  isSubjectToSection15_5c: boolean;
  // label: Are you a CFATS Facility?
  isRegulatedByCfats: boolean;
  // label: Is this facility address a non_standard address?
  isNonStandardAddress: boolean | undefined;
  // label: Is this facility's siteplan sent as a hardcopy?
  isSitePlanProvidedAsHardcopy: boolean | undefined;
};

export enum AR_RcraGenerator {
  "No",
  "Yes; Large Quantity Generator (LQG)",
  "Yes; Small Quantity Generator (SQG)",
  "Yes; Very Small Quantity Generator (VSQG)",
}

export enum AZ_TribalLand {
  "Not On Tribal Land",
  "Ak-Chin Indian Community",
  "Cocopah Tribe",
  "Colorado River Indian Tribes",
  "Fort McDowell Yavapai Nation",
  "Fort Mojave Indian Tribe",
  "Fort Yuma-Quechan Tribe",
  "Gila River Indian Community",
  "Havasupai Tribe",
  "Hualapai Tribe",
  "Kaibab-Paiute Tribe",
  "Navajo Nation",
  "Pascua Yaqui Tribe",
  "Salt River Pima- Maricopa Indian Community",
  "San Carlos Apache Tribe",
  "San Juan Southern Paiute",
  "The Hopi Tribe",
  "Tohono O'Odham Nation",
  "Tonto Apache Tribe",
  "White Mountain Apache Tribe",
  "Yavapai-Apache Nation",
  "Yavapai-Prescott Indian Tribe",
  "Zuni Tribe",
}

export enum CA_DocumentOption {
  "Upload",
  "Provided Elsewhere",
  "Exempt",
}

/** @deprecated("Use CaSections instead. This will be removed with the HMBP migration.") */
export enum CA_EmployeeTrainingPlanFiledElsewhere {
  "Within Facility Info",
  "Within Hazardous Materials Inventory",
  "Within Emergency Response and Training Plans",
}

export enum CA_UstPermitTypeOfAction {
  "Confirmed/Updated Information",
  "New Permit",
  "Renewal Permit",
  "Temporary Facility Closure",
  "Permanent Facility Closure",
  "Transfer Permit",
}

export enum CA_UstFacilityType {
  "Motor Vehicle Fueling",
  "Fuel Distribution",
  "Farm",
  "Processor",
  "Other",
}

export enum CA_UstPermitHolderInfo {
  "Facility Owner",
  "Facility Operator",
  "Tank Owner",
  "Tank Operator",
}

export enum CA_FinancialResponsibilityMechanisms {
  "Self-Insured",
  "Guarantee",
  "Insurance",
  "Surety Bond",
  "Letter of Credit",
  "Exemption",
  "State Fund and CFO Letter",
  "State Fund and CD",
  "Local Government Mechanism",
  "Other Mechanism",
}

export enum CO_FacilityType {
  "Federal, State, Local Government or University",
  "Retail Motor Fuel Outlet",
  "Federally Recognized Tribe",
  "Oil and Gas",
  "Agriculture",
  "Other Private Industry/Business",
}

export enum CO_OilAndGasType {
  "Well Head",
  "Storage Tanks",
  "Compressor Station",
  "Other",
}

export enum CO_AgricultureType {
  "Farm or Ranch",
  "Fertilizer or Pesticide",
  "Other",
}

export enum HI_Island {
  "Hawaii",
  "Kauai",
  "Lanai",
  "Maui",
  "Molokai",
  "Oahu",
}

export enum LA_FacilityStatus {
  "Active",
  "Sold",
  "Closed",
  "Plugged and Abandoned",
}

export enum LA_FacilityType {
  "Fixed",
  "Oilfield",
  "Pipeline",
}

export enum ME_ErpExerciseType {
  "Workshop",
  "Tabletop Exercise",
  "Functional",
  "Full Scale",
  "Other",
}

export enum MO_FacilityType {
  "Family Farm",
  "Local/Federal Government",
  "Pipeline",
  "Retail Petroleum",
  "Bulk Petroleum",
  "Other",
}

export enum NC_FacilityType {
  "Industrial/Other Private Business",
  "Government (Federal, State, or Local)",
  "Educational",
  "Tribal (Federally Recognized)",
  "Agricultural (Industrial/Commercial)",
  "Agricultural (Fertilizer/Pesticide Storage & Sales)",
  "Agricultural (Family Farm)",
  "Motor Vehicle Dealer",
  "Motor Vehicle Fuel Retailer",
}

export enum NC_RcraGenerator {
  "No",
  "Yes; Large Quantity Generator (LQG)",
  "Yes; Small Quantity Generator (SQG)",
  "Yes; Very Small Quantity Generator (VSQG)",
  "Unknown",
}

export enum NC_YesNoUnknown {
  "Yes",
  "No",
  "Unknown",
}

export enum NJ_FacilityStatus {
  "Active",
  "Out of Business",
}

export enum NY_Borough {
  "The Bronx",
  "Brooklyn",
  "Manhattan",
  "Queens",
  "Staten Island",
}

export enum OH_FilingType {
  "Initial Filing",
  "Updated Filing",
  "Annual Filing",
}

export enum OK_RcraGenerator {
  "No",
  "Yes; Large Quantity Generator (LQG)",
  "Yes; Small Quantity Generator (SQG)",
  "Yes; Very Small Quantity Generator (VSQG)",
}
