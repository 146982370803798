import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import { GET_REPORT } from "routes/Customer/Chemicals/Report/useReport";

const UPDATE_TIER_II_REPORT_REVIEWERS = gql(/* GraphQL */ `
  mutation UpdateTierIIReportReviewers(
    $tierIIReportIds: [ID!]!
    $personIds: [ID!]!
  ) {
    setTierIIReportReviewers(
      tierIIReportIds: $tierIIReportIds
      reviewerIds: $personIds
    ) {
      id
      organizationStatus
      reviewers {
        id
        assignmentType
        isComplete
        person {
          id
          first
          last
          phones {
            number
            type
          }
        }
      }
    }
  }
`);

const UPDATE_TIER_II_REPORT_ASSIGNEES = gql(/* GraphQL */ `
  mutation UpdateTierIIReportAssignees(
    $tierIIReportIds: [ID!]!
    $personIds: [ID!]!
  ) {
    setTierIIReportAssignees(
      tierIIReportIds: $tierIIReportIds
      assigneeIds: $personIds
    ) {
      id
      assignees {
        id
        assignmentType
        person {
          id
          first
          last
        }
      }
    }
  }
`);

const REMOVE_TIER_II_REPORT_REVIEWERS = gql(`
  mutation RemoveTierIIReportReviewers($tierIIReportIds: [ID!]!, $personIds: [ID!]!) {
    removeTierIIReportReviewers(tierIIReportIds: $tierIIReportIds, reviewerIds: $personIds) {
      id
      reviewers {
        id
        assignmentType
        person {
          id
          first
          last
          phones {
            number
            type
          }
        }
      }
    }
  }
`);

const REMOVE_TIER_II_REPORT_ASSIGNEES = gql(`
  mutation RemoveTierIIReportAssignees($tierIIReportIds: [ID!]!, $personIds: [ID!]!) {
    removeTierIIReportAssignees(tierIIReportIds: $tierIIReportIds, assigneeIds: $personIds) {
      id
      assignees {
        id
        assignmentType
        person {
          id
          first
          last
        }
      }
    }
  }
`);

const useAssigneeUpdate = () => {
  return useMutation(UPDATE_TIER_II_REPORT_ASSIGNEES);
};
const useAssigneeRemove = () => {
  return useMutation(REMOVE_TIER_II_REPORT_ASSIGNEES);
};
const useReviewerUpdate = () => {
  return useMutation(UPDATE_TIER_II_REPORT_REVIEWERS, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }
      const updatedReports = data.setTierIIReportReviewers;

      updatedReports.forEach((updatedReport) => {
        const report = cache.readQuery({
          query: GET_REPORT,
          variables: { reportId: updatedReport.id },
        });

        if (!report) {
          return;
        }

        cache.writeQuery({
          query: GET_REPORT,
          variables: { reportId: updatedReport.id },
          data: {
            __typename: "Query",
            tierIIReport: {
              ...report.tierIIReport,
              reviewers: updatedReport.reviewers,
            },
          },
        });
      });
    },
  });
};
const useReviewerRemove = () => {
  return useMutation(REMOVE_TIER_II_REPORT_REVIEWERS, {
    update: (cache, { data }) => {
      const updatedReports = data?.removeTierIIReportReviewers;
      if (!updatedReports) {
        return;
      }
      updatedReports.forEach((updatedReport) => {
        cache.modify({
          id: cache.identify({
            __typename: "TierIIReport",
            id: updatedReport.id,
          }),
          fields: {
            reviewers: (existingRefs = [], { readField }) => {
              return existingRefs?.filter((ref: any) =>
                updatedReport.reviewers.some(
                  (reviewer: any) => reviewer.id === readField("id", ref)
                )
              );
            },
          },
        });
      });
    },
  });
};

export {
  useAssigneeUpdate,
  useAssigneeRemove,
  useReviewerUpdate,
  useReviewerRemove,
};
