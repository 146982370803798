import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { contactRoleToLabel } from "../../../../util/facilityContacts";
import { useMemo } from "react";
import CopyableTypography from "../../../../components/Typography/CopyableTypography";
import {
  ContactReportRole,
  Person,
} from "../../../../generated-graphql/graphql";
import { useReportDetails } from "./useReportDetails";
import { getFullName } from "encamp-shared/src/utils/name";
import { TierIiReportOrgStatus } from "generated-graphql/graphql";

export function ContactDetails() {
  const { data } = useReportDetails();

  const showTitle = useMemo(() => {
    return (
      !!data?.tierIIReport.verifier ||
      !!data?.tierIIReport.facility.contacts?.length
    );
  }, [
    data?.tierIIReport.facility.contacts?.length,
    data?.tierIIReport.verifier,
  ]);

  return (
    <Stack spacing={2} component={Paper} paddingLeft={1}>
      {showTitle && <Typography variant="h6">Contacts</Typography>}
      {
        // In some cases we will not have a Person record associated to the report
        // for the verifier. In this case, we should display what we have on the report
        // as a fallback. An example of this is when Encamp verifies a report for Asmark.
        data?.tierIIReport.organizationStatus ===
          TierIiReportOrgStatus.Verified && (
          <ContactInfo
            contactType="Verifier"
            person={data?.tierIIReport.verifier}
            verifierFullName={data?.tierIIReport.verifierFullName}
            verifierTitle={data?.tierIIReport.verifierTitle}
          />
        )
      }
      {data?.tierIIReport.facility.contacts?.map((contact) => (
        <ContactInfo
          key={contact.id}
          contactType={contactTypesToDisplayNames(contact.reportingRoles ?? [])}
          person={contact.person}
        />
      ))}
    </Stack>
  );
}

type ContactInfoProps = {
  contactType: string;
  person?: Person | null;
  verifierFullName?: string | null;
  verifierTitle?: string | null;
};

function addressIsNotTrivial(
  address?: Pick<
    Person,
    "streetAddress1" | "streetAddress2" | "city" | "state" | "zip"
  > | null
) {
  return (
    !!address?.streetAddress1 ||
    !!address?.city ||
    !!address?.state ||
    !!address?.zip
  );
}

function getVerifierName(props: ContactInfoProps) {
  if (props.contactType === "Verifier") {
    if (props.person) {
      return getFullName(props.person);
    }

    if (props.verifierFullName) {
      return props.verifierFullName;
    }

    return "";
  }

  return getFullName(props.person);
}

function getVerifierTitle(props: ContactInfoProps) {
  if (props.contactType === "Verifier") {
    if (props.person) {
      return props.person.title;
    }

    if (props.verifierTitle) {
      return props.verifierTitle;
    }

    return "";
  }

  return props.person?.title;
}

function ContactInfo(props: ContactInfoProps) {
  const formattedAddress = addressIsNotTrivial(props.person)
    ? `
    ${props.person?.streetAddress1 ?? ""}${props.person?.streetAddress2 ?? ""}${
        props.person?.city ? ", " + props.person?.city : ""
      }${props.person?.state ? ", " + props.person?.state : ""}${
        props.person?.zip ? " " + props.person?.zip : ""
      }
  `
    : null;
  return (
    <Box paddingLeft={1}>
      <Typography variant="body1" color="gray" fontWeight="bold">
        {props.contactType}
      </Typography>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell variant="head">Name</TableCell>
            <TableCell>
              <CopyableTypography
                text={getVerifierName(props)}
                variant="body2"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Title</TableCell>
            <TableCell>
              <CopyableTypography
                text={getVerifierTitle(props)}
                variant="body2"
              />
            </TableCell>
          </TableRow>
          {props.person?.email && (
            <TableRow>
              <TableCell variant="head">Email</TableCell>
              <TableCell>
                <CopyableTypography
                  text={props.person?.email}
                  variant="body2"
                />
              </TableCell>
            </TableRow>
          )}
          {props.person?.phones && (
            <TableRow>
              <TableCell variant="head">Phones</TableCell>
              <TableCell>
                {props.person?.phones?.map((phone) => (
                  <Stack
                    key={phone.type + phone.number}
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                  >
                    <Typography variant="body2">{phone.type}:</Typography>
                    <CopyableTypography text={phone.number} variant="body2" />
                  </Stack>
                ))}
              </TableCell>
            </TableRow>
          )}
          {formattedAddress && (
            <TableRow>
              <TableCell variant="head">Address</TableCell>
              <TableCell>
                <CopyableTypography text={formattedAddress} variant="body2" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
}

function contactTypesToDisplayNames(contactTypes: ContactReportRole[]): string {
  return contactTypes
    .map((type) => contactRoleToLabel(type))
    .sort()
    .join(", ");
}
