import { gql } from "generated-graphql";

export const DELETE_CREDENTIAL = gql(`
  mutation DeleteCredential($id: ID!) {
    deleteCredential(id: $id) {
      id
    }
  }
`);

export const GET_CREDENTIAL = gql(`
  query GetCredential($id: ID!, $facilitiesPage: Int, $facilitiesPageSize: Int) {
    credential(id: $id, facilitiesPage: $facilitiesPage, facilitiesPageSize: $facilitiesPageSize) {
      id
      name
      username
      password
      kind
      email
      facilityCount
      tenantId
      lastVerificationSuccessAt
      lastVerificationCheckAt
      verificationStatus
      usesCombinedInvoicing
      facilities {
        id
        name
        streetAddress1
        city
        state
        zip
        portalCredential {
          url
        }
      }
      issues {
        ...issue
      }
    }
  }
`);

export const GET_FACILITIES_FOR_CREDENTIAL = gql(`
  query GetFacilitiesForCredential($credentialId: ID!) {
    facilitiesForCredential(credentialId: $credentialId) {
      id
      name
      streetAddress1
        city
        state
        zip
    }
  }
`);

export const CREDENTIALS = gql(`
  query Credentials($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    credentials(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        username
        kind
        email
        facilityCount
        states
        verificationStatus
        lastVerificationSuccessAt
        lastVerificationCheckAt
        usesCombinedInvoicing
      }
      count
    }
  }
`);

export const UPSERT_CREDENTIAL = gql(`
  mutation UpsertCredential($id: ID, $input: CredentialInput!) {
    upsertCredential(id: $id, input: $input) {
      id
      name
      username
      kind
      email
      tenantId
      facilityCount
      usesCombinedInvoicing
      facilities {
        id
      }
    }
  }
`);

export const FACILITIES = gql(`
  query FacilitiesWithCredentials($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        streetAddress1
        city
        state
        zip
        facilityCredentials {
          id
        }
      }
      count
    }
  }
`);
