import { DocumentType } from "../generated-graphql/oak-resolver-types";

export enum HmbpSection {
  FacilityInformation = "FacilityInformation",
  SitePlan = "SitePlan",
  EmergencyResponseContingencyPlan = "EmergencyResponseContingencyPlan",
  EmployeeTrainingPlan = "EmployeeTrainingPlan",
  USTFacilityOperatingPermitApplication = "USTFacilityOperatingPermitApplication",
  USTMonitoringSitePlan = "USTMonitoringSitePlan",
  USTCertificationOfFinancialResponsibility = "USTCertificationOfFinancialResponsibility",
  USTResponsePlan = "USTResponsePlan",
  USTOwnerOperatorWrittenAgreement = "USTOwnerOperatorWrittenAgreement",
  USTLetterFromChiefFinancialOfficer = "USTLetterFromChiefFinancialOfficer",
  OwnerStatementOfDesignatedUSTOperatorCompliance = "OwnerStatementOfDesignatedUSTOperatorCompliance",
  TankInformation = "TankInformation",
  TankMonitoringPlan = "TankMonitoringPlan",
  USTCertificationsOfInstallationModification = "USTCertificationsOfInstallationModification",
  RecyclableMaterialsReportDocumentation = "RecyclableMaterialsReportDocumentation",
  HazardousWasteTankClosureCertificate = "HazardousWasteTankClosureCertificate",
  APSAFacilityInformation = "APSAFacilityInformation",
  AbovegroundPetroleumStorageActDocumentation = "AbovegroundPetroleumStorageActDocumentation",
  Regulatory = "Regulatory",
}

export enum HmbpTitle {
  FacilityInformation = "Facility Information",
  EmergencyResponseAndTrainingPlans = "Emergency Response and Training Plans",
  UndergroundStorageTanks = "Underground Storage Tanks (UST)",
  RecyclableMaterialsReport = "Recyclable Materials Report",
  HazardousWasteTankClosureCertification = "Hazardous Waste Tank Closure Certification",
  AbovegroundPetroleumStorageAct = "Aboveground Petroleum Storage Act",
}

export type HmbpUndergroundStorageTankSection =
  | HmbpSection.TankInformation
  | HmbpSection.TankMonitoringPlan;

export type HmbpSectionWithoutRegulatory = Exclude<HmbpSection, "Regulatory">;

export type HmbpMetadata = {
  hmbpSection: HmbpSection;
};

export const hmbpDocumentTypes = [
  DocumentType.AboveGroundPetroleumStorageActDocumentation,
  DocumentType.EmergencyResponsePlan,
  DocumentType.HazardousWasteTankClosureCertificate,
  DocumentType.OwnerStatementOfDesignatedUstOperatorCompliance,
  DocumentType.RecyclableMaterialsReportDocumentation,
  DocumentType.SitePlan,
  DocumentType.TrainingPlan,
  DocumentType.UstCertificationOfFinancialResponsibility,
  DocumentType.UstLetterFromChiefFinancialOfficer,
  DocumentType.UstMonitoringSitePlan,
  DocumentType.UstOwnerOperatorWrittenAgreement,
  DocumentType.UstResponsePlan,
];

export const hmbpSectionToDocumentType: Partial<
  Record<HmbpSectionWithoutRegulatory, DocumentType>
> = {
  [HmbpSection.SitePlan]: DocumentType.SitePlan,
  [HmbpSection.AbovegroundPetroleumStorageActDocumentation]:
    DocumentType.AboveGroundPetroleumStorageActDocumentation,
  [HmbpSection.EmergencyResponseContingencyPlan]:
    DocumentType.EmergencyResponsePlan,
  [HmbpSection.EmployeeTrainingPlan]: DocumentType.TrainingPlan,
  [HmbpSection.HazardousWasteTankClosureCertificate]:
    DocumentType.HazardousWasteTankClosureCertificate,
  [HmbpSection.OwnerStatementOfDesignatedUSTOperatorCompliance]:
    DocumentType.OwnerStatementOfDesignatedUstOperatorCompliance,
  [HmbpSection.RecyclableMaterialsReportDocumentation]:
    DocumentType.RecyclableMaterialsReportDocumentation,
  [HmbpSection.USTCertificationOfFinancialResponsibility]:
    DocumentType.UstCertificationOfFinancialResponsibility,
  [HmbpSection.USTLetterFromChiefFinancialOfficer]:
    DocumentType.UstLetterFromChiefFinancialOfficer,
  [HmbpSection.USTOwnerOperatorWrittenAgreement]:
    DocumentType.UstOwnerOperatorWrittenAgreement,
  [HmbpSection.USTMonitoringSitePlan]: DocumentType.UstMonitoringSitePlan,
  [HmbpSection.USTResponsePlan]: DocumentType.UstResponsePlan,
};

export const hmbpSectionFromDocumentType: Partial<
  Record<DocumentType, HmbpSectionWithoutRegulatory>
> = Object.fromEntries(
  Object.entries(hmbpSectionToDocumentType).map(([section, docType]) => [
    docType,
    section as HmbpSectionWithoutRegulatory,
  ])
);

export function isHmbpDocumentType(documentType: string): boolean {
  return hmbpDocumentTypes.includes(documentType as DocumentType);
}

export const hmbpSectionDisplay: Record<HmbpSectionWithoutRegulatory, string> =
  {
    [HmbpSection.FacilityInformation]: "Additional Facility Information",
    [HmbpSection.EmergencyResponseContingencyPlan]:
      "Emergency Response/Contingency Plan",
    [HmbpSection.EmployeeTrainingPlan]: "Employee Training Plan",
    [HmbpSection.SitePlan]: "Site Plan",
    [HmbpSection.USTFacilityOperatingPermitApplication]:
      "UST Facility Operating Permit Application",
    [HmbpSection.USTMonitoringSitePlan]: "UST Monitoring Site Plan",
    [HmbpSection.USTCertificationOfFinancialResponsibility]:
      "UST Certification of Financial Responsibility",
    [HmbpSection.USTResponsePlan]: "UST Response Plan",
    [HmbpSection.USTOwnerOperatorWrittenAgreement]:
      "UST Owner/Operator Written Agreement",
    [HmbpSection.USTLetterFromChiefFinancialOfficer]:
      "UST Letter from Chief Financial Officer",
    [HmbpSection.OwnerStatementOfDesignatedUSTOperatorCompliance]:
      "Owner Statement of Designated UST Operator Compliance",
    [HmbpSection.RecyclableMaterialsReportDocumentation]:
      "Recyclable Materials Report",
    [HmbpSection.HazardousWasteTankClosureCertificate]:
      "Hazardous Waste Tank Closure Certificate",
    [HmbpSection.APSAFacilityInformation]: "APSA Facility Information",
    [HmbpSection.AbovegroundPetroleumStorageActDocumentation]:
      "Aboveground Petroleum Storage Act Documentation",
    [HmbpSection.TankInformation]: "Tank Information",
    [HmbpSection.TankMonitoringPlan]: "Tank Monitoring Plan",
    [HmbpSection.USTCertificationsOfInstallationModification]:
      "UST Certifications of Installation/Modification",
  };

export enum USTTypeOfAction {
  "Confirmed/Updated Information" = "Confirmed/Updated Information",
  "New Permit" = "New Permit",
  "Renewal Permit" = "Renewal Permit",
  "Temporary UST Closure" = "Temporary UST Closure",
  "UST Permanent Closure on Site" = "UST Permanent Closure on Site",
  "UST Removal" = "UST Removal",
  "Split Facility" = "Split Facility",
  "AST Change to UST" = "AST Change to UST",
  "UST Change to AST" = "UST Change to AST",
}

export enum USTConfiguration {
  "A Stand-alone Tank" = "A Stand-alone Tank",
  "One in a Compartmented Unit" = "One in a Compartmented Unit",
}

export enum USTUse {
  "Motor Vehicle Fueling" = "Motor Vehicle Fueling",
  "Marina Fueling" = "Marina Fueling",
  "Aviation Fueling" = "Aviation Fueling",
  "Chemical Product Storage" = "Chemical Product Storage",
  "Hazardous Waste (includes used oil)" = "Hazardous Waste (includes used oil)",
  "Emergency Generator Fuel" = "Emergency Generator Fuel",
  "Other Generator Fuel" = "Other Generator Fuel",
  "Airport Hydrant System" = "Airport Hydrant System",
  "Unknown" = "Unknown",
  "Other" = "Other",
}

export enum USTContents {
  "Regular Unleaded" = "Regular Unleaded",
  "Premium Unleaded" = "Premium Unleaded",
  "Midgrade Unleaded" = "Midgrade Unleaded",
  "Diesel" = "Diesel",
  "Jet Fuel" = "Jet Fuel",
  "Aviation Gas" = "Aviation Gas",
  "Used Oil" = "Used Oil",
  "Other Petroleum" = "Other Petroleum",
  "Other Non-petroleum" = "Other Non-petroleum",
  "E85" = "E85",
  "Biodiesel B6-B99" = "Biodiesel B6-B99",
  "Biodiesel 100" = "Biodiesel 100",
  "Kerosene" = "Kerosene",
}

export enum USTType {
  "Single Wall" = "Single Wall",
  "Double Wall" = "Double Wall",
  "Unknown" = "Unknown",
}

export enum USTConstructionPrimaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Internal Bladder" = "Internal Bladder",
  "Steel + Internal Lining" = "Steel + Internal Lining",
  "Unknown" = "Unknown",
  "Other" = "Other",
}

export enum USTConstructionSecondaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Exterior Membrane Liner" = "Exterior Membrane Liner",
  "Jacketed" = "Jacketed",
  "None" = "None",
  "Unknown" = "Unknown",
  "Other" = "Other",
}

export enum USTOverfillProtection {
  "Audible/Visual Alarms" = "Audible/Visual Alarms",
  "Ball Float" = "Ball Float",
  "Fill Tube Shut-Off Valve" = "Fill Tube Shut-Off Valve",
  "Exempt" = "Exempt",
}

export enum USTPipingConstruction {
  "Single-walled" = "Single-walled",
  "Double-walled" = "Double-walled",
  "Other" = "Other",
}

export enum USTPipingSystemType {
  "Pressure" = "Pressure",
  "Gravity" = "Gravity",
  "Conventional Suction" = "Conventional Suction",
  "23 CCR §2636(a)(3) Suction" = "23 CCR §2636(a)(3) Suction",
}

export enum USTProductWastePrimaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Flexible" = "Flexible",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Unknown" = "Unknown",
  "Other" = "Other",
}

export enum USTProductWasteSecondaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Flexible" = "Flexible",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Unknown" = "Unknown",
  "Other" = "Other",
}

export enum USTPipingTurbineContainmentSump {
  "Single-walled" = "Single-walled",
  "Double-walled" = "Double-walled",
  "None" = "None",
}

export enum USTVentPrimaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Other" = "Other",
}

export enum USTVentSecondaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Other" = "Other",
}

export enum USTVaporRecoveryPrimaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Other" = "Other",
}

export enum USTVaporRecoverySecondaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Other" = "Other",
}

export enum USTRiserPipePrimaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Other" = "Other",
}

export enum USTRiserPipeSecondaryContainment {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Rigid Plastic" = "Rigid Plastic",
  "None" = "None",
  "Other" = "Other",
}

export enum USTVentPipingTransitionSump {
  "Single-walled" = "Single-walled",
  "Double-walled" = "Double-walled",
  "None" = "None",
}

export enum USTFillComponentsInstalled {
  "Spill Bucket" = "Spill Bucket",
  "Striker Plate/Bottom Protector" = "Striker Plate/Bottom Protector",
  "Containment Sump" = "Containment Sump",
}

export enum USTUUdcConstructionType {
  "Single-walled" = "Single-walled",
  "Double-walled" = "Double-walled",
  "No Dispensers" = "No Dispensers",
}

export enum USTUUdcConstructionMaterial {
  "Steel" = "Steel",
  "Fiberglass" = "Fiberglass",
  "Rigid Plastic" = "Rigid Plastic",
  "Concrete" = "Concrete",
  "None" = "None",
  "Other" = "Other",
}

export enum USTCorrosionProtection {
  "Sacrificial Anode" = "Sacrificial Anode",
  "Impressed Current" = "Impressed Current",
  "Isolation" = "Isolation",
}

export enum USTMonitoringEquipmentServiced {
  "Annually" = "Annually",
  "Other" = "Other",
}

export enum USTSitePlotPlanSubmitted {
  "New Plan Submitted" = "New Plan Submitted",
  "Site Plan Previously Submitted" = "Site Plan Previously Submitted",
}

export enum USTMonitoringMethod {
  "Continuous Electronic Tank Monitoring" = "Continuous Electronic Tank Monitoring",
  "Automatic Tank Gauging" = "Automatic Tank Gauging",
  "Monthly Statistical Inventory Reconciliation" = "Monthly Statistical Inventory Reconciliation",
  "Weekly Manual Tank Gauge" = "Weekly Manual Tank Gauge",
  "Tank Integrity Testing" = "Tank Integrity Testing",
  "Other Monitoring" = "Other Monitoring",
}

export enum ContinuousElectronicTankMonitoringSecondaryContainmentSystem {
  "Dry" = "Dry",
  "Liquid-Filled" = "Liquid-Filled",
  "Pressurized" = "Pressurized",
  "Under Vacuum" = "Under Vacuum",
}

export enum USTAutomaticTankGaugingLeakTestFrequency {
  "Continuous" = "Continuous",
  "Daily/Nightly" = "Daily/Nightly",
  "Weekly" = "Weekly",
  "Monthly" = "Monthly",
  "Other" = "Other",
}

export enum USTAutomaticTankGaugingProgrammedTankTests {
  "0.1 GPH" = "0.1 GPH",
  "0.2 GPH" = "0.2 GPH",
  "Other" = "Other",
}

export enum USTWeeklyManualTankGaugeGaugingTestPeriod {
  "36 Hours" = "36 Hours",
  "60 Hours" = "60 Hours",
}

export enum USTIntegrityTestingFrequency {
  "Annually" = "Annually",
  "Biennially" = "Biennially",
  "Other" = "Other",
}

export enum USTPipeMonitoringMethod {
  "Continuous Monitoring of Piping Secondary Containment" = "Continuous Monitoring of Piping Secondary Containment",
  "Mechanical Line Leak Detector Performs 3 GPH Leak Test" = "Mechanical Line Leak Detector Performs 3 GPH Leak Test",
  "Electronic Line Leak Detector Performs 3 GPH Leak Test" = "Electronic Line Leak Detector Performs 3 GPH Leak Test",
  "Pipeline Integrity Testing" = "Pipeline Integrity Testing",
  "Visual Pipeline Monitoring" = "Visual Pipeline Monitoring",
  "Suction Piping Meets Exemption Criteria" = "Suction Piping Meets Exemption Criteria",
  "No Regulated Piping Per Health and Safety Code, Division 20, Chapter 6.7 Is Connected To The Tank System" = "No Regulated Piping Per Health and Safety Code, Division 20, Chapter 6.7 Is Connected To The Tank System",
  "Other Pipeline Monitoring" = "Other Pipeline Monitoring",
}

export enum USTPipeSecContainmentMonitoringPipingSecondaryContainment {
  "Dry" = "Dry",
  "Liquid" = "Liquid",
  "Pressurized" = "Pressurized",
  "Under Vacuum" = "Under Vacuum",
}

export enum USTElectronicLineLeakDetectorProgrammedInlineTesting {
  "0.2 GPH" = "0.2 GPH",
  "0.1 GPH" = "0.1 GPH",
}

export enum USTPipelineIntegrityTestingFrequency {
  "Annually" = "Annually",
  "Every 3 Years" = "Every 3 Years",
  "Other" = "Other",
}

export enum USTVisualPipelineMonitoringFrequency {
  "Daily" = "Daily",
  "Weekly" = "Weekly",
  "Minimum Monthly" = "Minimum Monthly",
}

export enum USTUdcMonitoring {
  "Continuous" = "Continuous",
  "Float and Chain Assembly" = "Float and Chain Assembly",
  "Electronic Stand-alone" = "Electronic Stand-alone",
  "No Dispensers" = "No Dispensers",
  "Other" = "Other",
}

export enum USTUdcConstruction {
  "Single-walled" = "Single-walled",
  "Double-walled" = "Double-walled",
}

export enum USTUdcSecondaryContainmentMonitoring {
  "Liquid" = "Liquid",
  "Pressure" = "Pressure",
  "Vacuum" = "Vacuum",
}

export enum USTPeriodicSystemTesting {
  "ELD Testing" = "ELD Testing",
  "Secondary Containment Testing" = "Secondary Containment Testing",
  "Spill Bucket Testing" = "Spill Bucket Testing",
}

export enum USTRecordkeeping {
  "Alarm Logs" = "Alarm Logs",
  "Visual Inspection Records" = "Visual Inspection Records",
  "Tank Integrity Testing Results" = "Tank Integrity Testing Results",
  "SIR Testing Results" = "SIR Testing Results",
  "Tank Gauging Results" = "Tank Gauging Results",
  "ATG Testing Results" = "ATG Testing Results",
  "Corrosion Protection Logs" = "Corrosion Protection Logs",
  "Equipment Maintenance and Calibration Records" = "Equipment Maintenance and Calibration Records",
}

export enum USTCertifiedWork {
  "Tank Installation or Replacement" = "Tank Installation or Replacement",
  "Piping Installation or Replacement" = "Piping Installation or Replacement",
  "Sump Installation or Replacement" = "Sump Installation or Replacement",
  "Under Dispenser Containment Installation or Replacement" = "Under Dispenser Containment Installation or Replacement",
  "Project Type: Other" = "Project Type: Other",
}

export enum USTCertifierRelationToTankOwner {
  "Tank Owner" = "Tank Owner",
  "Tank Operator" = "Tank Operator",
  "Contractor" = "Contractor",
  "Property Owner" = "Property Owner",
  "Other Authorized Agent of Tank Owner" = "Other Authorized Agent of Tank Owner",
}
