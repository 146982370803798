import {
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Popover,
  SelectProps,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  FieldError,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
} from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";
import React from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";

export type SelectItem = {
  value: any;
  display: string;
  disabled?: boolean;
};

export const FormMultiselectWithOther = <T extends FieldValues>({
  name,
  label,
  tooltip,
  disabled,
  control,
  selectItems,
  helperText,
  rules,
  sx,
  hideErrors,
}: {
  label: string;
  tooltip?: string;
  disabled?: boolean;
  selectProps?: SelectProps;
  selectItems: SelectItem[];
  helperText?: string;
  rules?: RegisterOptions;
  variant?: "standard" | "outlined" | "filled";
  sx?: SxProps<Theme>;
  selectSx?: SxProps<Theme>;
  hideErrors?: boolean;
} & UseControllerProps<T>) => {
  const labelWithTooltip = useMemo(
    () =>
      tooltip ? <TextWithTooltip text={label} tooltip={tooltip} /> : label,
    [label, tooltip]
  );

  return (
    <FormControl fullWidth sx={sx} required={!!rules?.required}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <MultiselectWithOtherControl
            value={field.value}
            onChange={field.onChange}
            selectItems={selectItems}
            error={error}
            helperText={helperText}
            hideErrors={hideErrors}
            labelWithTooltip={labelWithTooltip}
            disabled={disabled}
          />
        )}
      />
    </FormControl>
  );
};

type MultiselectWithOtherControlProps = {
  value: string[];
  onChange: (value: string[]) => void;
  selectItems: SelectItem[];
  error: FieldError | undefined;
  helperText: string | undefined;
  hideErrors: boolean | undefined;
  labelWithTooltip: React.ReactNode;
  disabled: boolean | undefined;
};

const MultiselectWithOtherControl = (
  props: MultiselectWithOtherControlProps
): React.ReactNode => {
  const {
    value = [],
    onChange,
    selectItems,
    error,
    helperText,
    hideErrors,
    labelWithTooltip,
    disabled,
  } = props;

  const valueArray = useMemo(() => value ?? [], [value]);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const otherInputRef = useRef<HTMLInputElement>(null);
  const [selectedElement, setSelectedElement] = useState<HTMLLIElement | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [otherValue, setOtherValue] = useState("");
  const [lastOtherValue, setLastOtherValue] = useState("");
  const [shouldFocusOther, setShouldFocusOther] = useState(false);

  const open = Boolean(anchorEl);

  // Determine if other value is part of the selection
  const hasOtherValue = valueArray.some(
    (v) => !selectItems.some((item) => item.value === v)
  );

  // Get the first selected value for scrolling
  const firstSelectedValue = useMemo(() => {
    if (hasOtherValue && valueArray.indexOf(otherValue) === 0) {
      return "other";
    }
    return valueArray.find((v) => selectItems.some((item) => item.value === v));
  }, [valueArray, selectItems, hasOtherValue, otherValue]);

  useEffect(() => {
    if (otherValue === "") {
      const foundOtherValue =
        valueArray.find((v) => !selectItems.some((i) => i.value === v)) ?? "";
      setOtherValue(foundOtherValue);
      setLastOtherValue(foundOtherValue);
    }
  }, [otherValue, valueArray, selectItems]);

  // Focus management for other input
  useEffect(() => {
    if (open && hasOtherValue) {
      setShouldFocusOther(true);
    } else if (!open) {
      setShouldFocusOther(false);
    }
  }, [open, hasOtherValue]);

  useEffect(() => {
    if (shouldFocusOther && otherInputRef.current) {
      const timeoutId = setTimeout(() => {
        otherInputRef.current?.focus();
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }, [shouldFocusOther]);

  // Scroll selected item into view when popover opens
  useEffect(() => {
    if (open && selectedElement && firstSelectedValue) {
      setTimeout(() => {
        selectedElement?.scrollIntoView({ block: "nearest" });
      }, 0);
    }
  }, [open, selectedElement, firstSelectedValue]);

  const handleItemClick = (itemValue: string) => {
    const newValue = valueArray.includes(itemValue)
      ? valueArray.filter((v) => v !== itemValue)
      : [...valueArray, itemValue];
    onChange(newValue);
  };

  const handleOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setOtherValue(newValue);

    // If there's a value and it's not already selected, add it
    if (newValue && !valueArray.includes(lastOtherValue)) {
      onChange([...valueArray, newValue]);
    }
    // If there's a value and we're changing from a previous other value
    else if (newValue && lastOtherValue) {
      onChange([...valueArray.filter((v) => v !== lastOtherValue), newValue]);
    }

    setLastOtherValue(newValue);
  };

  const handleOtherBlur = () => {
    if (!otherValue) {
      // If the other value is empty, remove the last other value from selection
      onChange(valueArray.filter((v) => v !== lastOtherValue));
      setLastOtherValue("");
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShouldFocusOther(false);
  };

  const handleOtherKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOtherBlur();
      textFieldRef.current?.focus();
      handleClose();
    } else if (e.key === "Escape") {
      handleClose();
      textFieldRef.current?.focus();
    }
  };

  const handleMainInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!open) {
        setAnchorEl(e.currentTarget);
      }
    }
  };

  const handleOtherCheckboxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!valueArray.includes(otherValue) && otherValue) {
      // If unchecked and we have a value, add it
      onChange([...valueArray, otherValue]);
    } else {
      // If checked, remove it
      onChange(
        valueArray.filter((v) => v !== otherValue && v !== lastOtherValue)
      );
    }
  };

  const getDisplayValue = () => {
    return valueArray
      .map((v) => {
        const item = selectItems.find((i) => i.value === v);
        return item ? item.display : v;
      })
      .join(", ");
  };

  return (
    <>
      <TextField
        fullWidth
        ref={textFieldRef}
        error={!!error}
        disabled={disabled}
        label={labelWithTooltip}
        onClick={handleClick}
        onKeyDown={handleMainInputKeyDown}
        value={getDisplayValue()}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                cursor: "pointer",
                "& input": { cursor: "pointer" },
              }}
            >
              {open ? <ArrowDropUp /> : <ArrowDropDown />}
            </InputAdornment>
          ),
        }}
        sx={{
          cursor: "pointer",
          "& input": { cursor: "pointer" },
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              paddingTop: 1,
              paddingBottom: 1,
              width: textFieldRef.current
                ? textFieldRef.current.offsetWidth
                : "auto",
              maxHeight: "300px",
            },
          },
        }}
      >
        {selectItems?.map((item: SelectItem) => {
          const isSelected = valueArray.includes(item.value);
          const isFirstSelected = item.value === firstSelectedValue;
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={item.disabled}
              onClick={() => handleItemClick(item.value)}
              selected={isSelected}
              ref={isFirstSelected ? setSelectedElement : null}
            >
              <Checkbox checked={isSelected} />
              <ListItemText primary={item.display} />
            </MenuItem>
          );
        })}
        <MenuItem
          key="other"
          selected={hasOtherValue}
          ref={firstSelectedValue === "other" ? setSelectedElement : null}
          onClick={(e) => {
            e.stopPropagation();
            setShouldFocusOther(true);
            otherInputRef.current?.focus();
          }}
        >
          <Checkbox
            checked={valueArray.includes(otherValue)}
            onClick={handleOtherCheckboxClick}
          />
          <TextField
            value={otherValue}
            onChange={handleOther}
            onBlur={handleOtherBlur}
            onKeyDown={handleOtherKeyDown}
            inputRef={otherInputRef}
            fullWidth
            size="small"
            placeholder="Enter custom value"
            onClick={(e) => e.stopPropagation()}
          />
        </MenuItem>
      </Popover>
      <ErrorDisplay
        error={error}
        helperText={helperText}
        hideErrors={hideErrors}
      />
    </>
  );
};
