import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import RemoveIcon from "@mui/icons-material/Remove";
import { Dialog } from "./Dialog";
import CheckCircle from "@mui/icons-material/CheckCircle";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import { ChangeEvent, useMemo, useState } from "react";
import { SubmitResetReportsButton } from "routes/Staff/Fulfillment/ReportDetails/ResetReportsButton";
import {
  TierIiReportEncampStatus,
  TierIiReportOrgStatus,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { stepToDisplayName } from "routes/Customer/Chemicals/Report/useReport";
import { encampStatusToLabel, orgStatusToLabel } from "util/constants";
import { useQuery } from "@apollo/client";
import { DataGrid } from "./DataGrid";
import { gql } from "generated-graphql";
import { ResetReportExplainer } from "./ResetReportExplainer";

type Props = {
  onClose: () => void;
  selectedReportIds: string[];
};

const orgStatuses: TierIiReportOrgStatus[] = [
  TierIiReportOrgStatus.NotStarted,
  TierIiReportOrgStatus.NotReporting,
  TierIiReportOrgStatus.Assigned,
  TierIiReportOrgStatus.InProgress,
  TierIiReportOrgStatus.InReview,
  TierIiReportOrgStatus.Verified,
];

const encampStatuses: TierIiReportEncampStatus[] = [
  TierIiReportEncampStatus.AwaitingVerification,
  TierIiReportEncampStatus.Processing,
  TierIiReportEncampStatus.FilingComplete,
  TierIiReportEncampStatus.NotReporting,
];

const REPORTS_SAFE_TO_RESET = gql(`
  query ReportsSafeToReset($reportIds: [ID!]!) {
    bulkReportsSafeToReset(reportIds: $reportIds) {
      id
      isSafeToReset
      unsafeResetReasons
      activities {
        isSafeToReset
        activity {
          id
          type
        }
      }
      tenant {
        name
      }
      facility {
        name
        state
      }
    }
  }
`);

export const ResetReportsFormDialog = (props: Props) => {
  const [radioValue, setRadioValue] = useState<UnsafeReportsRadioOptions>(null);

  const [orgStatus, setOrgStatus] = useState<TierIiReportOrgStatus>();
  const [encampStatus, setEncampStatus] = useState<TierIiReportEncampStatus>();
  const [shouldForceCancelWorkflow, setShouldForceCancelWorkflow] =
    useState<boolean>(true);
  const [shouldForceReSnapshot, setShouldForceReSnapshot] =
    useState<boolean>(false);

  const [reportStepsUntouched, setReportStepsUntouched] = useState(
    Object.keys(stepToDisplayName).map((tierIIReportStep) => ({
      step: tierIIReportStep as TierIiReportStep,
      name: stepToDisplayName[tierIIReportStep as TierIiReportStep],
      value: true,
    }))
  );

  const { data, loading } = useQuery(REPORTS_SAFE_TO_RESET, {
    variables: {
      reportIds: props.selectedReportIds,
    },
    skip: !props.selectedReportIds.length,
    fetchPolicy: "network-only",
  });

  const reportRows = useMemo(() => {
    if (radioValue === "removeUnsafeReports") {
      return data?.bulkReportsSafeToReset.filter((row) => row.isSafeToReset);
    }
    return data?.bulkReportsSafeToReset;
  }, [data?.bulkReportsSafeToReset, radioValue]);

  const safeToReset = useMemo(
    () => reportRows?.every((row) => row.isSafeToReset),
    [reportRows]
  );

  const canReset = useMemo(
    () => safeToReset || radioValue !== null,
    [safeToReset, radioValue]
  );

  if (loading) {
    return null;
  }

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>Reset Reports</DialogTitle>
      <DialogContent>
        <ResetReportExplainer />
        <Typography marginTop={"2rem"}>
          Bulk reset of reports will queue an asynchronous job to reset the
          reports. The change may not be immediately apparent in the UI. Please
          check the Jobs table to monitor the progress of the job.
        </Typography>
        <Box sx={{ m: "2rem 0" }}>
          {safeToReset ? (
            <SafeResetIndicator />
          ) : (
            <UnsafeResetIndicatorWithRadioOptions
              radioValue={radioValue}
              onChangeRadio={(e) => {
                setRadioValue(e.target.value as UnsafeReportsRadioOptions);
              }}
            />
          )}
        </Box>
        <Stack direction="row" gap={3}>
          <FormControl
            fullWidth
            disabled={!canReset}
            sx={{ mb: "1rem" }}
            required
          >
            <InputLabel id="org-status-label">Org Status</InputLabel>
            <Select
              labelId="org-status-label"
              id="org-status"
              label="Org Status"
              value={orgStatus}
              onChange={(e) =>
                setOrgStatus(e.target.value as TierIiReportOrgStatus)
              }
            >
              {orgStatuses.map((orgStatus) => (
                <MenuItem key={orgStatus} value={orgStatus}>
                  {orgStatusToLabel(orgStatus)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            disabled={!canReset}
            sx={{ mb: "1rem" }}
            required
          >
            <InputLabel id="encamp-status-label">Encamp Status</InputLabel>
            <Select
              labelId="encamp-status-label"
              id="encamp-status"
              label="Encamp Status"
              value={encampStatus}
              onChange={(e) =>
                setEncampStatus(e.target.value as TierIiReportEncampStatus)
              }
            >
              {encampStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {encampStatusToLabel(status)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" marginY={2}>
          <FormControlLabel
            label={"Force Cancel Workflow"}
            control={
              <Checkbox
                checked={shouldForceCancelWorkflow}
                onChange={() => {
                  setShouldForceCancelWorkflow((prev) => !prev);
                }}
              />
            }
          />
          <FormControlLabel
            label={"Force Replace Snapshot"}
            control={
              <Checkbox
                checked={shouldForceReSnapshot}
                onChange={() => {
                  setShouldForceReSnapshot((prev) => !prev);
                }}
              />
            }
          />
        </Stack>
        <Box>
          <Typography>Set report steps to "untouched"</Typography>
          <Grid container>
            {reportStepsUntouched.map(({ name, value }) => (
              <Grid item sm={4} key={name}>
                <FormControlLabel
                  label={name}
                  control={
                    <Checkbox
                      disabled={!canReset}
                      checked={value}
                      onChange={() => {
                        setReportStepsUntouched((prev) => {
                          const index = prev.findIndex(
                            (step) => step.name === name
                          );
                          const updated = [...prev];
                          updated[index] = {
                            ...prev[index],
                            value: !prev[index].value,
                          };
                          return updated;
                        });
                      }}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Grid container spacing={1} marginTop={1}>
          <Grid item xs={12}>
            <DataGrid
              columns={[
                {
                  field: "tenantName",
                  headerName: "Organization",
                  valueGetter: (params) => params.row.tenant?.name,
                  flex: 1,
                },
                {
                  field: "facilityName",
                  headerName: "Facility",
                  valueGetter: (params) => params.row.facility?.name,
                  flex: 0.8,
                },
                {
                  field: "facilityState",
                  headerName: "State",
                  valueGetter: (params) => params.row.facility?.state,
                  flex: 0.3,
                },
                {
                  field: "isSafeToReset",
                  headerName: "Safe to Reset",
                  align: "center",
                  flex: 0.8,
                  renderCell: (params) => {
                    return params.row.isSafeToReset ? <Check /> : <></>;
                  },
                },
                {
                  field: "unsafeResetReasons",
                  headerName: "Unsafe Reset Reasons",
                  flex: 1.4,
                  align: "center",
                  renderCell: (params) => {
                    if (params.row.isSafeToReset) {
                      return <RemoveIcon />;
                    }

                    return (
                      <List disablePadding sx={{ listStyleType: "disc" }}>
                        {params.row.unsafeResetReasons.map((reason) => (
                          <>
                            <ListItem
                              key={reason}
                              disablePadding
                              sx={{ display: "list-item" }}
                            >
                              <Typography fontSize={10}>{reason}</Typography>
                            </ListItem>
                          </>
                        ))}
                      </List>
                    );
                  },
                },
              ]}
              getRowHeight={() => "auto"}
              rows={reportRows ?? []}
              disableRowSelectionOnClick
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={props.onClose} data-cy="cancel">
            Cancel
          </Button>
          <SubmitResetReportsButton
            disabled={
              (!safeToReset && radioValue === null) ||
              reportRows?.length === 0 ||
              !orgStatus ||
              !encampStatus
            }
            onSubmit={props.onClose}
            organizationStatus={orgStatus ?? TierIiReportOrgStatus.NotStarted}
            encampStatus={encampStatus ?? TierIiReportEncampStatus.NotReporting}
            reportIds={reportRows?.map((row) => row.id) ?? []}
            safetyOverride={radioValue === "overrideSafetyCheck"}
            shouldForceCancelWorkflow={shouldForceCancelWorkflow}
            shouldForceReSnapshot={shouldForceReSnapshot}
            untouchedSteps={reportStepsUntouched
              .filter((item) => item.value)
              .map((item) => item.step)}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const SafeResetIndicator = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.success.main,
        display: "flex",
        alignItems: "end",
      }}
    >
      <CheckCircle />
      <Typography sx={{ marginLeft: "4px" }}>
        It is safe to reset these reports
      </Typography>
    </Box>
  );
};

const UnsafeResetIndicator = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.error.main,
        display: "flex",
        alignItems: "end",
      }}
    >
      <WarningAmberRounded />
      <Typography fontSize={14} sx={{ marginLeft: "4px" }}>
        It is not safe to reset these reports
      </Typography>
    </Box>
  );
};

type UnsafeReportsRadioOptions =
  | "removeUnsafeReports"
  | "overrideSafetyCheck"
  | null;

const UnsafeResetIndicatorWithRadioOptions = (props: {
  onChangeRadio: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  radioValue: UnsafeReportsRadioOptions;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: theme.palette.error.main,
        borderRadius: "3px",
        padding: "8px",
      }}
    >
      <UnsafeResetIndicator />

      <Typography fontSize={14} fontWeight="500" sx={{ m: "1rem 0" }}>
        Making changes now may affect the report submission, which has already
        started. Some steps cannot be recalled, such as submitting a payment.
      </Typography>
      <Typography fontSize={14} fontWeight="500" sx={{ m: "1rem 0" }}>
        Submit a ticket to{" "}
        <Link href={"https://encamp.slack.com/archives/C02EUTUBC1H"}>
          #engineering-support
        </Link>{" "}
        for manual review by an engineer.
      </Typography>

      <FormControl>
        <FormLabel id="unsafeReportsOptions">
          <Typography fontSize={14} fontWeight={500}>
            Please choose one of the following options:
          </Typography>
        </FormLabel>
        <Box marginLeft={1}>
          <RadioGroup
            aria-labelledby="unsafeReportsOptions"
            name="controlled-radio-buttons-group"
            value={props.radioValue}
            onChange={props.onChangeRadio}
          >
            <FormControlLabel
              value="removeUnsafeReports"
              control={<Radio size="small" />}
              label={
                <Typography fontSize={14} fontWeight={500}>
                  I want to remove unsafe reports from the selection
                </Typography>
              }
            />
            <FormControlLabel
              value="overrideSafetyCheck"
              control={<Radio size="small" />}
              label={
                <Typography fontSize={14} fontWeight={500}>
                  I've reviewed this with an engineer and want to override the
                  safety check
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
      </FormControl>
    </Box>
  );
};
