import { useQuery } from "@apollo/client";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CopyAllOutlined from "@mui/icons-material/CopyAllOutlined";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { gql } from "generated-graphql";
import {
  TierIiReportEncampStatus,
  TierIiReportOrgStatus,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useReportSteps } from "routes/Customer/Chemicals/Report/useReport";
import { encampStatusToLabel, orgStatusToLabel } from "util/constants";
import { SubmitResetReportButton } from "./ResetReportButton";
import { useReportDetails } from "./useReportDetails";
import { ResetReportExplainer } from "../../../../components/ResetReportExplainer";

export const REPORT_SAFE_TO_RESET = gql(`
query ReportSafeToReset($reportId: ID!) {
    reportSafeToReset(reportId: $reportId) {
      isSafeToReset
      unsafeResetReasons
      activities {
        isSafeToReset
        activity {
          id
          type
        }
      }
    }
}`);

type Props = { open: boolean; toggleResetReportModal: () => void };

export const ResetReportModal = (props: Props) => {
  const { toggleResetReportModal } = props;
  const { data } = useReportDetails();
  const [overrideSafetyCheck, setOverrideSafetyCheck] = useState(false);
  const [orgStatus, setOrgStatus] = useState<TierIiReportOrgStatus>();
  const [encampStatus, setEncampStatus] = useState<TierIiReportEncampStatus>();
  const [shouldForceCancelWorkflow, setShouldForceCancelWorkflow] =
    useState<boolean>(true);
  const [shouldForceReSnapshot, setShouldForceReSnapshot] =
    useState<boolean>(false);

  const reportSteps = useReportSteps();
  const steps = useMemo(
    () =>
      Object.entries(reportSteps).map(([step, { metaData }]) => ({
        step: step as TierIiReportStep,
        name: metaData.title,
        value: true,
      })),
    [reportSteps]
  );

  const [reportStepsUntouched, setReportStepsUntouched] =
    useState<{ step: TierIiReportStep; value: boolean; name: string }[]>(steps);

  const { reportId } = useParams<{ reportId: string }>();
  const safeToResetQuery = useQuery(REPORT_SAFE_TO_RESET, {
    variables: {
      reportId: reportId ?? "",
    },
    skip: !reportId,
    fetchPolicy: "network-only",
  });

  const safeToReset = safeToResetQuery.data?.reportSafeToReset.isSafeToReset;
  const canReset = safeToReset || overrideSafetyCheck;

  useEffect(() => {
    setReportStepsUntouched(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(steps)]);

  useEffect(() => {
    if (data) {
      setOrgStatus(data.tierIIReport.organizationStatus);
      setEncampStatus(data.tierIIReport.encampStatus);
    }
  }, [data]);

  const onClose = useCallback(() => {
    toggleResetReportModal();
    setOverrideSafetyCheck(false);
  }, [toggleResetReportModal]);

  return (
    <Dialog open={props.open} onClose={onClose}>
      <DialogTitle>Reset Report</DialogTitle>
      <DialogContent>
        <ResetReportExplainer />
        <Box sx={{ m: "2rem 0" }}>
          {safeToReset ? (
            <SafeResetIndicator />
          ) : (
            <UnsafeResetIndicatorWithOverride
              reportId={reportId}
              unsafeResetReasons={
                safeToResetQuery.data?.reportSafeToReset.unsafeResetReasons ??
                []
              }
              overrideSafetyCheck={overrideSafetyCheck}
              onChangeOverride={() =>
                setOverrideSafetyCheck(!overrideSafetyCheck)
              }
            />
          )}
        </Box>
        <Stack direction="row" gap={3}>
          <FormControl fullWidth disabled={!canReset} sx={{ mb: "1rem" }}>
            <InputLabel id="org-status-label">Org Status</InputLabel>
            <Select
              labelId="org-status-label"
              id="org-status"
              label="Org Status"
              value={orgStatus}
              onChange={(e) =>
                setOrgStatus(e.target.value as TierIiReportOrgStatus)
              }
            >
              {orgStatuses.map((orgStatus) => (
                <MenuItem key={orgStatus} value={orgStatus}>
                  {orgStatusToLabel(orgStatus)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!canReset} sx={{ mb: "1rem" }}>
            <InputLabel id="encamp-status-label">Encamp Status</InputLabel>
            <Select
              labelId="encamp-status-label"
              id="encamp-status"
              label="Encamp Status"
              value={encampStatus}
              onChange={(e) =>
                setEncampStatus(e.target.value as TierIiReportEncampStatus)
              }
            >
              {encampStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {encampStatusToLabel(status)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" marginY={2}>
          <FormControlLabel
            label={"Force Cancel Workflow"}
            control={
              <Checkbox
                checked={shouldForceCancelWorkflow}
                onChange={() => {
                  setShouldForceCancelWorkflow((prev) => !prev);
                }}
              />
            }
          />
          <FormControlLabel
            label={"Force Replace Snapshot"}
            control={
              <Checkbox
                checked={shouldForceReSnapshot}
                onChange={() => {
                  setShouldForceReSnapshot((prev) => !prev);
                }}
              />
            }
          />
        </Stack>
        <Box>
          <Typography>Set report steps to "untouched"</Typography>
          <Grid container>
            {reportStepsUntouched.map(
              ({ step, name, value }, reportStepIndex) => (
                <Grid item sm={4} key={name}>
                  <FormControlLabel
                    key={step}
                    label={name}
                    control={
                      <Checkbox
                        disabled={!canReset}
                        checked={value}
                        onChange={() =>
                          setReportStepsUntouched(
                            reportStepsUntouched.map((x, index) => {
                              if (index === reportStepIndex) {
                                return { ...x, value: !x.value };
                              }
                              return x;
                            })
                          )
                        }
                      />
                    }
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
        <DialogActions sx={{ padding: 0 }}>
          <Button onClick={onClose} data-cy="cancel">
            Cancel
          </Button>

          <SubmitResetReportButton
            disabled={!canReset || !reportId || !encampStatus || !orgStatus}
            reportId={reportId ?? ""}
            encampStatus={
              encampStatus ?? TierIiReportEncampStatus.AwaitingVerification
            }
            organizationStatus={orgStatus ?? TierIiReportOrgStatus.NotStarted}
            safetyOverride={overrideSafetyCheck}
            shouldForceCancelWorkflow={shouldForceCancelWorkflow}
            shouldForceReSnapshot={shouldForceReSnapshot}
            untouchedSteps={reportStepsUntouched
              .filter((reportStep) => reportStep.value)
              .map((reportStep) => reportStep.step)}
            onSubmit={onClose}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const SafeResetIndicator = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.success.main,
        display: "flex",
        alignItems: "end",
      }}
    >
      <CheckCircle />
      <Typography sx={{ marginLeft: "4px" }}>
        It is safe to reset this report
      </Typography>
    </Box>
  );
};

const UnsafeResetIndicator = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.error.main,
        display: "flex",
        alignItems: "end",
      }}
    >
      <WarningAmberRounded />
      <Typography fontSize={14} sx={{ marginLeft: "4px" }}>
        It is not safe to reset this report
      </Typography>
    </Box>
  );
};

const UnsafeResetIndicatorWithOverride = (props: {
  reportId?: string;
  unsafeResetReasons: string[];
  overrideSafetyCheck: boolean;
  onChangeOverride: () => void;
}) => {
  const theme = useTheme();
  const alerts = useAlerts();

  const reportActivitiesUrl = `https://staff.encamp.com/staff/fulfillment/${props.reportId}/activites`;
  const handleCopy = () => {
    navigator.clipboard.writeText(reportActivitiesUrl);
    alerts.info("Copied to clipboard");
  };

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: theme.palette.error.main,
        borderRadius: "3px",
        padding: "8px",
      }}
    >
      <UnsafeResetIndicator />

      <List disablePadding>
        {props.unsafeResetReasons.map((reason) => (
          <ListItem key={reason} sx={{ padding: "0 30px" }}>
            <Typography fontSize={14} sx={{ color: theme.palette.error.main }}>
              - {reason}
            </Typography>
          </ListItem>
        ))}
      </List>

      <Typography fontSize={14} fontWeight="500" sx={{ m: "1rem 0" }}>
        Making changes now may affect the report submission, which has already
        started. Some steps cannot be recalled, such as submitting a payment.
      </Typography>
      <Typography fontSize={14} fontWeight="500" sx={{ m: "1rem 0" }}>
        Submit a ticket to{" "}
        <Link href={"https://encamp.slack.com/archives/C02EUTUBC1H"}>
          #engineering-support
        </Link>{" "}
        for manual review by an engineer with these{" "}
        <Link href={reportActivitiesUrl} target="_blank">
          report details
        </Link>
        .{" "}
        <IconButton onClick={handleCopy} sx={{ padding: 0 }}>
          <CopyAllOutlined sx={{ height: "16px", width: "16px" }} />
        </IconButton>
      </Typography>

      <FormControlLabel
        sx={{ marginLeft: "0px" }}
        control={
          <Checkbox
            sx={{ padding: "9px 4px 9px 0px" }}
            checked={props.overrideSafetyCheck}
            onChange={props.onChangeOverride}
          />
        }
        label={
          <Typography fontSize={14} fontWeight={500}>
            I've reviewed this with an engineer and want to override the safety
            check
          </Typography>
        }
      />
    </Box>
  );
};

const orgStatuses: TierIiReportOrgStatus[] = [
  TierIiReportOrgStatus.NotStarted,
  TierIiReportOrgStatus.NotReporting,
  TierIiReportOrgStatus.Assigned,
  TierIiReportOrgStatus.InProgress,
  TierIiReportOrgStatus.InReview,
  TierIiReportOrgStatus.Verified,
];

const encampStatuses: TierIiReportEncampStatus[] = [
  TierIiReportEncampStatus.AwaitingVerification,
  TierIiReportEncampStatus.Processing,
  TierIiReportEncampStatus.FilingComplete,
  TierIiReportEncampStatus.NotReporting,
];
