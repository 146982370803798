import AsyncAutocomplete from "components/Forms/AsyncAutocomplete";
import { AssigneePickerFragment } from "generated-graphql/graphql";
import { GET_USERS_QUERY } from "./userQueries";
import { ControllerFieldState } from "react-hook-form";
import { getFullName } from "encamp-shared/src/utils/name";

export const AssigneePicker = ({
  tenantId,
  value,
  onChange,
  error,
}: {
  tenantId: string;
  value: AssigneePickerFragment | null;
  onChange: (item: AssigneePickerFragment | null) => void;
} & ControllerFieldState) => {
  return (
    <AsyncAutocomplete
      query={GET_USERS_QUERY}
      getItems={(data) => data.users.items}
      placeholderLabel="Assignee *"
      getOptionLabel={(item) => {
        const fullName = getFullName(item.person);
        return fullName ? `${fullName} (${item.email})` : item.email;
      }}
      onChange={onChange}
      value={value}
      additionalSearchString={`tenantId:${tenantId}`}
      error={error}
    />
  );
};
