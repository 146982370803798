import { Alert, Grid, Stack, Typography } from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { Control, UseFormWatch } from "react-hook-form";
import { RegInfoForm } from "./Regulatory";

export const CaliforniaRegulatory: React.FC<{
  control: Control<RegInfoForm, any>;
  watch: UseFormWatch<RegInfoForm>;
}> = ({ control, watch }) => {
  const showHazardousWasteOnSiteNote = watch("hazardousWasteOnSite");
  const showHazardousWasteConsolidateNote = watch("hazardousWasteConsolidate");
  const showHasHazardousWasteNote =
    watch("epaId") && !watch("hasHazardousWaste");
  const showHasHazardousWasteEpaId = watch("hasHazardousWaste");

  return (
    <>
      <Typography variant="h6">Hazardous Materials</Typography>
      <Stack>
        <RadioGroupField
          label="Does your facility have on site (for any purpose) at any one time, hazardous materials at or above 55 gallons for liquids, 500 pounds for solids, or 200 cubic feet for compressed gases (include liquids in ASTs and USTs); or is regulated under more restrictive local inventory reporting requirements (shown below if present); or the applicable Federal threshold quantity for an extremely hazardous substance specified in 40 CFR Part 355, Appendix A or B; or handle radiological materials in quantities for which an emergency plan is required pursuant to 10 CFR Parts 30, 40 or 70? 10.0002"
          tooltip="You have a hazardous material onsite if the material is 1) handled in quantities equal to or greater than 500 pounds, 55 gallons, or 200 cubic feet of compressed gas (calculated at standard temperature and pressure); 2) handled in quantities equal to or greater than the applicable federal threshold planning quantity for an extremely hazardous substance listed in 40 CFR Part 355, Appendix A; or 3) radioactive and handled in quantities requiring an emergency plan under federal or state regulations."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hazardousMaterialsOnSite"
          control={control}
        />
      </Stack>
      <Typography variant="h6">Underground Storage Tank(s)</Typography>
      <Stack>
        <RadioGroupField
          label="Does your facility own or operate underground storage tanks?"
          tooltip="Check the appropriate box to indicate whether you own or operate UST's containing hazardous substances as defined in Health and Safety Code (HSC) 25316. If YES, then CERS will prompt you to submit additional forms and supplemental documentation for your UST facility and its tank(s)."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hasUST"
          control={control}
        />
      </Stack>
      <Typography variant="h6">Hazardous Waste</Typography>
      <Stack>
        <RadioGroupField
          label="Does your facility generate Hazardous Waste?"
          tooltip='A hazardous waste "generator" is the person or business whose acts or processes produce a hazardous waste, or who causes a hazardous substance or waste to become subject to State hazardous waste law. If your facility generates "hazardous waste", you must provide your facility\&apos;s EPA Identification number (EPA ID) you use to properly transport and dispose of your hazardous waste. "Hazardous waste" means a waste that meets any of the criteria for the identification of a hazardous waste adopted by Department of Toxic Substances Control pursuant to HSC§25141, and includes, but is not limited to, federally regulated hazardous waste, extremely hazardous waste, and acutely hazardous waste unless explicitly stated otherwise.'
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hasHazardousWaste"
          control={control}
        />
        {showHasHazardousWasteEpaId && (
          <Grid item xs={3}>
            <FormTextField
              name="epaId"
              control={control}
              label="EPA ID number"
            />
          </Grid>
        )}
        {showHasHazardousWasteNote && (
          <Alert severity="info" sx={{ fontSize: "0.75rem", mt: -2 }}>
            Your facility has an active EPA ID, which often means that the
            facility generates hazardous waste. Please make sure you have
            answered this question accurately.
          </Alert>
        )}
      </Stack>

      <Stack>
        <RadioGroupField
          label="Does your facility treat hazardous waste on-site?"
          tooltip='Indicate if your facility engages in onsite treatment of hazardous waste. "Treatment" means any method, technique, or process designed to change the physical, chemical, or biological character or composition of any hazardous waste or any material contained therein, or removes or reduces its harmful properties or characteristics for any purpose. Treatment does not include the removal of residues from manufacturing process equipment for cleaning purposes, and there are other exemptions for certain processes under specific, limited conditions (see HSC§25123.5(b) and HSC§25200.3.1). Contact your CUPA to determine if any exemptions apply to your facility.'
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hazardousWasteOnSite"
          control={control}
        />
        {showHazardousWasteOnSiteNote && (
          <Alert severity="info" sx={{ fontSize: "0.75rem", mt: -2 }}>
            If your facility treats hazardous waste on-site, you are required to
            submit Tiered Permitting information to CERS. Please contact Encamp
            Support for assistance.
          </Alert>
        )}
      </Stack>

      <Stack>
        <RadioGroupField
          label="Is your facility's treatment subject to financial assurance requirements (for Permit by Rule or Conditional Authorization)?"
          tooltip="Check the appropriate box to indicate whether your facility is subject to financial assurance requirements for closure of an onsite treatment unit. Unless they are exempt, Permit by Rule (PBR) and Conditionally Authorized (CA) operations are required to provide financial assurance for closure costs (per 22.CCR§67450.13(b) and HSC§25245.4). If your facility is subject to financial assurance requirements or claiming an exemption, then complete the Certification of Financial Assurance page."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hazardousWasteFinancialAssuranceRequirements"
          control={control}
        />
      </Stack>

      <Stack>
        <RadioGroupField
          label="Does your facility consolidate hazardous waste generated at a remote site?"
          tooltip="Answer yes if you are a hazardous waste generator that collects hazardous waste initially at remote sites and subsequently transports the hazardous waste to a consolidation site you also operate. You must be eligible pursuant to the conditions in HSC§25110.10."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hazardousWasteConsolidate"
          control={control}
        />

        {showHazardousWasteConsolidateNote && (
          <Alert severity="info" sx={{ fontSize: "0.75rem", mt: -3 }}>
            If your facility consolidates hazardous waste generated at a remote
            site, you are required to submit Remote Waste Consolidation Site
            Annual Notification information to CERS. Please contact Encamp
            Support for assistance.
          </Alert>
        )}
      </Stack>

      <Stack>
        <RadioGroupField
          label="Does your facility need to report the closure/removal of a tank that was classified as hazardous waste and cleaned on-site?"
          tooltip="Indicate whether the tank being closed would be classified as hazardous waste after its contents are removed because: 1) your knowledge of the tank and its contents, 2) the mixture rule, 3) testing of the tank, 4) the listed wastes in 40 CFR 261.31 or 40 CFR 261.32, 5) inability to remove hazardous materials stored in the tank."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hazardousWasteTankClosureRemoval"
          control={control}
        />
      </Stack>

      <Stack>
        <RadioGroupField
          label="Is your facility a RCRA Large Quantity Generator?"
          tooltip="Indicate if your facility is a Large Quantity Generator (LQG). If YES, you must have or obtain a US EPA ID Number before you can submit the LQG portions of your submittal."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hazardousWasteRCRA"
          control={control}
        />
      </Stack>

      <Stack>
        <RadioGroupField
          label="Is your facility a Household Hazardous Waste (HHW) Collection site?"
          tooltip="Check the appropriate box to indicate whether your facility is a HHW Collection site."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hazardousWasteHHWCollectionSite"
          control={control}
        />
      </Stack>

      <Typography variant="h6">Excluded and/or Exempted Materials</Typography>

      <Stack>
        <RadioGroupField
          label="Facility recycles more than 100 kg/month of excluded or exempted recyclable materials"
          tooltip="Check the appropriate box to indicate whether you recycle more than 100 kilograms per month of recyclable material, under a claim that the material is excluded or exempt per HSC 25143.2. Check “YES” and complete the Recyclable Materials Report pages, if you either recycled onsite or recycled excluded recyclable materials, which were generated off-site. Check “NO” if you only send recyclable materials to an off-site recycler. You do not need to report."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hasExcludedExemptedMaterials"
          control={control}
        />
      </Stack>

      <Typography variant="h6">Aboveground Petroleum Storage</Typography>

      <Stack>
        <RadioGroupField
          label="Does your facility own or operate aboveground petroleum storage tanks or containers AND: have a total aboveground petroleum storage capacity of 1,320 gallons or more, OR have one or more petroleum tanks in an underground area?"
          tooltip='If your facility is NOT a FARM, check the appropriate box to indicate if your facility stores petroleum in aboveground storage tanks, containers, or equipment of 55 gallons or more in shell capacity and: • the total aboveground petroleum storage capacity is 1,320 gallons or more; OR • has one or more petroleum tanks in an underground area as defined in HSC §25270.2(o). If your facility is a FARM, visit the following website to determine if your farm is regulated under APSA: <a href="https://osfm.fire.ca.gov/what-we-do/pipeline-safety-and-cupa/certified-unified-program-agency/aboveground-petroleum-storage-act/farms" target="_blank" rel="noopener noreferrer">https://osfm.fire.ca.gov/what-we-do/pipeline-safety-and-cupa/certified-unified-program-agency/aboveground-petroleum-storage-act/farms</a> "Petroleum" means crude oil or any fraction thereof, which is liquid at 60 degrees Fahrenheit temperature and 14.7 pounds per square inch absolute pressure (HSC §25270.2(h)). When determining the aggregate petroleum storage capacity for the facility, DO NOT include aboveground storage tanks, containers, or equipment EXCLUDED under APSA. Refer to HSC §25270.2(a) for APSA exclusions.'
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hasAPS"
          control={control}
        />
      </Stack>

      <Typography variant="h6">Regulated Substances</Typography>

      <Stack>
        <RadioGroupField
          label="Facility stores Regulated Substances onsite in quantities greater than the threshold quantities established by the CalARP Program"
          tooltip="Refer to 19 CCR 2770.5 for regulated substances. Check the box to indicate whether your facility has CalARP regulated substances stored on-site."
          radioOptions={[
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ]}
          name="hasRegulatedSubstancesGreaterThanThreshold"
          control={control}
        />
      </Stack>
    </>
  );
};
