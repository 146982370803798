import { useLazyQuery } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";
import FindReplace from "@mui/icons-material/FindReplace";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DocumentPreview } from "components/DocumentPreview";
import { Dropzone } from "components/Dropzone";
import {
  FacilityDocumentPicker,
  FacilityDocumentPickerItem,
} from "components/FacilityDocumentPicker";
import { DateField } from "components/Forms/DateField";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { FormTextField } from "components/Forms/FormTextField";
import { IssueListButton } from "components/Forms/IssueListButton";
import { SaveButton } from "components/SaveButton";
import {
  HmbpSectionWithoutRegulatory,
  hmbpSectionToDocumentType,
} from "encamp-shared/src/hmbp";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { DocumentType, Issue } from "generated-graphql/graphql";
import { useDocumentDownloadLink } from "hooks/useDocumentDownloadLink";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import { CaliforniaDocumentTypes } from "util/constants";
import { useReport } from "../../../useReport";
import { FormState, UploadFile } from "./types";

type UploadProps = {
  loading?: boolean;
  facilityId: string;
  handleBack: () => void;
  section: HmbpSectionWithoutRegulatory;
  issues: Issue[];
};

export function Upload({
  loading,
  facilityId,
  handleBack,
  section,
  issues,
}: UploadProps) {
  const documentType = hmbpSectionToDocumentType[section];
  const documentTypes = documentType ? [documentType] : CaliforniaDocumentTypes;
  const theme = useTheme();
  const alerts = useAlerts();
  const { data } = useReport();
  const contentElementRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null); // Ref for hidden file input
  const { control, getValues, setValue } = useFormContext<FormState>();
  const [replaceIndex, setReplaceIndex] = useState<number | null>(null);
  const [isAddingAnotherFile, setIsAddingAnotherFile] = useState(false);
  const { append, fields, remove, update } = useFieldArray<
    FormState,
    "upload",
    "uniqueId"
  >({
    control,
    name: "upload",
    keyName: "uniqueId",
  });

  const isExistingDocument = (upload: UploadFile) => {
    return !!upload.document?.id;
  };

  const [downloadPreviewUrl] = useDocumentDownloadLink();
  const handleDocumentSelect = async (document: FacilityDocumentPickerItem) => {
    const { data } = await downloadPreviewUrl({
      variables: { id: document.id },
    });

    append({
      document,
      extension: document.fileExtension ?? "",
      previewURL: data?.getDocumentDownloadLink ?? null,
    });

    if (isAddingAnotherFile) {
      setIsAddingAnotherFile(false);
    }
  };

  const handleFileSelect = async (files: File[]) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    append({
      file,
      extension: file.name.split(".").pop()?.toLowerCase() ?? "",
      previewURL: URL.createObjectURL(file),
    });

    if (isAddingAnotherFile) {
      setIsAddingAnotherFile(false);
    }
  };

  const handleReplaceFile = (index: number) => {
    setReplaceIndex(index);
    fileInputRef.current?.click();
  };

  const onFileReplace = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && replaceIndex !== null) {
      update(replaceIndex, {
        ...fields[replaceIndex],
        file,
        document: {
          ...fields[replaceIndex].document,
          id: fields[replaceIndex].document?.id ?? "",
          title: file.name,
          storageLink: fields[replaceIndex].document?.storageLink ?? "",
          documentType:
            fields[replaceIndex].document?.documentType ?? DocumentType.Other,
        },
        extension: file.name.split(".").pop()?.toLowerCase() ?? "",
        previewURL: URL.createObjectURL(file),
      });

      // Reset the replace index and clear the file input
      setReplaceIndex(null);
      event.target.value = "";
    }
  };

  const [download] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_LINK_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data?.getDocumentDownloadLink) {
        return alerts.error("Error fetching download link");
      }

      alerts.success("Success fetching download link");
      window.open(data.getDocumentDownloadLink, "_blank");
    },
    onError: () => {
      alerts.error("Error fetching download link");
    },
  });

  const handleDelete = async (upload: UploadFile, index: number) => {
    const currentDocuments = getValues("upload");

    // Remove the deleted document
    remove(index);

    const remainingDocuments = (currentDocuments ?? []).filter(
      (_, i) => i !== index
    );

    // Update the remaining document fields to account for the new indexes
    remainingDocuments.forEach((uploadFile, newIndex) => {
      if (uploadFile.document) {
        setValue(
          `upload.${newIndex}.document.authoredAt`,
          uploadFile.document.authoredAt
        );
        setValue(
          `upload.${newIndex}.document.description`,
          uploadFile.document.description
        );
      }
    });
  };

  const handleDownload = async (documentId: string) => {
    download({
      variables: { id: documentId },
    });
  };

  const documentIds = useMemo(() => {
    return fields.map((f) => f.document?.id).filter(hasValue);
  }, [fields]);

  const hasRequiredDocument = useMemo(() => fields.length > 0, [fields]);

  const initRef = useRef(false);

  // Add this effect to preload documents
  useEffect(() => {
    const loadDocuments = async () => {
      if (initRef.current) return;
      if (!data?.tierIIReport.documents) return;
      initRef.current = true;

      const documentType = hmbpSectionToDocumentType[section];
      if (!documentType) return;

      // Find documents matching the section's document type
      const matchingDocuments = data.tierIIReport.documents.filter(
        (d) => d.documentType === documentType
      );

      // Pre-populate form with matching documents
      await Promise.all(
        matchingDocuments.map(async (d) => {
          const { data } = await downloadPreviewUrl({
            variables: { id: d.id },
          });

          append({
            document: {
              id: d.id,
              title: d.title,
              fileExtension: d.fileExtension,
              documentType: d.documentType,
              storageLink: d.storageLink,
              authoredAt: d.authoredAt,
              description: d.description,
            },
            extension: d.fileExtension ?? "",
            previewURL: data?.getDocumentDownloadLink ?? "",
          });
        })
      );
      initRef.current = true;
    };
    loadDocuments();
  }, [data?.tierIIReport.documents, section, append, downloadPreviewUrl]);

  // Effect to handle scrolling the dropzone into view when adding another file
  useEffect(() => {
    setTimeout(() => {
      if (contentElementRef.current?.lastChild) {
        (contentElementRef.current.lastChild as Element).scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 0);
  }, [isAddingAnotherFile]);

  return (
    <Fragment>
      <DialogContent>
        <Stack
          direction="column"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={onFileReplace}
          />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              paddingRight:
                (fields && fields.length > 1) || isAddingAnotherFile
                  ? theme.spacing(2)
                  : 0,
            }}
          >
            <Typography variant="body1">Upload Document</Typography>
            <ErrorDisplay
              error={
                !hasRequiredDocument
                  ? {
                      type: "required",
                      message:
                        "At least one document is required for this section",
                    }
                  : undefined
              }
            />

            {fields.length === 0 && (
              <Stack spacing={2} marginTop={theme.spacing(2)}>
                <FacilityDocumentPicker
                  facilityId={facilityId}
                  documentTypes={documentTypes}
                  onSelect={handleDocumentSelect}
                  disabled={loading ?? false}
                />

                <UploadFormDropzone onDrop={handleFileSelect} />
              </Stack>
            )}

            {fields && fields.length > 0 && (
              <Stack ref={contentElementRef} gap={theme.spacing(3)}>
                {fields.map((upload, index) => {
                  return (
                    <Fragment key={index}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="caption">
                          {upload.file?.name
                            ? upload.file.name
                            : upload.document?.title}
                        </Typography>
                        <Stack direction="row" gap={theme.spacing(1)}>
                          <IconButton
                            onClick={() => handleDelete(upload, index)}
                          >
                            <Delete color="primary" />
                          </IconButton>
                          <IconButton onClick={() => handleReplaceFile(index)}>
                            <FindReplace color="primary" />
                          </IconButton>
                          {upload.document?.id && !upload.file && (
                            <IconButton
                              onClick={() =>
                                handleDownload(upload.document?.id ?? "")
                              }
                            >
                              <Download color="primary" />
                            </IconButton>
                          )}
                        </Stack>
                      </Stack>

                      {upload.extension === "pdf" && upload.previewURL && (
                        <Box sx={{ height: theme.spacing(30) }}>
                          <DocumentPreview fileUrl={upload.previewURL} />
                        </Box>
                      )}

                      <DateField
                        name={`upload.${index}.document.authoredAt`}
                        label="Date Authored"
                        required
                        sx={{
                          width: "40%",
                          marginTop: 0,
                          marginBottom: -3,
                        }}
                      />

                      <FormTextField
                        name={`upload.${index}.document.description`}
                        label="Description / Comments"
                        sx={{ marginBottom: -3 }}
                      />
                    </Fragment>
                  );
                })}
                {!isAddingAnotherFile && fields.length > 0 && (
                  <Button
                    startIcon={<Add />}
                    onClick={() => setIsAddingAnotherFile(true)}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    Add Another
                  </Button>
                )}
                {isAddingAnotherFile && (
                  <Stack spacing={2} marginTop={theme.spacing(2)}>
                    <FacilityDocumentPicker
                      facilityId={facilityId}
                      documentTypes={documentTypes}
                      onSelect={handleDocumentSelect}
                      disabled={loading ?? false}
                      disabledDocumentIds={documentIds}
                    />
                    <UploadFormDropzone onDrop={handleFileSelect} />
                  </Stack>
                )}
              </Stack>
            )}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          gap={theme.spacing(2)}
          sx={{
            paddingTop: theme.spacing(3),
            justifyContent: "flex-end",
          }}
        >
          <IssueListButton issues={issues} />
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
          <SaveButton
            loading={loading ?? false}
            disabled={fields.length === 0 || !hasRequiredDocument}
          />
        </Stack>
      </DialogActions>
    </Fragment>
  );
}

type UploadFormDropzoneProps = {
  onDrop: (files: File[]) => void;
};

function UploadFormDropzone({ onDrop }: UploadFormDropzoneProps) {
  const theme = useTheme();

  return (
    <Dropzone
      label="Drag and drop your document or click to select file"
      dragActiveText="Drop the file here"
      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      sx={{ height: theme.spacing(30) }}
    />
  );
}
