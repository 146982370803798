import React, { useCallback, useState } from "react";
import { v4 } from "uuid";
import {
  IconButton,
  Button,
  TextField,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Check from "@mui/icons-material/Check";
import { Control, useController, useFieldArray } from "react-hook-form";
import { TaskFormType } from "./TaskDialog";

type ReminderDaysFormControlProps = {
  control: Control<TaskFormType>;
};

const ReminderDaysFormControl: React.FC<ReminderDaysFormControlProps> = ({
  control,
}) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "reminderDays",
    keyName: "_id",
  });

  const [editingId, setEditingId] = useState<string | null>(null);

  const handleAddReminder = useCallback(() => {
    const newId = v4();
    append({ days: 3, id: newId }, { shouldFocus: true });
    setEditingId(newId);
  }, [append]);

  const handleDeleteReminder = useCallback(
    (id: string) => {
      const idx = fields.findIndex((field) => field.id === id);
      if (editingId === id) {
        setEditingId(null);
      }
      remove(idx);
    },
    [editingId, fields, remove]
  );

  const onChange = useCallback(
    (value: string) => {
      if (editingId == null) return false;
      const idx = fields.findIndex((field) => field.id === editingId);
      if (idx === -1) return false;

      const numberValue = parseInt(value);
      if (!isNaN(numberValue)) {
        update(idx, { days: numberValue, id: editingId });
      }
      return true;
    },
    [editingId, fields, update]
  );

  const handleSave = useCallback((id: string) => {
    setEditingId(null);
  }, []);

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Reminder Settings</Typography>
        <div>
          <Button
            onClick={handleAddReminder}
            disabled={editingId !== null}
            startIcon={<Add />}
          >
            Add Reminder
          </Button>
        </div>
      </Stack>
      <Stack direction="column">
        {fields?.length === 0 && (
          <Typography variant="body2">
            No reminders have been added to this task yet
          </Typography>
        )}
        {fields.map((field, index) => (
          <ReminderItem
            control={control}
            index={index}
            key={field.id}
            isEditing={editingId === field.id}
            handleDelete={() => handleDeleteReminder(field.id)}
            handleEdit={() => setEditingId(field.id)}
            handleSave={() => handleSave(field.id)}
            onChange={onChange}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const ReminderItem: React.FC<{
  control: Control<TaskFormType>;
  index: number;
  isEditing: boolean;
  handleDelete: () => void;
  handleEdit: () => void;
  handleSave: () => void;
  onChange: (value: string) => boolean;
}> = ({
  index,
  control,
  isEditing,
  handleDelete,
  handleEdit,
  handleSave,
  onChange,
}) => {
  const theme = useTheme();
  const { field, fieldState } = useController({
    control,
    name: `reminderDays.${index}.days`,
  });

  return (
    <Stack direction="row" justifyContent="space-between">
      {isEditing ? (
        <TextField
          value={field.value ?? ""}
          error={fieldState.error != null}
          label="Days"
          helperText={
            fieldState.error?.message ??
            "The number of days before the deadline"
          }
          onChange={(e) =>
            onChange(e.target.value) && field.onChange(e.target.value)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSave();
            }
          }}
          type="number"
          inputProps={{ min: 1 }}
          fullWidth
        />
      ) : (
        <Typography variant="body2" width="100%">
          Notify {field.value} days before deadline
          <Typography sx={{ paddingLeft: 1 }} variant="caption" color="error">
            {fieldState.error?.message}
          </Typography>
        </Typography>
      )}
      <Stack
        direction="row"
        alignItems={isEditing ? "top" : "center"}
        height={34}
        spacing={theme.spacing(0.5)}
        marginTop={isEditing ? theme.spacing(1.5) : 0}
        marginLeft={theme.spacing(1)}
      >
        {isEditing ? (
          <IconButton edge="end" size="small" onClick={handleSave}>
            <Check />
          </IconButton>
        ) : (
          <IconButton edge="end" size="small" onClick={handleEdit}>
            <Edit />
          </IconButton>
        )}
        <IconButton edge="end" size="small" onClick={handleDelete}>
          <Delete />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ReminderDaysFormControl;
