import { FacilityDocumentPickerItem } from "components/FacilityDocumentPicker";
import { CaSections } from "encamp-shared/src/hmbp/sections";
import { DynamicField } from "generated-graphql/graphql";

export enum DocumentOption {
  Upload = "Upload",
  ProvideElsewhere = "Provided Elsewhere",
  Exempt = "Exempt",
}

export enum DialogStep {
  DocumentOptions = "document-options",
  Upload = "upload",
  ProvidedElsewhere = "provided-elsewhere",
  Exempt = "exempt",
}

export type FormState = {
  documentOption: DocumentOption | null;
  suppliedIn: CaSections | null;
  suppliedInExplanation: string | null;
  exemptExplanation: string | null;
  documentIds: string[];
  upload?: UploadFile[];
};

export type FormSubmitData = {
  documentUploads: UploadFile[];
  stateFields: DynamicField[];
};

export type UploadFile = {
  document?: FacilityDocumentPickerItem;
  file?: File;
  extension: string;
  previewURL: string | null;
};
