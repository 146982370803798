import { useMutation, useQuery } from "@apollo/client";
import { useAlerts } from "components/Alerts/AlertProvider";
import {
  CA_DocumentOption,
  CA_FinancialResponsibilityMechanisms,
  CA_UstPermitHolderInfo,
} from "encamp-compliance-engine/src/types/facilityStateFieldTypes";
import {
  HmbpMetadata,
  HmbpSection,
  hmbpSectionFromDocumentType,
  hmbpSectionToDocumentType,
  HmbpSectionWithoutRegulatory,
  HmbpTitle,
  HmbpUndergroundStorageTankSection,
  USTContents,
} from "encamp-shared/src/hmbp";
import { gql } from "generated-graphql";
import {
  DocumentType,
  DynamicField,
  Issue,
  ProgramArea,
  TierIiReportCaStep,
  TierIiReportStep,
  UndergroundStorageTank,
  UndergroundStorageTankInput,
} from "generated-graphql/graphql";
import { transform as transformStateField } from "hooks/transform/transformStateField";
import { transform } from "hooks/transform/transformUndergroundStorageTank";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { Path } from "react-hook-form";
import { ReportStepState } from "util/constants";
import {
  DocumentOption,
  UploadFile,
} from "./StateInformation/hmbpComponents/HmbpDocumentForm/types";
import {
  GET_REPORT,
  Report,
  useReport,
  useReportDynamicFields,
  useReportIssues,
} from "./useReport";
import { useTouchReportMutation } from "./useTouchReportMutation";
import { useDocumentInputValidation } from "./validationHooks/useDocumentInputValidation";
import { useFacilityInputValidation } from "./validationHooks/useFacilityInputValidation";

gql(`
  fragment undergroundStorageTank on UndergroundStorageTank {
    id
    facilityId
    tankId
    nickname

    typeOfAction
    manufacturer
    capacityGallons
    installationDate
    existingDiscoveredDate
    permanentlyClosedDate
    configuration
    numberOfCompartments
    additionalDescription
    tankUsage
    tankUsageOther
    tankContents
    tankContentsOther
    type
    constructionPrimaryContainment
    constructionPrimaryContainmentOther
    constructionSecondaryContainment
    constructionSecondaryContainmentOther
    overfillProtection
    pipingConstruction
    pipingSystemType
    productWastePrimaryContainment
    productWastePrimaryContainmentOther
    productWasteSecondaryContainment
    productWasteSecondaryContainmentOther
    pipingTurbineContainmentSump
    ventPrimaryContainment
    ventPrimaryContainmentOther
    ventSecondaryContainment
    ventSecondaryContainmentOther
    vaporRecoveryPrimaryContainment
    vaporRecoveryPrimaryContainmentOther
    vaporRecoverySecondaryContainment
    vaporRecoverySecondaryContainmentOther
    riserPipePrimaryContainment
    riserPipePrimaryContainmentOther
    riserPipeSecondaryContainment
    riserPipeSecondaryContainmentOther
    ventPipingTransitionSumps
    fillComponentsInstalled
    constructionType
    constructionMaterial
    constructionMaterialOther
    corrosionProtection
    
    monitoringEquipmentServiced
    monitoringEquipmentServicedOther
    sitePlotPlanSubmitted
    udcMonitoring
    udcMonitoringOther
    udcPanelManufacturer
    udcPanelModel
    udcLeakSensorManufacturer
    udcLeakSensorModel
    hasUdcLeakDetection
    hasUdcLeakAlarmTriggerShutdown
    hasUdcFailureTriggerShutdown
    hasUdcMonitoringStopFlow
    udcConstruction
    udcSecondaryContactMonitoring
    hasUdcSecondaryLeakDetection
    periodicSystemTesting
    recordKeeping
    ustPersonnelFamiliarTraining
    otherTrainingDocuments
    designatedOperatorTraining
    additionalInformation
    firstNameResponsibility
    firstNameTitle
    secondNameResponsibility
    secondNameTitle

    tankMonitoringMethod
    continuousElectronicTankMonitoringSecondaryContainmentSystem
    continuousElectronicTankMonitoringEmpManufacturer
    continuousElectronicTankMonitoringEmpModelNumber
    continuousElectronicTankMonitoringLeakSensorManufacturer
    continuousElectronicTankMonitoringLeakSensorModelNumber
    automaticTankGaugingAtgPanelManufacturer
    automaticTankGaugingAtgModelNumber
    automaticTankGaugingProbeManufacturer
    automaticTankGaugingProbeModelNumber
    automaticTankGaugingLeakTestFrequency
    automaticTankGaugingLeakTestFrequencyOther
    automaticTankGaugingProgrammedTankTests
    automaticTankGaugingProgrammedTankTestsOther
    weeklyManualTankGaugeGaugingTestPeriod
    integrityTestingFrequency
    integrityTestingFrequencyOther
    otherMonitoring

    pipeMonitoringMethod
    pipeSecContainmentMonitoringPipingSecondaryContainment
    pipeSecContainmentMonitoringPanelManufacturer
    pipeSecContainmentMonitoringPanelModelNumber
    pipeSecContainmentMonitoringLeakSensorManufacturer
    pipeSecContainmentMonitoringLeakSensorModelNumber
    pipeSecContainmentMonitoringLeakAlarmTriggersAutoPumpShutdown
    pipeSecContainmentMonitoringFailureTriggersPumpShutdown
    mechanicalLineLeakDetectorManufacturer
    mechanicalLineLeakDetectorModelNumber
    electronicLineLeakDetectorManufacturer
    electronicLineLeakDetectorModelNumber
    electronicLineLeakDetectorProgrammedInlineTesting
    electronicLineLeakDetectorTriggersAutoPumpShutdown
    electronicLineLeakDetectorFailureTriggersPumpShutdown
    pipelineIntegrityTestingFrequency
    pipelineIntegrityTestingFrequencyOther
    visualPipelineMonitoringFrequency
    otherPipelineMonitoring

    previouslyReported
  }
`);

export type HmbpIssue = Issue & {
  metadata: HmbpMetadata;
};

export type HmbpDynamicField = DynamicField & {
  metadata: HmbpMetadata;
};

type HmbpOptions = {
  section?: HmbpSection;
  omit?: HmbpSection[];
};

function isHmbpType<T extends { metadata?: { hmbpSection?: HmbpSection } }>(
  item: T,
  options?: HmbpOptions
): item is T & { metadata: HmbpMetadata } {
  const hmbpSection = item.metadata?.hmbpSection;
  return options?.section
    ? hmbpSection === options.section
    : hmbpSection !== undefined && !options?.omit?.includes(hmbpSection);
}

export function isHmbpIssue(
  issue: Issue,
  options: HmbpOptions
): issue is HmbpIssue {
  return isHmbpType(issue, options);
}

export function isHmbpDynamicField(
  field: DynamicField,
  options: HmbpOptions
): field is HmbpDynamicField {
  return isHmbpType(field, options);
}

export function isHmbpDocumentType(documentType?: DocumentType): boolean {
  return documentType
    ? Object.values(hmbpSectionToDocumentType).includes(documentType)
    : false;
}

export function useHmbpFeature(state: string) {
  return useMemo(() => state === "CA", [state]);
}

// returns all issues that have an hmbpSection metadata field, optionally filtered by the section
export function useHmbpIssues(
  section?: HmbpSectionWithoutRegulatory
): HmbpIssue[] {
  const reportIssues = useReportIssues();
  return useMemo(
    () =>
      reportIssues
        // filter out facility documents because these will show up as duplicates otherwise
        .filter((issue) => issue.key !== "facilityDocuments")
        .filter((issue) =>
          isHmbpIssue(issue, { section, omit: [HmbpSection.Regulatory] })
        ),
    [reportIssues, section]
  );
}

// returns all issues belonging to the Regulatory section
export function useHmbpRegulatoryIssues(): HmbpIssue[] {
  const reportIssues = useReportIssues();
  return useMemo(
    () =>
      reportIssues.filter((issue) =>
        isHmbpIssue(issue, { section: HmbpSection.Regulatory })
      ),
    [reportIssues]
  );
}

// returns all dynamic fields that have an hmbpSection metadata field, optionally filtered by the section
export function useHmbpDynamicFields(
  section?: HmbpSectionWithoutRegulatory
): HmbpDynamicField[] {
  const reportFields = useReportDynamicFields();
  return useMemo(
    () =>
      reportFields.filter((field) =>
        isHmbpDynamicField(field, { section, omit: [HmbpSection.Regulatory] })
      ),
    [reportFields, section]
  );
}

// returns the answer to the documentOptions question for a given document type
export function useHmbpDocumentOptions(
  documentType?: DocumentType | null
): DocumentOption | undefined {
  const section = documentType
    ? hmbpSectionFromDocumentType[documentType]
    : undefined;
  const fields = useHmbpDynamicFields(section);
  if (!section) return;
  const field = fields.find((field) => field.key.includes("DocumentOption"));
  if (!field) return;
  return field.value as DocumentOption;
}

// This doesn't get used for USTs due to the need for UI spacing
export function useShouldShowSection(title: HmbpTitle) {
  const emergencyResponseAndTrainingPlans = [
    useGuidance(HmbpSection.EmergencyResponseContingencyPlan) !== "None",
    useGuidance(HmbpSection.EmployeeTrainingPlan) !== "None",
  ].some(Boolean);
  const recyclableMaterialsReport =
    useGuidance(HmbpSection.RecyclableMaterialsReportDocumentation) !== "None";
  const hazardousWasteTankClosureCertificate =
    useGuidance(HmbpSection.HazardousWasteTankClosureCertificate) !== "None";
  const abovegroundPetroleumStorageAct = [
    useGuidance(HmbpSection.APSAFacilityInformation) !== "None",
    useGuidance(HmbpSection.AbovegroundPetroleumStorageActDocumentation) !==
      "None",
  ].some(Boolean);

  switch (title) {
    case HmbpTitle.FacilityInformation:
      return true;
    case HmbpTitle.EmergencyResponseAndTrainingPlans:
      return emergencyResponseAndTrainingPlans;
    case HmbpTitle.RecyclableMaterialsReport:
      return recyclableMaterialsReport;
    case HmbpTitle.HazardousWasteTankClosureCertification:
      return hazardousWasteTankClosureCertificate;
    case HmbpTitle.AbovegroundPetroleumStorageAct:
      return abovegroundPetroleumStorageAct;
    default:
      return true;
  }
}

type Guidance = "Recommended" | "Required" | "Optional" | "None";

export function useGuidance(section: HmbpSectionWithoutRegulatory): Guidance {
  const stateFields = useReportDynamicFields();
  const usTanks = useHmbpUSTanks();
  const docOptions = {
    emergencyResponse: "emergencyResponseDocumentOption",
    employeeTraining: "trainingPlanDocumentOption",
    ownerStatementOfDesignatedUSTOperatorCompliance:
      "ownerStatementOfDesignatedUSTOperatorComplianceDocumentOption",
    ustMonitoringSitePlan: "ustMonitoringSitePlanDocumentOption",
    ustCertificationOfFinancialResponsibility:
      "ustCertificationOfFinancialResponsibilityDocumentOption",
    ustResponsePlan: "ustResponsePlanDocumentOption",
    ustOwnerOperatorWrittenAgreement:
      "ustOwnerOperatorWrittenAgreementDocumentOption",
    ustLetterFromChiefFinancialOfficer:
      "ustLetterFromChiefFinancialOfficerDocumentOption",
    recyclableMaterialsReport: "recyclableMaterialsReportDocumentOption",
    hazardousWasteTankClosureCertificate:
      "hazardousWasteTankClosureCertificateDocumentOption",
    apsaDocumentation: "apsaDocumentationDocumentOption",
  };

  switch (section) {
    case HmbpSection.FacilityInformation:
      return "Required";
    case HmbpSection.SitePlan:
      return "Required";
    case HmbpSection.EmergencyResponseContingencyPlan:
      return mapToGuidance(
        {
          hazardousMaterialsOnSite: "Required",
          [docOptions.emergencyResponse]: "Optional",
        },
        stateFields
      );
    case HmbpSection.EmployeeTrainingPlan:
      return mapToGuidance(
        {
          hazardousMaterialsOnSite: "Required",
          [docOptions.employeeTraining]: "Optional",
        },
        stateFields
      );
    case HmbpSection.USTFacilityOperatingPermitApplication:
      return mapToGuidance(
        {
          hasUST: "Required",
        },
        stateFields
      );
    case HmbpSection.USTMonitoringSitePlan:
      return mapToGuidance(
        {
          hasUST: "Recommended",
          [docOptions.ustMonitoringSitePlan]: "Optional",
        },
        stateFields
      );
    case HmbpSection.USTCertificationOfFinancialResponsibility:
      if (
        stateFields.find((field) => field.key === "hasUST")?.value &&
        usTanks
          .map((tank) => tank.tankContents)
          .some((content) =>
            [
              USTContents["Regular Unleaded"],
              USTContents["Premium Unleaded"],
              USTContents["Midgrade Unleaded"],
              USTContents["Diesel"],
              USTContents["Jet Fuel"],
              USTContents["Aviation Gas"],
              USTContents["Other Petroleum"],
            ].includes(content as USTContents)
          )
      ) {
        return "Recommended";
      }
      return mapToGuidance(
        {
          [docOptions.ustCertificationOfFinancialResponsibility]: "Optional",
        },
        stateFields
      );
    case HmbpSection.USTResponsePlan:
      return mapToGuidance(
        {
          hasUST: "Recommended",
          [docOptions.ustResponsePlan]: "Optional",
        },
        stateFields
      );
    case HmbpSection.USTOwnerOperatorWrittenAgreement:
      if (
        stateFields.find((field) => field.key === "hasUST")?.value &&
        [
          CA_UstPermitHolderInfo[CA_UstPermitHolderInfo["Facility Owner"]],
          CA_UstPermitHolderInfo[CA_UstPermitHolderInfo["Facility Operator"]],
          CA_UstPermitHolderInfo[CA_UstPermitHolderInfo["Tank Owner"]],
        ].includes(
          stateFields.find((field) => field.key === "ustPermitHolderInfo")
            ?.value
        )
      ) {
        return "Recommended";
      }
      return mapToGuidance(
        {
          [docOptions.ustOwnerOperatorWrittenAgreement]: "Optional",
        },
        stateFields
      );
    case HmbpSection.USTLetterFromChiefFinancialOfficer:
      if (
        stateFields.find((field) => field.key === "hasUST")?.value &&
        (
          stateFields.find(
            (field) =>
              field.key === "ustPermitFinancialResponsibilityMechanisms"
          )?.value ?? []
        ).some((value: string) =>
          [
            CA_FinancialResponsibilityMechanisms[
              CA_FinancialResponsibilityMechanisms["Self-Insured"]
            ],
            CA_FinancialResponsibilityMechanisms[
              CA_FinancialResponsibilityMechanisms["Guarantee"]
            ],
            CA_FinancialResponsibilityMechanisms[
              CA_FinancialResponsibilityMechanisms["State Fund and CFO Letter"]
            ],
            CA_FinancialResponsibilityMechanisms[
              CA_FinancialResponsibilityMechanisms["State Fund and CD"]
            ],
            CA_FinancialResponsibilityMechanisms[
              CA_FinancialResponsibilityMechanisms["Local Government Mechanism"]
            ],
          ].includes(value)
        )
      ) {
        return "Recommended";
      }
      return mapToGuidance(
        {
          [docOptions.ustLetterFromChiefFinancialOfficer]: "Optional",
        },
        stateFields
      );
    case HmbpSection.OwnerStatementOfDesignatedUSTOperatorCompliance:
      return mapToGuidance(
        {
          hasUST: "Recommended",
          [docOptions.ownerStatementOfDesignatedUSTOperatorCompliance]:
            "Optional",
        },
        stateFields
      );
    case HmbpSection.RecyclableMaterialsReportDocumentation:
      return mapToGuidance(
        {
          hasExcludedExemptedMaterials: "Required",
          [docOptions.recyclableMaterialsReport]: "Optional",
        },
        stateFields
      );
    case HmbpSection.HazardousWasteTankClosureCertificate:
      return mapToGuidance(
        {
          hazardousWasteTankClosureRemoval: "Required",
          [docOptions.hazardousWasteTankClosureCertificate]: "Optional",
        },
        stateFields
      );
    case HmbpSection.APSAFacilityInformation:
      return mapToGuidance(
        {
          hasAPS: "Required",
        },
        stateFields
      );
    case HmbpSection.AbovegroundPetroleumStorageActDocumentation:
      return mapToGuidance(
        {
          hasAPS: "Required",
          [docOptions.apsaDocumentation]: "Optional",
        },
        stateFields
      );
    default:
      return "None";
  }
}

function mapToGuidance(
  mapping: Record<string, Guidance>,
  stateFields: DynamicField[]
) {
  for (const [key, guidance] of Object.entries(mapping)) {
    if (stateFields.find((field) => field.key === key)?.value) {
      return guidance;
    }
  }
  return "None";
}

// returns all dynamic fields belonging to the Regulatory section
export function useHmbpRegulatoryDynamicFields(): HmbpDynamicField[] {
  const reportFields = useReportDynamicFields();
  return useMemo(
    () =>
      reportFields.filter((field) =>
        isHmbpDynamicField(field, { section: HmbpSection.Regulatory })
      ),
    [reportFields]
  );
}

export function useHmbpUSTanks(): UndergroundStorageTank[] {
  const { data } = useReport();
  return data?.tierIIReport.facility.undergroundStorageTanks ?? [];
}

export function useHmbpUSTankIssues(
  section: HmbpUndergroundStorageTankSection,
  modelId: string
): Issue[] {
  return useReportIssues().filter(
    (issue) =>
      isHmbpIssue(issue, { section, omit: [HmbpSection.Regulatory] }) &&
      issue.modelId === modelId
  );
}

export function useHmbpUSTankStatus(
  section: HmbpUndergroundStorageTankSection,
  modelId: string
): ReportStepState {
  const issues = useHmbpUSTankIssues(section, modelId);
  const { data } = useReport();

  const touchedSection = data?.tierIIReport.undergroundStorageTankStatuses
    ?.find(
      ({ undergroundStorageTankId }) => undergroundStorageTankId === modelId
    )
    ?.touchedSteps.includes(section);

  if (issues.length) {
    return ReportStepState.Invalid;
  }

  if (touchedSection) {
    return ReportStepState.Done;
  }

  return ReportStepState.NotStarted;
}

// A shared validator to pass to useValidatingForm that will validate the facility state fields
export function useHmbpValidator(section: HmbpSectionWithoutRegulatory) {
  const { data } = useReport();

  const validateFacility = useFacilityInputValidation({
    facilityId: data?.tierIIReport.facility.id ?? "",
    reportId: data?.tierIIReport.id,
    pick: ["stateFields"],
  });

  const validateDocument = useDocumentInputValidation();

  return useCallback(
    async (facilityStateFields: DynamicField[], uploads?: UploadFile[]) => {
      const issues = await validateFacility({
        fireDepartmentId: data?.tierIIReport.facility.fireDepartmentId,
        lepcId: data?.tierIIReport.facility.lepcId,
        state: data?.tierIIReport.facility.state,
        stateFields: facilityStateFields.map((field) => ({
          ...field,
          type: ProgramArea.Epcra,
        })),
      });
      const documentIssues = await Promise.all(
        (uploads ?? []).map(async (upload) => {
          return await validateDocument({
            id: upload.document?.id,
            title: upload.document?.title ?? upload.file?.name,
            authoredAt: upload.document?.authoredAt,
            fileExtension: upload.document?.fileExtension ?? upload.extension,
            documentType: hmbpSectionToDocumentType[section],
            storageLink: upload.document?.storageLink,
            currentSubmissionDocument:
              upload.document?.currentSubmissionDocument,
            facilities: [
              {
                id: data?.tierIIReport.facility.id ?? "",
                name: data?.tierIIReport.facility.name ?? "",
              },
            ],
          });
        })
      );
      issues.push(
        ...documentIssues
          .map((issues, index) =>
            issues.map((issue) => ({
              ...issue,
              key: `upload.${index}.document.${issue.key}`,
            }))
          )
          .flat()
      );
      return issues.filter(
        (issue) =>
          issue.modelName === "Document" || isHmbpIssue(issue, { section })
      );
    },
    [
      data?.tierIIReport.facility.fireDepartmentId,
      data?.tierIIReport.facility.id,
      data?.tierIIReport.facility.lepcId,
      data?.tierIIReport.facility.name,
      data?.tierIIReport.facility.state,
      section,
      validateDocument,
      validateFacility,
    ]
  );
}

const UPDATE_FACILITY_STATE_FIELDS = gql(`
  mutation UpdateFacilityStateFields($facilityId: ID!, $stateFields: [DynamicFieldInput!]!) {
    updateFacilityStateFields(facilityId: $facilityId, stateFields: $stateFields) {
      id
    }
  }
`);
export function useCAStepStatus(step: TierIiReportCaStep): ReportStepState {
  const { data } = useReport();
  const issues = useHmbpIssues(step as unknown as HmbpSectionWithoutRegulatory);
  if (!data) return ReportStepState.NotStarted;
  return getCAStepStatus(step, data.tierIIReport, issues);
}

function getCAStepStatus(
  step: TierIiReportCaStep,
  report: Report,
  issues: Issue[]
): ReportStepState {
  return issues?.length
    ? ReportStepState.Invalid
    : report.touchedCASteps?.includes(step)
    ? ReportStepState.Done
    : ReportStepState.NotStarted;
}

// A shared mutation for saving state fields
export function useHmbpStateFieldMutation() {
  const { data } = useReport();
  const hmbpFields = useHmbpDynamicFields();

  const [updateFacilityStateFields, { loading: submitting }] = useMutation(
    UPDATE_FACILITY_STATE_FIELDS
  );

  // We'll want to touch the report on any change
  // TODO: we'll probably want to do something similar for the HMBP steps.
  // Maybe useTouchReportMutation can accept an optional hmbp step to touch?
  const { handleSave: handleTouch, loading: touchLoading } =
    useTouchReportMutation(
      data?.tierIIReport.id ?? "",
      data?.tierIIReport.touchedSteps ?? [],
      TierIiReportStep.StateInformation,
      false // don't navigate to overview after saving state fields
    );

  const mutation = useCallback(
    async (stateFields: DynamicField[]) => {
      if (!data) return;

      await updateFacilityStateFields({
        variables: {
          facilityId: data.tierIIReport.facility.id,
          stateFields: stateFields.map(transformStateField),
        },
        awaitRefetchQueries: true,
      });
      await handleTouch();
    },
    [data, handleTouch, updateFacilityStateFields]
  );

  const setDocumentOptionToUpload = useCallback(
    async (documentType: DocumentType) => {
      const section = hmbpSectionFromDocumentType[documentType];
      if (!section) return;
      const stateFields = hmbpFields.filter((field) =>
        isHmbpDynamicField(field, { section })
      );
      await mutation(
        stateFields.map((field) => ({
          ...field,
          value: field.key.includes("DocumentOption")
            ? CA_DocumentOption[CA_DocumentOption["Upload"]]
            : null,
          type: ProgramArea.Epcra,
        }))
      );
    },
    [mutation, hmbpFields]
  );

  return useMemo(
    () => ({
      mutation,
      setDocumentOptionToUpload,
      loading: submitting || touchLoading,
    }),
    [mutation, submitting, touchLoading, setDocumentOptionToUpload]
  );
}

const REMOVE_UNDERGROUND_STORAGE_TANK = gql(`
  mutation RemoveUndergroundStorageTank($id: ID!) {
    removeUndergroundStorageTank(id: $id) {
      ...undergroundStorageTank
    }
  }
`);

export const useRemoveUndergroundStorageTank = () => {
  const alerts = useAlerts();
  const [mutate, { loading }] = useMutation(REMOVE_UNDERGROUND_STORAGE_TANK);

  const remove = useCallback(
    async (id: string) => {
      try {
        await mutate({
          variables: { id },
          refetchQueries: [GET_REPORT],
        });
        alerts.success("UST removed successfully");
      } catch (error) {
        alerts.error("Failed to remove UST");
      }
    },
    [mutate, alerts]
  );

  return { remove, loading };
};

const UPSERT_UNDERGROUND_STORAGE_TANK = gql(`
  mutation UpsertUndergroundStorageTank($input: UndergroundStorageTankInput!) {
    upsertUndergroundStorageTank(input: $input) {
      ...undergroundStorageTank
    }
  }
`);

export const useUpsertUndergroundStorageTank = () => {
  const [mutate, { loading }] = useMutation(UPSERT_UNDERGROUND_STORAGE_TANK);

  const upsert = useCallback(
    async (data: UndergroundStorageTankInput) => {
      await mutate({
        variables: {
          input: transform({ ...data }),
        },
        refetchQueries: [GET_REPORT],
      });
    },
    [mutate]
  );
  return { upsert, loading };
};

const UNDERGROUND_STORAGE_TANK_FIELDS = gql(`
  query UndergroundStorageTankFields($jurisdictions: [String!]) {
    undergroundStorageTankFields(jurisdictions: $jurisdictions) {
      ...dynamicField
      group
      helperText
    }
  }
`);

export const useUndergroundStorageTankFields = (): DynamicField[] => {
  const { data: reportData, loading: reportLoading } = useReport();
  const jurisdictions = compact([
    reportData?.tierIIReport.facility.state,
    reportData?.tierIIReport.facility.lepcId,
    reportData?.tierIIReport.facility.fireDepartmentId,
  ]);
  const query = useQuery(UNDERGROUND_STORAGE_TANK_FIELDS, {
    variables: { jurisdictions },
    skip: reportLoading,
  });

  const { data } = query;
  return data?.undergroundStorageTankFields ?? [];
};

type UndergroundStorageTankDynamicField = HmbpDynamicField & {
  key: Path<UndergroundStorageTankInput>;
};

export function useUndergroundStorageTankDynamicFields(
  section: HmbpUndergroundStorageTankSection
): UndergroundStorageTankDynamicField[] {
  const fields = useUndergroundStorageTankFields()
    .filter((field) => isHmbpDynamicField(field, { section }))
    .map((field) => ({
      ...field,
      key: field.key as Path<UndergroundStorageTankInput>,
    }));
  return fields;
}
