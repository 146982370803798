import { useMutation } from "@apollo/client";
import Restore from "@mui/icons-material/Restore";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  ResetReportInput,
  ResetReportMutation,
  ResetReportMutationVariables,
} from "generated-graphql/graphql";
import { useCallback } from "react";
import { useAlerts } from "../../../../components/Alerts/AlertProvider";
import { useReportDetails } from "./useReportDetails";
import { gql } from "generated-graphql";

const RESET_REPORT = gql(`
  mutation ResetReport($inputs: ResetReportInput!) {
    resetReport(inputs: $inputs)
  }
`);
export const SubmitResetReportButton = (
  props: { disabled?: boolean; onSubmit: () => void } & ResetReportInput
) => {
  const {
    onSubmit,
    encampStatus,
    organizationStatus,
    reportId,
    safetyOverride,
    shouldForceCancelWorkflow,
    shouldForceReSnapshot,
    untouchedSteps,
  } = props;
  const { refetch } = useReportDetails();
  const alerts = useAlerts();
  const [resetReportMutation, { loading: isResettingReports }] = useMutation<
    ResetReportMutation,
    ResetReportMutationVariables
  >(RESET_REPORT, {
    onCompleted() {
      refetch();
      alerts.success(`Successfully reset report status.`);
    },
    onError(error) {
      alerts.error("We encountered an error resetting the report.", error);
    },
  });

  const resetReport = useCallback(async () => {
    await resetReportMutation({
      variables: {
        inputs: {
          encampStatus,
          organizationStatus,
          reportId,
          safetyOverride,
          untouchedSteps,
          shouldForceReSnapshot,
          shouldForceCancelWorkflow,
        },
      },
    });
    onSubmit();
  }, [
    resetReportMutation,
    encampStatus,
    organizationStatus,
    reportId,
    safetyOverride,
    untouchedSteps,
    onSubmit,
  ]);

  return (
    <ResetReportButton
      loading={isResettingReports}
      onClick={resetReport}
      disabled={props.disabled}
      variant="contained"
    />
  );
};

type Props = Pick<
  LoadingButtonProps,
  "onClick" | "loading" | "disabled" | "variant" | "fullWidth"
>;
export const ResetReportButton = (props: Props) => {
  return (
    <LoadingButton
      variant={props.variant ?? "outlined"}
      size="small"
      color="info"
      loadingPosition="start"
      startIcon={<Restore />}
      {...props}
    >
      Reset report
    </LoadingButton>
  );
};
