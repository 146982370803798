import { Box, Typography, useTheme } from "@mui/material";
import OpenInNew from "@mui/icons-material/OpenInNew";
import MenuBook from "@mui/icons-material/MenuBook";
import Science from "@mui/icons-material/Science";
import { Issue, Permission } from "generated-graphql/graphql";
import pluralize from "pluralize";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { IssueCount } from "components/IssueCount";
import { brandColors } from "../../util";
import { useAuthorization } from "hooks/useAuthorization";

export const BaseLink = ({
  linkContent,
  link,
  issueCount,
  openInNew = true,
  hasIgnoredIssues = false,
}: {
  linkContent: React.ReactNode;
  link: string;
  issueCount: number;
  openInNew?: boolean;
  hasIgnoredIssues?: boolean;
}) => {
  const theme = useTheme();
  const color =
    issueCount === 0 || hasIgnoredIssues
      ? theme.palette.text.secondary
      : theme.palette.error.main;
  const backgroundColor =
    issueCount === 0 || hasIgnoredIssues
      ? brandColors["encamp-blue"][50]
      : brandColors["encamp-red"][50];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        px: 2,
        py: 1,
        mt: 2,
        whiteSpace: "pre",
        backgroundColor,
        color,
      }}
    >
      {linkContent}
      <Link
        to={link}
        target={openInNew ? "_blank" : "_self"}
        rel="noopener noreferrer"
        style={{ color }}
      >
        {openInNew && (
          <OpenInNew
            fontSize="small"
            sx={{
              verticalAlign: "middle",
              fontSize: "inherit",
            }}
          />
        )}
      </Link>
    </Box>
  );
};
