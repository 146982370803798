export enum CaSections {
  FacilityInformation = "FacilityInformation",
  HazardousMaterialsInventory = "HazardousMaterialsInventory",
  EmergencyResponseAndTrainingPlan = "EmergencyResponseAndTrainingPlan",
  UndergroundStorageTanks = "UndergroundStorageTanks",
  TieredPermitting = "TieredPermitting",
  RecyclableMaterialsReport = "RecyclableMaterialsReport",
  RemoteWasteConsolidationSiteAnnualNotification = "RemoteWasteConsolidationSiteAnnualNotification",
  HazardousWasteTankClosureCertification = "HazardousWasteTankClosureCertification",
  AbovegroundPetroleumStorageAct = "AbovegroundPetroleumStorageAct",
  CaliforniaAccidentalReleaseProgram = "CaliforniaAccidentalReleaseProgram",
}

export const SectionIds: Record<CaSections, string> = {
  [CaSections.FacilityInformation]: "1",
  [CaSections.HazardousMaterialsInventory]: "2",
  [CaSections.EmergencyResponseAndTrainingPlan]: "3",
  [CaSections.UndergroundStorageTanks]: "4",
  [CaSections.TieredPermitting]: "5",
  [CaSections.RecyclableMaterialsReport]: "6",
  [CaSections.RemoteWasteConsolidationSiteAnnualNotification]: "7",
  [CaSections.HazardousWasteTankClosureCertification]: "8",
  [CaSections.AbovegroundPetroleumStorageAct]: "9",
  [CaSections.CaliforniaAccidentalReleaseProgram]: "10",
};

export type SectionIdValues = typeof SectionIds[keyof typeof SectionIds];

export const SectionLabels: Record<CaSections, string> = {
  [CaSections.FacilityInformation]: "Facility Information",
  [CaSections.HazardousMaterialsInventory]: "Hazardous Materials Inventory",
  [CaSections.EmergencyResponseAndTrainingPlan]:
    "Emergency Response and Training Plans",
  [CaSections.UndergroundStorageTanks]: "Underground Storage Tanks",
  [CaSections.TieredPermitting]: "Tiered Permitting",
  [CaSections.RecyclableMaterialsReport]: "Recyclable Materials Report",
  [CaSections.RemoteWasteConsolidationSiteAnnualNotification]:
    "Remote Waste Consolidation Site Annual Notification",
  [CaSections.HazardousWasteTankClosureCertification]:
    "Hazardous Waste Tank Closure Certification",
  [CaSections.AbovegroundPetroleumStorageAct]:
    "Aboveground Petroleum Storage Act",
  [CaSections.CaliforniaAccidentalReleaseProgram]:
    "California Accidental Release Program",
};
