import { Paper, Stack, Typography, useTheme } from "@mui/material";
import { DocumentList } from "./DocumentList";

export function Documents() {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        padding: theme.spacing(2),
        width: "100%",
      }}
    >
      <Stack spacing={theme.spacing(2)}>
        <Typography variant="h5">Documents</Typography>
        <DocumentList />
      </Stack>
    </Paper>
  );
}
