import { useQuery } from "@apollo/client";
import Info from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridRowSelectionModel,
} from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useRequeueActivities } from "hooks/activities/requeue";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";
import { compact } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { DataGrid } from "../../../../components/DataGrid";
import {
  Filter,
  Omnisearch,
} from "../../../../components/Omnisearch/OmnisearchV2";
import { SpinnerOverlay } from "../../../../components/SpinnerOverlay";
import { UserSelectDialog } from "../../../../components/UserSelectDialog";
import {
  ExecutionPlanAutomated,
  ExecutionPlanManual,
} from "../../../../components/icons";
import { gql } from "../../../../generated-graphql";
import {
  ActivityStatus,
  ActivityType,
  ExecutionPlanType,
  TierIiReportActivitiesInErrorQuery,
} from "../../../../generated-graphql/graphql";
import { useUpdateActivities } from "../../../../hooks/activities";
import { useDebounce } from "../../../../hooks/useDebounce";

type Row =
  TierIiReportActivitiesInErrorQuery["tierIIReportActivitiesInError"]["items"][0];

const REPORTS_IN_ERROR_QUERY = gql(`
  query TierIIReportActivitiesInError($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReportActivitiesInError(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        tierIIReportId
        reportingYear
        productCount
        organizationStatus
        encampStatus
        state
        facilityName
        organizationName
        workflowId

        activityId
        activityType
        activityDescription
        activityAssigneeEmail
        activityAssigneeFirst
        activityAssigneeLast
        activityExecutionPlan
        activityStatus
        activityErrorMessage
      }
      count
    }
  }
`);

export function ReportErrorTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isAssigning, setIsAssigning] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const {
    columns,
    omnisearch,
    setOmnisearch,
    sortModel,
    paginationModel,
    setPaginationModel,
    setSortModel,
    columnFilterKeys,
  } = useOmnisearchDatagrid({
    columns: [
      {
        field: "organizationName",
        headerName: "Organization",
        flex: 1,
      },
      {
        field: "facilityName",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 1,
      },
      {
        field: "state",
        headerName: "State",
        flex: 1,
      },
      {
        field: "activityExecutionPlan",
        renderCell: (params) => {
          const executionPlan = params.row.activityExecutionPlan;

          if (!executionPlan) {
            return "";
          }

          return executionPlan === ExecutionPlanType.Automation ? (
            <Tooltip title={executionPlan}>
              <ExecutionPlanAutomated />
            </Tooltip>
          ) : (
            <Tooltip title={executionPlan}>
              <ExecutionPlanManual />
            </Tooltip>
          );
        },
        headerName: "Plan",
        flex: 1,
      },
      {
        field: "activityErrorMessage",
        headerName: "Error Message",
        flex: 1,
      },
      {
        field: "productCount",
        headerName: "Number of Chemicals",
        flex: 1,
        valueGetter: (params) => {
          if (params.row.productCount == null) return "N/A";
          return params.row.productCount;
        },
      },
      {
        field: "activityDescription",
        valueGetter: (params) => {
          if (!params.row.activityType) return "N/A";
          return params.row.activityDescription
            ? `${params.row.activityType}: ${params.row.activityDescription}`
            : `${params.row.activityType}`;
        },
        headerName: "Activities",
        flex: 1,
      },
      {
        field: "activityAssigneeEmail",
        headerName: "Assignee",
        flex: 1,
      },
      {
        headerName: "More Info",
        field: "moreInfo",
        type: "actions",
        getActions: (params) => {
          const actions = [];
          if (params.row.tierIIReportId) {
            actions.push(
              <Tooltip title="View Submission" key={1}>
                <GridActionsCellItem
                  onClick={() => viewReport(params.row.tierIIReportId ?? "")}
                  label="View Submission"
                  icon={<Info />}
                />
              </Tooltip>
            );
          }
          return actions;
        },
      },
    ],
  });

  // scroll the data grid into view when the omnisearch filters change
  const dataGridRef = useRef<HTMLDivElement>(null);
  const [knownFilter, setKnownFilter] = useState<Partial<Filter>>({});

  useEffect(() => {
    if (dataGridRef.current && omnisearch) {
      dataGridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [setKnownFilter, knownFilter, omnisearch]);

  const [debouncedSearch] = useDebounce(omnisearch, 1000);

  const { data, error, loading, previousData } = useQuery(
    REPORTS_IN_ERROR_QUERY,
    {
      fetchPolicy: "network-only",
      variables: {
        search: debouncedSearch,
        pageSize: paginationModel.pageSize,
        sort: [...sortModel],
      },
    }
  );

  const [updateActivities, { loading: isUpdatingActivities }] =
    useUpdateActivities();

  const [selectedRows, setSelectedRows] = useState<Row[]>([]);

  useEffect(() => {
    setSelectedRows(
      compact(
        rowSelectionModel.map((id) => {
          return data?.tierIIReportActivitiesInError.items.find(
            (item) => item.id === id
          );
        })
      )
    );
  }, [rowSelectionModel, data]);

  const handleAssignUser = useCallback(
    async (userId: string) => {
      const activityIds = compact(selectedRows.map((row) => row.activityId));

      await updateActivities({
        variables: {
          input: {
            assigneeId: userId,
          },
          ids: activityIds,
          allowPartialFailure: true,
        },
      });
      setIsAssigning(false);
    },
    [updateActivities, selectedRows]
  );

  const handleUnassignUser = useCallback(async () => {
    const activityIds = compact(selectedRows.map((row) => row.activityId));

    await updateActivities({
      variables: {
        input: {
          assigneeId: null,
        },
        ids: activityIds,
        allowPartialFailure: true,
      },
    });
  }, [updateActivities, selectedRows]);

  const viewReport = useCallback(
    (id?: string) => {
      id && navigate(`/staff/fulfillment/${id}`);
    },
    [navigate]
  );

  const handleSetActivitiesExecutionPlan = useCallback(
    async (executionPlan: ExecutionPlanType) => {
      const activityIds = compact(selectedRows.map((row) => row.activityId));

      await updateActivities({
        variables: {
          ids: activityIds,
          input: {
            executionPlan,
          },
          allowPartialFailure: true,
        },
      });
    },
    [updateActivities, selectedRows]
  );

  const alerts = useAlerts();

  const [requeueActivities, { loading: isRequeueing }] = useRequeueActivities();

  const handleRequeueClick = useCallback(async () => {
    const activityIds = compact(selectedRows.map((row) => row.activityId));
    const result = await requeueActivities({ variables: { activityIds } });
    if (activityIds.length === result.data?.requeueActivities?.updatedCount) {
      alerts.success("Successfully requeued activities!");
    } else if (result.data?.requeueActivities?.updatedCount === 0) {
      alerts.error("No activities were requeued.");
    } else {
      alerts.info(
        "Some activities were requeued, but some failed. Refresh to investigate"
      );
    }
  }, [selectedRows, requeueActivities, alerts]);

  const shouldDisableSetExecutionPlanButton = useMemo(() => {
    return !selectedRows.every(
      (row) => row.activityType === ActivityType.Geppetto
    );
  }, [selectedRows]);

  const shouldDisableRequeueButton = useMemo(() => {
    return !selectedRows.every(
      (row) =>
        row.activityStatus === ActivityStatus.Error &&
        row.activityType === ActivityType.Geppetto
    );
  }, [selectedRows]);

  if (error) throw error;

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          padding: 3,
          flexDirection: "column",
          position: "relative",
          border: `${theme.palette.divider} solid 1px`,
        }}
      >
        <Typography variant="h6">Error Assignment</Typography>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          sx={{ marginTop: "1rem", marginBottom: "2rem" }}
        >
          <Omnisearch
            omnisearch={omnisearch}
            setOmnisearch={setOmnisearch}
            columnFilterKeys={columnFilterKeys}
          />
          {!!rowSelectionModel.length && (
            <>
              <Button
                variant="outlined"
                onClick={() => setIsAssigning(true)}
                disabled={isUpdatingActivities}
              >
                Assign
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleUnassignUser()}
                disabled={isUpdatingActivities}
              >
                Unassign
              </Button>
              <LoadingButton
                variant="outlined"
                onClick={handleRequeueClick}
                disabled={shouldDisableRequeueButton}
                loading={isRequeueing}
              >
                Requeue
              </LoadingButton>
              <Tooltip
                title={
                  shouldDisableSetExecutionPlanButton
                    ? "You can only change the execution plan for Geppetto activities."
                    : ""
                }
              >
                {/* This span is necessary so that the tooltip wrapping the button will work with the button disabled */}
                <span>
                  <Button
                    variant="outlined"
                    disabled={shouldDisableSetExecutionPlanButton}
                    onClick={() =>
                      handleSetActivitiesExecutionPlan(ExecutionPlanType.Human)
                    }
                  >
                    Set Execution Plan to {ExecutionPlanType.Human}
                  </Button>
                </span>
              </Tooltip>
            </>
          )}
        </Stack>
        <Box position="relative" sx={{ height: "80vh" }}>
          <DataGrid
            innerRef={dataGridRef}
            sx={{
              minHeight: "500px",
              opacity:
                loading && previousData
                  ? theme.palette.action.disabledOpacity
                  : 1,
            }}
            checkboxSelection
            columnBuffer={10}
            loading={loading}
            rowCount={data?.tierIIReportActivitiesInError.count ?? 0}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            onRowClick={({ row }) => {
              viewReport(row.tierIIReportId);
            }}
            onRowSelectionModelChange={(newModel) =>
              setRowSelectionModel(newModel)
            }
            columns={columns}
            initialState={{
              pinnedColumns: {
                right: ["actions"],
              },
            }}
            rows={
              data?.tierIIReportActivitiesInError.items ??
              previousData?.tierIIReportActivitiesInError.items ??
              []
            }
          />
          <SpinnerOverlay loading={loading} />
        </Box>
        <UserSelectDialog
          open={isAssigning}
          onSelect={handleAssignUser}
          onClose={() => setIsAssigning(false)}
          title="Assign Error to User"
        />
      </Paper>
      <Outlet />
    </>
  );
}
