import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Delete from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CopyableText } from "components/CopyableText";
import { DataGrid } from "components/DataGrid";
import { Dialog } from "components/Dialog";
import { CheckboxField } from "components/Forms/CheckboxField";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { SkeletonFormGroup } from "components/Skeleton";
import { gql } from "generated-graphql";
import {
  Credential,
  CredentialInputForValidation,
  CredentialKind,
  GetCredentialQuery,
  InventoryOverviewQuery,
} from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useTenant } from "hooks/useTenant";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CredentialPortalUrl } from "routes/Staff/Fulfillment/ReportDetails/CredentialPortalUrl";
import { credentialKindToLabel } from "util/constants";
import { hasCriticalIssues } from "util/forms";
import { CredentialVerification } from "../../Staff/Fulfillment/ReportDetails/CredentialVerification";
import { useCredentialInputValidation } from "../Chemicals/Report/validationHooks/useCredentialInputValidation";
import { EditablePasswordField } from "./EditablePasswordField";
import { SelectFacilitiesDialog } from "./SelectFacilitiesDialog";
import { GET_CREDENTIAL, UPSERT_CREDENTIAL } from "./schema";

const GET_ASSOCIATED_FACILITIES = gql(`
  query GetAssociatedFacilities($search: String) {
    facilities(search: $search) {
      items {
        id
        name
        streetAddress1
        city
        state
        zip
      }
    }
  }`);

type CredentialFacility = NonNullable<
  GetCredentialQuery["credential"]["facilities"]
>[number];

interface CredentialDialogProps {
  onClose: () => void;
  mode?: "add" | "edit";
  credentialId?: string;
  currentFacility?: InventoryOverviewQuery["facility"];
}

export const CredentialDialog: React.FC<CredentialDialogProps> = ({
  onClose,
  mode,
  credentialId,
  currentFacility,
}) => {
  const theme = useTheme();
  const alerts = useAlerts();
  const { tenantId } = useTenant();
  const { isStaff } = useCurrentUser();
  const navigate = useNavigate();
  const addingCredential = mode === "add";

  const { data, loading, previousData } = useQuery(GET_CREDENTIAL, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: credentialId ?? "",
    },
    skip: !credentialId,
  });

  const [upsertCredential, { loading: isUpserting }] = useMutation(
    UPSERT_CREDENTIAL,
    {
      refetchQueries: ["Credentials"],
    }
  );

  useEffect(() => {
    if (!loading && !data && !previousData && credentialId) {
      // not-found is a non existent route that will be caught by the wildcard route handler
      // and sent to the NotFound page. See apps/oak/src/main.tsx
      navigate("/not-found");
    }
  }, [loading, data, previousData, credentialId, navigate]);

  const defaultValues: CredentialInputForValidation = useMemo(() => {
    const facilityIds = data?.credential?.facilities?.map((f) => f.id) ?? [];

    if (addingCredential && currentFacility) {
      facilityIds.push(currentFacility.id);
    }

    return {
      id: data?.credential?.id ?? undefined,
      name: data?.credential?.name ?? "",
      username: data?.credential?.username ?? "",
      password: data?.credential?.password ?? "",
      kind: data?.credential?.kind ?? CredentialKind.Tierii,
      email: data?.credential?.email,
      usesCombinedInvoicing: data?.credential?.usesCombinedInvoicing ?? false,
      facilityIds,
      tenantId,
    };
  }, [data, currentFacility, addingCredential, tenantId]);

  const form = useValidatingForm<CredentialInputForValidation>(
    defaultValues,
    data?.credential.issues ?? [],
    useCredentialInputValidation(addingCredential)
  );

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    issues,
    unregister,
    register,
    watch,
  } = form;

  const watchedFacilityIds = watch("facilityIds");

  // We don't need to update this because we disable the ability to copy after
  // the password has been edited. Generally, if you are editing the password
  // you shouldn't (tm) need to copy it.
  const passwordCopyValue = useMemo(() => {
    return data?.credential?.password ?? null;
  }, [data]);

  const [showPasswordCopyIcon, setShowPasswordCopyIcon] = useState(isStaff);

  useEffect(() => {
    if (!isStaff) {
      unregister("password");
    }
  }, [isStaff, unregister]);

  const [openSelectFacilitiesDialog, setOpenSelectFacilitiesDialog] =
    useState(false);

  const remove = useCallback(
    (index: number) => {
      const newFacilityIds = [...(watchedFacilityIds ?? [])];
      newFacilityIds.splice(index, 1);
      setValue("facilityIds", newFacilityIds, {
        shouldValidate: true,
      });
    },
    [setValue, watchedFacilityIds]
  );

  const onSubmit = async () => {
    // Create a Set from the array for faster lookup

    const input = {
      ...getValues(),
      tenantId: tenantId ?? "",
    };

    await upsertCredential({
      variables: { id: credentialId, input },
      onCompleted: () => {
        onClose();
        alerts.success("Credential saved successfully");
      },
      onError: (error) => {
        console.error(error);
        alerts.error("An error occurred while saving the Credential");
      },
    });
  };

  // Assumption being made here is that the collection of associated facilities all belong to the same portal jurisdiction.
  // Therefore, we can arbitrarily pick one to use when submitting a verification check.
  const anAssociatedFacility = useMemo(() => {
    if (!data?.credential?.facilities?.length) return;
    return data.credential.facilities[0];
  }, [data?.credential.facilities]);

  const savingOrLoading = loading || isUpserting;
  return (
    <>
      <Dialog open onClose={onClose} maxWidth="lg">
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {addingCredential ? "Add" : "Edit"} Credential
          <Button
            disabled={savingOrLoading}
            variant="contained"
            onClick={() => setOpenSelectFacilitiesDialog(true)}
          >
            ASSOCIATE FACILITIES
          </Button>
        </DialogTitle>
        <DialogContent sx={{ minHeight: 500, minWidth: 1200 }}>
          {loading && !previousData && (
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <SkeletonFormGroup count={4} />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Skeleton
                  variant="rounded"
                  sx={{ mb: theme.spacing(1) }}
                  height={350}
                />
              </Grid>
            </Grid>
          )}

          {(!loading || !!previousData) && (
            <FormProvider {...form}>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 0.5,
                }}
              >
                <>
                  <Grid
                    container
                    width={"100%"}
                    justifyContent="flex-end"
                  ></Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        name="name"
                        label="Name"
                        control={control}
                        textFieldProps={{ required: true }}
                      />
                      <FormTextField
                        sx={{ mt: 0.5 }}
                        disabled={savingOrLoading}
                        name="username"
                        label={
                          <>
                            {"Username"}
                            {isStaff && (
                              <CopyableText hideText>
                                {getValues("username")}
                              </CopyableText>
                            )}
                          </>
                        }
                        control={control}
                        textFieldProps={{ required: true }}
                      />
                      <EditablePasswordField
                        sx={{ mt: 0.5 }}
                        mode={mode}
                        name="password"
                        label={
                          <>
                            {"Password"}
                            {showPasswordCopyIcon && (
                              <CopyableText hideText>
                                {passwordCopyValue}
                              </CopyableText>
                            )}
                          </>
                        }
                        disabled={savingOrLoading}
                        onEditClick={() => {
                          // no need to show copy if we're editing the password
                          setShowPasswordCopyIcon(false);
                          register("password");
                          setValue("password", "");
                        }}
                        required
                      />
                      <FormSelect
                        sx={{ mt: 0.5 }}
                        disabled={savingOrLoading}
                        name="kind"
                        label="Type"
                        selectItems={Object.values(CredentialKind).map(
                          (kind) => ({
                            display: credentialKindToLabel(kind),
                            value: kind,
                          })
                        )}
                        control={control}
                        rules={{ required: true }}
                      />
                      <FormTextField
                        sx={{ mt: 0.5 }}
                        disabled={savingOrLoading}
                        control={control}
                        name="email"
                        label={
                          <>
                            {"Email"}
                            {isStaff && (
                              <CopyableText hideText>
                                {getValues("email")}
                              </CopyableText>
                            )}
                          </>
                        }
                      />
                      {isStaff &&
                        (currentFacility?.state === "WV" ||
                          data?.credential?.facilities?.some(
                            (f) => f.state === "WV"
                          )) && (
                          <CheckboxField
                            control={control}
                            name="usesCombinedInvoicing"
                            label="Uses Combined Invoicing (WV only)"
                          />
                        )}
                      {isStaff && (
                        <CredentialPortalUrl facility={anAssociatedFacility} />
                      )}
                      {isStaff && (
                        <CredentialVerification
                          disabled={!data?.credential.facilityCount}
                          credential={data?.credential as Credential}
                          facilityId={anAssociatedFacility?.id ?? ""}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <AssociatedFacilityGrid
                        facilityIds={watchedFacilityIds ?? []}
                        onRemove={remove}
                      />
                    </Grid>
                  </Grid>
                </>
              </Box>
            </FormProvider>
          )}
        </DialogContent>
        <DialogActions sx={{ pr: theme.spacing(2), pb: theme.spacing(2) }}>
          <Button onClick={onClose}>Cancel</Button>
          <SaveButton
            loading={savingOrLoading}
            saveText="Save"
            onClick={handleSubmit(onSubmit)}
            disabled={hasCriticalIssues(issues)}
          />
        </DialogActions>
      </Dialog>
      <SelectFacilitiesDialog
        key={watchedFacilityIds?.join(",") ?? ""}
        open={openSelectFacilitiesDialog}
        onCancel={() => setOpenSelectFacilitiesDialog(false)}
        onSave={(selectedFacilityIds) => {
          setValue("facilityIds", selectedFacilityIds, {
            shouldValidate: true,
          });
          setOpenSelectFacilitiesDialog(false);
        }}
        initialSelectedIds={watchedFacilityIds ?? []}
      />
    </>
  );
};

type FacilityGridProps = {
  facilityIds: string[];
  onRemove: (indexToRemove: number) => void;
};

const AssociatedFacilityGrid = ({
  facilityIds,
  onRemove,
}: FacilityGridProps) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  // copy the facilityIds prop so we can control when it is updated. otherwise,
  // every time the prop changes (when we delete facilities for example)
  // we will re run this query and cause an issue with not properly updating
  // the associated facilities DataGrid
  const [prevFacilityIdsLength, setPrevFacilityIdsLength] = useState(0);

  const [loadFacilities, { data, loading }] = useLazyQuery(
    GET_ASSOCIATED_FACILITIES
  );

  const facilities = useMemo(
    () =>
      (data?.facilities?.items ?? []).filter((f) => facilityIds.includes(f.id)),
    [data?.facilities?.items, facilityIds]
  );

  useEffect(() => {
    // if the number of facility ids has increased, we have added facilities
    // to our association list and need to fetch the new ones to show in this
    // datagrid.
    if (facilityIds.length > prevFacilityIdsLength) {
      (async () =>
        await loadFacilities({
          variables: {
            search: `ids:${(facilityIds ?? []).join(",")}`,
          },
        }))();
    }

    setPrevFacilityIdsLength(facilityIds.length);
  }, [prevFacilityIdsLength, facilityIds, loadFacilities]);

  const columns: GridColDef<CredentialFacility>[] = [
    { field: "name", headerName: "Associated Facility", flex: 1 },
    {
      field: "streetAddress1",
      headerName: "Street Address",
      flex: 1,
      valueGetter(params) {
        if (!params.row.streetAddress1) return "";

        const { streetAddress1, city, state, zip } = params.row;

        return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
      },
    },
    {
      field: "state",
      headerName: "State",
      flex: 0.2,
    },

    {
      field: "actions",
      type: "actions",
      flex: 0.1,
      getActions: (params) => {
        const index = facilityIds.findIndex((f) => f === params.row.id);
        return [
          <Tooltip title="Delete facility association" key={1}>
            <GridActionsCellItem
              label="Delete"
              onClick={() => onRemove(index)}
              icon={<Delete />}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <DataGrid
      loading={loading}
      rows={facilities}
      columns={columns}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[10, 25, 50]}
      rowCount={facilities.length}
    />
  );
};
