import {
  Checkbox,
  FormControl,
  FormControlLabel,
  SxProps,
} from "@mui/material";
import { useMemo } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";

type Props<T extends FieldValues> = {
  label: string | React.ReactNode;
  tooltip?: string;
  sx?: SxProps;
  staticErrorSpacing?: false;
  disabled?: boolean;
  checkboxProps?: Partial<React.ComponentProps<typeof Checkbox>>;
  formControlLabelProps?: Partial<
    React.ComponentProps<typeof FormControlLabel>
  >;
  noErrorDisplay?: boolean;
  fullWidth?: boolean;
} & UseControllerProps<T>;

export function CheckboxField<T extends FieldValues>(props: Props<T>) {
  const {
    label,
    tooltip,
    sx,
    staticErrorSpacing,
    disabled,
    checkboxProps,
    noErrorDisplay,
    fullWidth = true,
    ...controllerProps
  } = props;

  const labelWithTooltip = useMemo(
    () =>
      tooltip ? <TextWithTooltip text={label} tooltip={tooltip} /> : label,
    [label, tooltip]
  );

  return (
    <FormControl fullWidth={fullWidth} sx={{ display: "inline-block", ...sx }}>
      <Controller
        {...controllerProps}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <FormControlLabel
                {...props.formControlLabelProps}
                control={
                  <Checkbox
                    {...field}
                    {...checkboxProps}
                    value={field.value ?? false}
                    onChange={(e) => {
                      checkboxProps?.onChange?.(e, e.target.checked);
                      field.onChange(e.target.checked);
                    }}
                    checked={!!field.value}
                    disabled={disabled}
                  />
                }
                label={labelWithTooltip}
              />
              {!noErrorDisplay && (
                <ErrorDisplay
                  error={error}
                  staticSpacing={staticErrorSpacing}
                />
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
}
