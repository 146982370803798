import { getEPCRAContactRoles } from "../util/facilityContacts";
import { ContactReportRole } from "generated-graphql/graphql";

/**
 * Get the EPCRA facility contact role options for the state these facilities reside in
 */
export function useEpcraContactRoles(
  ...states: Array<string | null | undefined>
): {
  roles: ContactReportRole[];
} {
  const roles = getEPCRAContactRoles(...states);

  return { roles };
}
