import { TierIiReportStep } from "generated-graphql/graphql";
import { useRYTag, useTierIITag } from "hooks/useDocumentTags";
import { DocumentsGrid } from "routes/Customer/Documents/DocumentsGrid";
import { reportStepMetadata } from "util/constants";
import { ReportFloatingBar } from "../ReportFloatingBar";
import { StepperPageHeader } from "../StepperPageHeader";
import {
  GET_REPORT,
  useAllDocumentIssues,
  useReportFacilityDocumentIssues,
  useReport,
} from "../useReport";
import { useTouchReportMutation } from "../useTouchReportMutation";
import { useHmbpFeature } from "../useHmbp";

export const Documents = () => {
  const { data, previousData, loading } = useReport();
  const isHmbp = useHmbpFeature(data?.tierIIReport.facility.state ?? "");
  const { handleSave, loading: touchLoading } = useTouchReportMutation(
    data?.tierIIReport.id ?? "",
    data?.tierIIReport.touchedSteps ?? [],
    TierIiReportStep.Documents
  );
  const issues = useAllDocumentIssues();
  const facilityDocIssues = useReportFacilityDocumentIssues();

  const reportingYearTag = useRYTag(data?.tierIIReport.reportingYear);
  const tierIITag = useTierIITag();

  return (
    <>
      <StepperPageHeader
        header="Documents"
        description={
          isHmbp
            ? reportStepMetadata.DOCUMENTS.hmbpDescription
            : reportStepMetadata.DOCUMENTS.overviewDescription
        }
        mb={0}
      />
      <DocumentsGrid
        loading={loading}
        tenantId={data?.tierIIReport.tenantId}
        documents={
          data?.tierIIReport.documents ??
          previousData?.tierIIReport.documents ??
          []
        }
        facilityDocumentIssues={facilityDocIssues}
        facility={
          data?.tierIIReport.facility
            ? {
                id: data.tierIIReport.facility.id,
                name: data.tierIIReport.facility.name,
              }
            : undefined
        }
        reportId={data?.tierIIReport.id}
        refetchQueries={[GET_REPORT]}
        tags={[tierIITag, reportingYearTag]}
        deleteDocument={false}
      />
      <ReportFloatingBar
        loading={touchLoading}
        onSaveClick={handleSave}
        issues={issues}
        disabledWithAnyIssues
        saveText="Mark as Complete"
      />
    </>
  );
};
