import Add from "@mui/icons-material/Add";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { UserSelectDialog } from "components/UserSelectDialog";
import { useUpdateActivityAssignees } from "hooks/activities";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { useBulkActionContext } from ".";

export function AssignMenuItem() {
  const { selectedRows, setDialogState } = useBulkActionContext();

  const shouldDisable = useMemo(() => {
    return !selectedRows.every((row) => row.activityId);
  }, [selectedRows]);

  return (
    <MenuItem disabled={shouldDisable} onClick={() => setDialogState("assign")}>
      <ListItemIcon>
        <Add />
      </ListItemIcon>
      <ListItemText>Assign</ListItemText>
    </MenuItem>
  );
}

type AssignDialogProps = {
  activityIds: string[];
  onClose: () => void;
  open: boolean;
};

export function BulkAssignDialog() {
  const { selectedRows, onClose, dialogState } = useBulkActionContext();
  const activityIds = useMemo(
    () => compact(selectedRows.map((row) => row.activityId)),
    [selectedRows]
  );
  return (
    <AssignDialog
      open={dialogState === "assign"}
      activityIds={activityIds}
      onClose={onClose}
    />
  );
}

export function AssignDialog(props: AssignDialogProps) {
  const [updateActivityAssignees] = useUpdateActivityAssignees();
  const handleAssignUser = useCallback(
    async (userId: string) => {
      await updateActivityAssignees({
        variables: {
          assigneeId: userId,
          ids: props.activityIds,
        },
      });
      props.onClose();
    },
    [props, updateActivityAssignees]
  );
  return (
    <UserSelectDialog
      open={props.open}
      onSelect={handleAssignUser}
      onClose={props.onClose}
      title="Select an assignee"
    />
  );
}
