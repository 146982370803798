import { RefetchQueriesInclude, useApolloClient } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Alert, Box, Button, Stack, Tooltip, useTheme } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid-premium";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
  EditMode,
  FormState,
} from "components/AddEditDocumentDialog";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DataGrid, IssueMessageCell } from "components/DataGrid";
import { DocumentViewer } from "components/DocumentViewer";
import { gql } from "generated-graphql";
import {
  DocumentGridRowFragment,
  DocumentType,
  FacilityDocumentInput,
  Issue,
  TagInput,
} from "generated-graphql/graphql";
import {
  useDeleteDocumentMutation,
  useRemoveDocumentFromReportMutation,
} from "hooks/documents";
import { PaginationModel } from "hooks/usePaginationModel";
import { useMemo, useState } from "react";
import {
  CaliforniaDocumentTypes,
  DocumentTypes,
  documentTypeToLabel,
} from "util/constants";
import { prettyPrintDateMed } from "util/dates";
import { hmbpSectionFromDocumentType } from "encamp-shared/src/hmbp";
import { useHmbpFeature } from "../Chemicals/Report/useHmbp";
import { useReport } from "../Chemicals/Report/useReport";
import { useFacility } from "../Facility/useFacility";
import { HmbpDocumentForm } from "../Chemicals/Report/StateInformation/hmbpComponents/HmbpDocumentForm";
import { DateTime } from "luxon";

gql(`
  fragment DocumentGridRow on Document {
    id
    title
    fileExtension
    documentType
    storageLink
    currentSubmissionDocument
    createdAt
    authoredAt
    description
  }
`);

type DocumentIssueRow = Issue & GridValidRowModel;

export type DocumentRecommendationRow = {
  message: string;
  type: "recommendation";
  documentType?: DocumentType;
  id: string;
};

type DataGridRow = DocumentGridRowFragment | DocumentIssueRow;

const isDocumentIssueRow = (row: DataGridRow): row is DocumentIssueRow => {
  return (row as DocumentIssueRow).key !== undefined;
};

type RemoveDialogState = {
  open: boolean;
  message: string;
  documentIdToRemove: string;
  reportId: string;
};

const defaultRemoveDialogState: RemoveDialogState = {
  open: false,
  message: "Are you sure you want to delete this record?",
  documentIdToRemove: "",
  reportId: "",
};

type DocumentsGridProps = {
  loading: boolean;
  documents: DocumentGridRowFragment[];
  /** These are issues that will display their message as a row in the grid.
   * Used primarily when a facility is missing a specific document type. */
  facilityDocumentIssues: Issue[];
  tenantId: string | undefined;
  facility: FacilityDocumentInput | undefined;
  reportId?: string;
  refetchQueries: RefetchQueriesInclude;
  tags?: TagInput[];
  paginationModel?: PaginationModel;
  setPaginationModel?: (pagination: PaginationModel) => void;
  sortModel?: GridSortModel;
  setSortModel?: (sort: GridSortModel) => void;
  count?: number;
  deleteDocument: boolean;
};

export const DocumentsGrid: React.FC<DocumentsGridProps> = ({
  loading,
  documents,
  facilityDocumentIssues,
  tenantId,
  facility,
  reportId,
  refetchQueries,
  tags,
  paginationModel,
  setPaginationModel,
  sortModel,
  setSortModel,
  count,
  deleteDocument,
}) => {
  // Need both the facility and the report since this component is used in
  // both contexts
  const fullFacility = useFacility();
  const report = useReport();
  const isHmbp = useHmbpFeature(
    fullFacility?.data?.facility?.state ??
      report?.data?.tierIIReport?.facility?.state ??
      ""
  );
  const theme = useTheme();
  const apolloClient = useApolloClient();
  const alerts = useAlerts();

  const selectableDocumentTypes = useMemo(
    () => (isHmbp ? CaliforniaDocumentTypes : DocumentTypes),
    [isHmbp]
  );

  const rows: DataGridRow[] = useMemo(
    () => [
      ...documents,
      ...facilityDocumentIssues.map((i) => ({ ...i, id: i.message })),
    ],
    [facilityDocumentIssues, documents]
  );

  const additionalAddEditState: Partial<AddEditDocumentDialogState> =
    useMemo(() => {
      return {
        facilities: facility ? [facility] : undefined,
        documentTags: tags,
      };
    }, [facility, tags]);

  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>({
      ...defaultAddEditDocumentDialogState,
    });
  const [removeDialogState, setRemoveDialogState] = useState<RemoveDialogState>(
    defaultRemoveDialogState
  );

  const [removeDocumentFromReport, { loading: removingDocument }] =
    useRemoveDocumentFromReportMutation({ refetchQueries });
  const [deleteDocumentMutation, { loading: deletingDocument }] =
    useDeleteDocumentMutation({ refetchQueries });

  const columns: GridColDef<DataGridRow>[] = useMemo(
    () => [
      {
        field: "title",
        headerName: "Name",
        flex: 1.5,
        colSpan: ({ row }) => {
          if (isDocumentIssueRow(row)) {
            return 3;
          }

          return undefined;
        },
        renderCell: ({ row }) => {
          if (isDocumentIssueRow(row)) {
            return <IssueMessageCell issue={row} />;
          }

          return row.title;
        },
      },
      {
        field: "fileExtension",
        headerName: "File Extension",
        flex: 0.5,
      },
      {
        field: "documentType",
        headerName: "Type",
        flex: 0.75,
        renderCell: (params) => {
          if (!isDocumentIssueRow(params.row)) {
            return documentTypeToLabel(params.row.documentType);
          }

          return "";
        },
      },
      {
        field: "createdAt",
        headerName: "Uploaded",
        flex: 0.5,
        renderCell: ({ value }) =>
          value ? prettyPrintDateMed(value) : undefined,
      },
      {
        field: "actions",
        type: "actions",
        align: "right",
        flex: 0.4,
        getActions: ({ row }) => {
          if (isDocumentIssueRow(row)) {
            return [
              <Button
                key="add"
                color="primary"
                size="small"
                sx={{ my: 1 }}
                startIcon={<Add fontSize="small" />}
                onClick={() => {
                  setAddEditDialogState((state) => ({
                    ...state,
                    open: true,
                    mode: EditMode.ADD_OR_PICK,
                    documentType: row.metadata?.documentType,
                    currentSubmissionDocument: true,
                    isAddingFromIssue: true,
                    authoredAt: DateTime.now().toISODate(),
                    ...additionalAddEditState,
                  }));
                }}
              >
                Add Document
              </Button>,
            ];
          }

          return [
            <Tooltip title="Edit" key="editRecord">
              <GridActionsCellItem
                onClick={() => {
                  setAddEditDialogState((state) => ({
                    ...state,
                    open: true,
                    mode: EditMode.EDIT,
                    step: FormState.FORM,
                    documentId: row.id,
                    title: row.title,
                    documentType: row.documentType,
                    storageLink: row.storageLink,
                    currentSubmissionDocument:
                      row.currentSubmissionDocument ?? false,
                    authoredAt: row.authoredAt,
                    ...additionalAddEditState,
                  }));
                }}
                label="Edit"
                icon={<Edit />}
              />
            </Tooltip>,
            <Tooltip title="Download" key="downloadRecord">
              <GridActionsCellItem
                label="View"
                icon={
                  <DocumentViewer
                    documentId={row.id}
                    title={row.title}
                    fileExtension={row.fileExtension}
                  />
                }
              />
            </Tooltip>,
            <Tooltip
              title={deleteDocument ? "Delete" : "Remove"}
              key="deleteRecord"
            >
              <GridActionsCellItem
                onClick={() =>
                  setRemoveDialogState((state) => ({
                    ...state,
                    open: true,
                    message: deleteDocument
                      ? `Are you sure you want to delete ${row.title}?`
                      : `Are you sure you want to remove ${row.title} from this report? This will not delete the document from your document catalog.`,
                    documentIdToRemove: row.id,
                    reportId: reportId ?? "",
                  }))
                }
                label={deleteDocument ? "Delete" : "Remove"}
                icon={deleteDocument ? <Delete /> : <Close />}
              />
            </Tooltip>,
          ];
        },
      },
    ],
    [additionalAddEditState, reportId, deleteDocument]
  );

  const documentType = addEditDialogState.documentType;
  const hmbpSection = documentType
    ? hmbpSectionFromDocumentType[documentType]
    : undefined;

  return (
    <>
      <Stack display="flex" flexDirection="column" flexGrow={1}>
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end"
            gap={theme.spacing(2)}
            mb={theme.spacing(1)}
          >
            <Box>
              {!loading &&
                rows.length === 0 &&
                facilityDocumentIssues.length === 0 && (
                  <Alert
                    severity="info"
                    sx={{ mt: theme.spacing(1), fontSize: "0.7rem" }}
                  >
                    Your state does not require this facility to provide any
                    additional documentation, however you can voluntarily add
                    documentation like emergency response or site plans here.
                  </Alert>
                )}
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{ minWidth: "auto" }}
              onClick={() =>
                setAddEditDialogState((state) => ({
                  ...state,
                  open: true,
                  mode: reportId ? EditMode.ADD_OR_PICK : EditMode.ADD,
                  ...additionalAddEditState,
                }))
              }
            >
              Add Document
            </Button>
          </Stack>

          <DataGrid<DataGridRow>
            columns={columns}
            rows={rows}
            rowCount={count}
            autoHeight={true}
            getRowHeight={() => "auto"}
            loading={loading}
            rowSelection={false}
            noRowsMessage="No documents have been added yet."
            hideFooter={!paginationModel}
            pagination={!!paginationModel}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            onRowClick={(params: GridRowParams<DataGridRow>) => {
              if (isDocumentIssueRow(params.row)) {
                const { metadata } = params.row;
                setAddEditDialogState((state) => ({
                  ...state,
                  open: true,
                  mode: EditMode.ADD_OR_PICK,
                  documentType: metadata?.documentType,
                  currentSubmissionDocument: true,
                  isAddingFromIssue: true,
                  ...additionalAddEditState,
                }));
              } else {
                setAddEditDialogState((state) => ({
                  ...state,
                  ...params.row,
                  open: true,
                  mode: EditMode.EDIT,
                  step: FormState.FORM,
                  documentId: params.row.id,
                  title: (params.row as DocumentGridRowFragment).title,
                  documentType: (params.row as DocumentGridRowFragment)
                    .documentType,
                  storageLink: (params.row as DocumentGridRowFragment)
                    .storageLink,
                  currentSubmissionDocument:
                    (params.row as DocumentGridRowFragment)
                      .currentSubmissionDocument ?? false,
                  authoredAt: (params.row as DocumentGridRowFragment)
                    .authoredAt,
                  description: (params.row as DocumentGridRowFragment)
                    .description,
                  ...additionalAddEditState,
                }));
              }
            }}
          />
        </Stack>
      </Stack>

      {addEditDialogState.open &&
        tenantId &&
        (isHmbp && hmbpSection && addEditDialogState.isAddingFromIssue ? (
          <HmbpDocumentForm
            section={hmbpSection}
            handleClose={() =>
              setAddEditDialogState(defaultAddEditDocumentDialogState)
            }
          />
        ) : (
          <AddEditDocumentDialog
            dialogState={addEditDialogState}
            setDialogState={setAddEditDialogState}
            tenantId={tenantId}
            reportId={reportId}
            state={
              fullFacility?.data?.facility?.state ??
              report?.data?.tierIIReport?.facility?.state
            }
            documentTypeOptions={selectableDocumentTypes}
            onSubmit={() =>
              apolloClient.refetchQueries({ include: [...refetchQueries] })
            }
            refetchQueries={refetchQueries}
            facilityDocumentIssues={facilityDocumentIssues}
          />
        ))}

      {removeDialogState.open && (
        <ConfirmDialog
          open={removeDialogState.open}
          msg={removeDialogState.message}
          loading={deletingDocument || removingDocument}
          onClose={() => setRemoveDialogState(defaultRemoveDialogState)}
          onConfirm={async () => {
            if (deleteDocument) {
              await deleteDocumentMutation({
                variables: {
                  id: removeDialogState.documentIdToRemove,
                },
                refetchQueries,
              });
            } else {
              await removeDocumentFromReport({
                variables: {
                  documentId: removeDialogState.documentIdToRemove,
                  reportId: removeDialogState.reportId,
                },
                refetchQueries,
              });
            }
            setRemoveDialogState(defaultRemoveDialogState);
            if (deleteDocument) {
              alerts.success("Document successfully deleted");
            } else {
              alerts.success("Document successfully removed");
            }
          }}
        />
      )}
    </>
  );
};
