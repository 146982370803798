import { Chip, useTheme, Tooltip } from "@mui/material";
import { brandColors } from "../util";

type IssueCountProps = {
  issueCount?: number | null;
  ignoreForReport?: boolean;
  onClick?: () => void;
  colorOverride?: string;
};

export const IssueCount = ({
  issueCount,
  ignoreForReport,
  onClick,
  colorOverride,
}: IssueCountProps) => {
  const theme = useTheme();
  if (!issueCount) return <></>;

  const chip = (
    <Chip
      label={issueCount}
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      sx={{
        color: ignoreForReport
          ? theme.palette.text.secondary
          : theme.palette.error.main,
        backgroundColor:
          colorOverride ||
          (ignoreForReport
            ? brandColors["encamp-gray"][100]
            : brandColors["encamp-red"][200]),
        "&:hover": ignoreForReport
          ? undefined
          : {
              color: theme.palette.error.main,
              backgroundColor: onClick
                ? brandColors["encamp-red"][300]
                : brandColors["encamp-red"][200],
            },
      }}
    />
  );

  return ignoreForReport ? (
    <Tooltip title="You are not required to fix these issues because this chemical will not be reported">
      {chip}
    </Tooltip>
  ) : (
    chip
  );
};
