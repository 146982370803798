import { useMutation, useQuery } from "@apollo/client";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { GetTenantQuery } from "generated-graphql/graphql";
import { isNil } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import { gql } from "../generated-graphql";
import { useCurrentUser } from "./useCurrentUser";

gql(`
  fragment tenant on Tenant {
    id
    name
    isDisabled
    isDemo
    isPartner
    partnerTenantId
  }
`);

const GET_TENANT_QUERY = gql(`
  query GetTenant($tenantId: ID!) {
    tenant(id: $tenantId) {
      ...tenant
      featureFlags
    }
  }
`);

export type Tenant = GetTenantQuery["tenant"] | undefined;

export function useTenant() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { user } = useCurrentUser();

  const [setUserTenant, { loading: settingUserTenant }] = useMutation(
    gql(`
      mutation SetUserTenant($tenantId: ID!) {
        setUserTenant(tenantId: $tenantId)
      }
    `)
  );

  const [savedTenant, setSavedTenant] = useLocalStorageState<Tenant>(
    user ? `${user.id}-savedTenant` : ""
  );
  const [internalTenantId, setInternalTenantId] = useState<string | undefined>(
    tenantId ?? savedTenant?.id
  );

  useEffect(() => {
    setInternalTenantId(tenantId ?? savedTenant?.id);
  }, [savedTenant?.id, tenantId]);

  const { data, loading, error } = useQuery(GET_TENANT_QUERY, {
    variables: {
      tenantId: internalTenantId ?? "",
    },
    skip: isNil(internalTenantId),
    onCompleted(data) {
      setSavedTenant(data.tenant);
    },
  });

  // Setting the user tenant will:
  // 1. Set the user's tenant in the database
  // 2. Set the user's tenant in cognito through the `setUserTenant` mutation
  // 3. Refresh the user's cognito session
  const setCurrentTenantId = useCallback(
    async (newTenantId: string | null | undefined) => {
      if (newTenantId && newTenantId !== internalTenantId) {
        setInternalTenantId(newTenantId);
        await setUserTenant({
          variables: {
            tenantId: newTenantId,
          },
        });

        // This works, but is apparently undocumented and should be considered a bit fragile.
        // https://stackoverflow.com/questions/48343674/how-can-i-force-a-cognito-token-refresh-from-the-client
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        await new Promise((resolve) => {
          user.refreshSession(session.getRefreshToken(), resolve);
        });
      }
    },
    [setUserTenant, internalTenantId]
  );

  const tenant = useMemo(() => {
    return data?.tenant ?? savedTenant;
  }, [data?.tenant, savedTenant]);

  const isAmazon = internalTenantId === "6a92e397-bb74-44ed-afee-f84c7821f40e";

  return {
    tenantId: internalTenantId,
    isAmazon,
    tenant: tenant?.id === internalTenantId ? tenant : undefined,
    loading,
    error,
    settingUserTenant,
    setCurrentTenantId,
  };
}
